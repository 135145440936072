import React from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LoginNav from "../../Components/LoginNav";
import login from "../../assets/login.svg";
import SignUpForm from "./SignupForm";

function SignupPage() {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        [theme.breakpoints.up("md")]: {
          padding: "1rem",
          boxSizing: "border-box",
          backgroundImage: `url(${login})`,
          backgroundSize: "cover",
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        },
      }}
    >
      <Grid container>
        <LoginNav />
        <SignUpForm />
      </Grid>
    </Grid>
  );
}

export default SignupPage;

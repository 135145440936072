import {
  getusers,
  suspendusers,
  deleteusers,
  dashboardcounts,
  dashboard,
  createAnnouncementAPI,
  updateAnnouncementAPI,
  deleteAnnouncementAPI,
} from "../api/admin";
import { toast } from "react-toastify";
export const getUsers = async (query) => {
  try {
    const { data } = await getusers(query);
    return data;
  } catch (error) {
    //console.log(error);
  }
};

export const suspendUsers = async (ids, func) => {
  try {
    if (ids.length < 1) {
      return toast.error("Please select accounts first");
    }
    const { data } = await suspendusers(ids);
    toast("Accounts Suspended");
    func(data);
  } catch (error) {
    //console.log(error);
  }
};

export const deleteUsers = async (ids, func) => {
  try {
    if (ids.length < 1) {
      return toast.error("Please select accounts first");
    }
    const { data } = await deleteusers(ids);
    toast("Accounts deleted");
    func(data);
  } catch (error) {
    //console.log(error);
  }
};

export const getDashboardCounts = async () => {
  try {
    const { data } = await dashboardcounts();
    return data;
  } catch (error) {
    //console.log(error);
  }
};

export const getDashboard = async (query) => {
  try {
    const { data } = await dashboard(query);
    return data;
  } catch (error) {
    //console.log(error);
  }
};

/*Announcements */

export const createAnnouncementAction = (announcement) => async (dispatch) => {
  try {
    const { data } = await createAnnouncementAPI(announcement);
    if (data.announcement) {
      dispatch({
        type: "ADD_ANNOUNCEMENT",
        payload: data,
      });
    }
  } catch (error) {
    toast.error("Something went wrong. Try again");
  }
};
export const deleteAnnouncementAction = (id) => async (dispatch) => {
  try {
    const { data } = await deleteAnnouncementAPI(id);

    if (data.deleted) {
      dispatch({
        type: "DELETE_ANNOUNCEMENT",
        payload: { id },
      });
    }
  } catch (error) {
    toast.error("Something went wrong. Try again");
  }
};
export const updateAnnouncementAction = (id, newData) => async (dispatch) => {
  try {
    const { data } = await updateAnnouncementAPI(id, newData);
    if (data.announcement) {
      dispatch({
        type: "UPDATE_ANNOUNCEMENT",
        payload: data,
      });
    }
  } catch (error) {
    toast.error("Something went wrong. Try again");
  }
};

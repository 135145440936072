export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem",
    borderRadius: "1em",
    width: { xs: "80vw", sm: "40vw", xl: "25vw" },
    backgroundColor: "rgb(250, 250, 250, 0.2)",
    border: "1px solid rgb(250, 250, 250, 0.5)",
    backdropFilter: "blur(2px)",
  },
  inputTitle: {
    fontWeight: "500",
    margin: "8px 0 0 0",
    color: "black",
  },
};

export const announcementsReducer = (state = { announcements: [] }, action) => {
  switch (action.type) {
    case "SET_ANNOUNCEMENTS":
      state = {
        ...state,
        announcements: action.payload,
      };
      return state;
    case "ADD_ANNOUNCEMENT":
      state = {
        ...state,
        announcements: [action.payload.announcement, ...state.announcements],
      };
      return state;
    case "UPDATE_ANNOUNCEMENT":
      const updatedAnnouncement = action.payload.announcement;
      const updatedAnnouncements = state.announcements.map((announcement) => {
        if (announcement._id === updatedAnnouncement._id) {
          return updatedAnnouncement;
        }
        return announcement;
      });
      state = {
        ...state,
        announcements: updatedAnnouncements,
      };
      return state;
    case "DELETE_ANNOUNCEMENT":
      const announcements = state.announcements.filter(
        (announcement) => announcement._id !== action.payload.id
      );
      state = {
        ...state,
        announcements,
      };
      return state;
    case "RESET_ANNOUNCEMENTS":
      state = {
        ...state,
        announcements: [],
      };
      return state;

    default:
      return state;
  }
};

import React from "react";

import { Routes, Route } from "react-router";

import Home from "./Home";
import Request from "./Request";
import EditRequest from "./EditRequest";
import Single from "./Single";

const LoanBoard = ({ socket, deleteLoanBtnClick }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home socket={socket} deleteLoanBtnClick={deleteLoanBtnClick} />
        }
      />
      <Route path="/request" element={<Request socket={socket} />} />
      <Route path="/request/edit" element={<EditRequest socket={socket} />} />
      <Route path="/:id/:type" element={<Single socket={socket} />} />
    </Routes>
  );
};

export default LoanBoard;

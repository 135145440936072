const localStorageProfile = localStorage.getItem("profile");
const localStorageToken = localStorage.getItem("token");

const initialState = localStorageProfile
  ? { user: JSON.parse(localStorageProfile) }
  : null;

const forwardingAuthToken = (authToken) => {
  window?.webkit?.messageHandlers?.BRIDGE_FOR_TOKEN?.postMessage({
    message: authToken,
  });
};

if (localStorageToken) {
  forwardingAuthToken(localStorageToken);
}

const reducer = (state = initialState, actions) => {
  switch (actions.type) {
    case "LOGIN":
      localStorage.setItem("profile", JSON.stringify(actions.data.user));
      localStorage.setItem("token", "Bearer " + actions.data.token);
      if (actions.data.token) {
        forwardingAuthToken(actions.data.token);
      }
      return { ...state, ...actions.data };
    case "UPDATE PROFILE":
      localStorage.setItem("profile", JSON.stringify(actions.data));
      return { ...state, user: actions.data };
    case "LOGOUT":
      localStorage.clear();
      return { user: null, token: "" };
    default:
      return { ...state };
  }
};

export default reducer;

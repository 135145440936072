import React, { memo } from "react";

import { Grid, Typography } from "@mui/material";

import { UploadButton } from "./UploadButton";

const Component = ({
  handleUploadImage,
  mainColor,
  allPaystubs,
  warningColor,
  handleDeleteImage,
  error,
}) => {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: "10px", md: "8px" },
      }}
    >
      <Typography sx={{ color: "rgba(0,0,0,.6)" }}>
        Please attach your last 2 paystubs. This{" "}
        <span style={{ fontWeight: "bold" }}>
          will not be publicly displayed
        </span>{" "}
        and, if verified and approved, will be used to grant you a loan within
        24 hours.
      </Typography>
      {error && (
        <Typography sx={{ color: "#d32f2f", fontSize: "12px", width: "100%" }}>
          Please attach your last 2 paystubs.
        </Typography>
      )}
      <Grid
        container
        item
        sx={{
          flexDirection: "row",
          padding: "5px",
          mt: "8px",
          gap: "5px",
          borderRadius: "8px",
          width: "fit-content",
          justifyContent: "center",
          alignItems: "center",
          border: allPaystubs.length && `1px solid rgba(0,0,0,.6)`,
        }}
      >
        {!!allPaystubs.length &&
          allPaystubs.map((image) => {
            return (
              <Grid
                key={image.originalname || image.id}
                item
                sx={{
                  display: "inline-flex",
                  flexDirection: "column",
                  justifyContect: "center",
                  alignItems: "center",
                  "&:not(:first-of-type)": { marginLeft: 1 },
                }}
              >
                <Typography sx={{ fontSize: "13px", color: "rgba(0,0,0,.7)" }}>
                  {image.data?.name ||
                    image?.originalname ||
                    image?.data?.originalname}
                </Typography>
                <Typography
                  onClick={() => handleDeleteImage(image._id, image?.url)}
                  sx={{
                    fontSize: "12px",
                    color: warningColor,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Delete
                </Typography>
              </Grid>
            );
          })}
        {allPaystubs.length < 2 && (
          <UploadButton
            caption="Upload"
            handleUploadImage={handleUploadImage}
            mainColor={mainColor}
            type="paystubs"
            styles={{
              display: "inline-block",
              margin: "0 auto",
              padding: allPaystubs?.length ? "0.1rem 0.5rem" : "0.5rem 1rem",
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default memo(Component);

import React, { memo } from "react";

import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";

import { LoanCardButton, AddressForm } from "../../../../Components";

export const LenderPreAgreement = memo(
  ({
    closeModalHandler,
    actionBtnClick,
    isLoading,
    checked,
    setChecked,
    addressForm,
    setAddressForm,
    addressFormErrors,
    setAddressFormErrors,
  }) => {
    return (
      <>
        <Grid>
          <AddressForm
            addressForm={addressForm}
            setAddressForm={setAddressForm}
            errors={addressFormErrors}
            setErrors={setAddressFormErrors}
            secondAgreementSide="Borrower"
          />
        </Grid>
        <Grid>
          <FormControlLabel
            sx={{ mt: 1 }}
            label={
              <Typography color="#727987" fontSize={"14px"}>
                I have contacted the Borrower, have done my due diligence and
                wishto proceed. I understand that getloanr.com is not a mediator
                and I waive any rights to persecute getloanr.com or it's
                subsidiaries.
              </Typography>
            }
            control={
              <Checkbox
                sx={{ color: "#DDDDDD", borderRadius: "1px" }}
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          spacing={1}
          sx={{
            width: "100%",
            justifyContent: {
              sm: "center",
              md: "flex-end",
            },
          }}
          container
        >
          <Grid
            item
            sx={{
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <LoanCardButton
              title="Cancel"
              btnProps={{
                color: "secondary",
                variant: "contained",
                disabled: isLoading,
              }}
              cb={closeModalHandler}
              styles={{
                mr: 1,
                fontSize: "16px",
              }}
            />

            <LoanCardButton
              title="Wish to proceed"
              btnProps={{
                variant: "contained",
                disabled: isLoading,
              }}
              cb={actionBtnClick}
              styles={{
                mr: 1,
                fontSize: "16px",
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);

import * as React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from "@mui/material";

export function Sort({ open, handleClose, setSortRule, onApplyClick }) {
  return (
    <Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="loan filters"
        sx={{
          "& .MuiDialog-container .MuiPaper-root": {
            overflow: "hidden",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ fontSize: 24, color: "#a59e9e" }}>
          Sort By
        </DialogTitle>
        <DialogContent sx={{ width: "fit-content" }}>
          <Grid
            container
            sx={{ flexDirection: "column", width: "fit-content" }}
          >
            <Grid>
              <FormControl sx={{ m: 1 }}>
                <InputLabel htmlFor="grouped-native-select">Date</InputLabel>
                <Select
                  native
                  defaultValue=""
                  id="grouped-native-select"
                  label="Date"
                  autoWidth
                  onChange={(e) => setSortRule(e.target.value)}
                >
                  <option aria-label="None" value="" />
                  <option value={"date-asc"}>Payback (Asc)</option>
                  <option value={"date-desc"}>Payback (Desc)</option>
                  <option value={"createdAt-asc"}>Published (Asc)</option>
                  <option value={"createdAt-desc"}>Published (Desc)</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Back</Button>
          <Button onClick={onApplyClick} autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

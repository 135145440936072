import React, { memo, useState } from "react";

import {
  Paper,
  Grid,
  useMediaQuery,
  Modal,
  Typography,
  Alert,
  useTheme,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payments";

import { LoanCardButton } from "../../../Components";

export const DashboardRequestModal = memo(
  ({
    open,
    publishData,
    closeModalHandler,
    publishLoanRequest,
    PaymentComponent,
    isEditing,
    isLoading,
    requestedAmount,
  }) => {
    const [showPaymentComponent, setShowPaymentComponent] = useState(null);

    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    return (
      <Modal sx={{ zIndex: 1300 }} open={open}>
        <Paper
          className="fade-in-slow"
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            padding: "0.5em",
            borderRadius: "8px",
            height: showPaymentComponent ? "90%" : "fit-content",
            width: showPaymentComponent ? "450px" : "fit-content",
            maxWidth: {
              xs: "90%",
              sm: "65%",
              md: "450px",
            },
          }}
          elevation={isSmallMediaQuery ? 0 : 6}
        >
          {PaymentComponent || publishData?.isSubscriptionPaused === true ? (
            <Grid
              sx={{
                overflowY: "auto",
              }}
            >
              <PaymentIcon
                sx={{
                  color: "#ccc",
                  fontSize: 50,
                  display: "block",
                  margin: "0 auto",
                }}
              />

              <Typography
                fontWeight={700}
                style={{ marginBottom: "1em", textAlign: "center" }}
                fontSize={"20px"}
              >
                Publishing Loan
              </Typography>

              {PaymentComponent && !isEditing && (
                <Grid sx={{}}>
                  {
                    <PaymentComponent
                      payeerRole="borrower"
                      price={publishData?.price}
                      handleCancelBtnClick={() => {
                        setShowPaymentComponent(false);
                        closeModalHandler();
                      }}
                      requestedAmount={requestedAmount}
                      setShowPaymentComponent={setShowPaymentComponent}
                      origin="dashboard"
                    />
                  }
                </Grid>
              )}
              <Grid
                item
                xs={12}
                spacing={1}
                sx={{
                  width: "100%",
                  marginTop: "0.5em",
                  justifyContent: {
                    xs: PaymentComponent && "center",
                    sm: "center",
                    md: "flex-end",
                  },
                  ml: {},
                }}
                container
              ></Grid>
            </Grid>
          ) : (
            <Typography
              fontSize={"16px"}
              sx={{ margin: "16px auto 0 auto", textAlign: "center" }}
            >
              Do you want to publish loan request to the Loanboard?
            </Typography>
          )}
          <Grid sx={{ mt: 1 }}>
            {publishData?.isPublishAllowed === false && (
              <Alert severity="warning">
                Only one published request is allowed until your outstanding
                loan is repaid. You can save this request to the Dasboard.
              </Alert>
            )}
            {publishData?.isSubscriptionPaused && (
              <Alert severity="warning">{publishData.message}</Alert>
            )}
          </Grid>

          <Grid
            item
            sx={{
              height: "fit-content",
              display: "flex",
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <LoanCardButton
              title="Cancel"
              btnProps={{
                color: "secondary",
                variant: "contained",
                disabled: isLoading,
              }}
              cb={() => {
                setShowPaymentComponent(false);
                closeModalHandler();
              }}
              styles={{
                mr: 1,
                mt: { xs: 1, sm: 0 },
                fontSize: "16px",
                width: "fit-content",
              }}
            />

            {!PaymentComponent && (
              <LoanCardButton
                title="Publish Request"
                btnProps={{
                  variant: "contained",
                  disabled:
                    publishData?.isPublishAllowed === false ||
                    publishData?.isSubscriptionPaused,
                }}
                cb={() => publishLoanRequest()}
                styles={{
                  mr: 1,
                  fontSize: "16px",
                }}
              />
            )}
          </Grid>
        </Paper>
      </Modal>
    );
  }
);

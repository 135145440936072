import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

import { BarChartGraph } from "./BarChartGraph";
import { LocationTable } from "./LocationTable";

export const LocationsChart = ({ locationData }) => {
  const [locationType, setLocationType] = useState("cities");
  const [dataFormatType, setDataFormatType] = useState("chart");

  return (
    <Grid container sx={{ mb: "20px", mt: "20px" }}>
      <Typography sx={{ margin: "0 auto", fontSize: "22px" }}>
        Users location by {locationType}
      </Typography>
      <Button
        size="small"
        onClick={() => {
          const toggleToLocationType =
            locationType === "cities" ? "states" : "cities";
          setLocationType(toggleToLocationType);
        }}
      >
        Switch to {locationType === "cities" ? "states" : "cities"}
      </Button>
      <Button
        size="small"
        onClick={() => {
          const toggleToDataFormatType =
            dataFormatType === "chart" ? "table" : "chart";
          setDataFormatType(toggleToDataFormatType);
        }}
      >
        Show as {dataFormatType === "chart" ? "table" : "chart"}
      </Button>
      {dataFormatType === "chart" && (
        <BarChartGraph
          data={locationData[locationType].filter(
            (l) => l.value > (locationType === "cities" ? 2 : 0)
          )}
          locationType={locationType}
        />
      )}
      {dataFormatType === "table" && (
        <LocationTable
          data={locationData[locationType]}
          allCount={locationData.all}
          locationType={locationType}
        />
      )}
    </Grid>
  );
};

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  Sidebar,
  ConversationHeader,
  Avatar,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  RequestQuoteOutlined as RequestIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";

import { Conversations } from "./ConversationList";
import { ImageModal } from "./ImageModal";
import { MessageContent } from "./MessageContent";

import moment from "moment";

import "./styles.css";
import { ActiveLoanRequestsModal } from "./ActiveLoanRequestsModal";
import { getloans } from "../../api/loan";
import { useLocation, useNavigate } from "react-router";
import { getLoanById } from "../../actions/loan";
import {
  DefaultRemindingMessage,
  SystemMessageBadge,
} from "./SystemMessageElements";
import { getSingleChatAction } from "../../actions/chats";

import { toast } from "react-toastify";
import { ChatLoader } from "./ChatLoader";
import { LoanCardButton } from "../../Components";
import { getChatAsPDFAPI } from "../../api/chats";
import { ChatToPDFModal } from "./ChatToPDFModal";

function ChatScreen({ socket }) {
  const {
    auth: { user },
    chats: { chats, loading, selectedChat },
    loans: { loans },
  } = useSelector((state) => state);
  const location = useLocation();
  const receiverIDRef = useRef(location.state?.receiver);

  const [msg, setMsg] = useState("");
  const [msgImage, setMsgImage] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [activeLoanRequests, setActiveLoanRequests] = useState(null);
  const [isChatToPDFModalOpen, setIsChatToPDFModalOpen] = useState(false);

  const bottomRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const observerRef = useRef(null);

  const isAdminChat = !!selectedChat?.users.some(
    (user) => user.role === "chat-bot"
  );
  const currentRespondent = selectedChat?.users?.find((chatUser) => {
    if (user.role === "admin") {
      /*Prevent selecting chat bot as a respondent user */
      return chatUser.role !== "chat-bot" && chatUser.id !== user.id;
    } else {
      return chatUser.id !== user.id;
    }
  });

  const isUserStartChating = selectedChat?.messages?.some((message) => {
    return message?.sender?.id === user.id;
  });

  const handleSendMessage = useCallback(() => {
    if (!msg.trim() && !msgImage) return;
    const receiverID = currentRespondent?.id;

    let imgSrc;
    if (msgImage) {
      imgSrc = URL.createObjectURL(msgImage);
    }
    const newMessage = {
      message: { text: msg.trim(), image: msgImage },
      senderID: user.chatBotID || user.id,
      receiverID,
      adminID: selectedChat.adminID,
      sentToAdmin:
        user.role !== "admin" && (isAdminChat ? selectedChat.adminID : null),
    };

    socket?.emit("new message", newMessage);
    delete newMessage.senderID;
    delete newMessage.receiverID;
    newMessage.message.image = imgSrc;
    newMessage._id = Math.random().toString();
    newMessage.sender = user;

    dispatch({
      type: "UPDATE_SELECTED_CHAT",
      payload: { newMessage, chatID: selectedChat.id },
    });

    setMsgImage(null);
    setMsg("");
  }, [
    msg,
    msgImage,
    socket,
    isAdminChat,
    selectedChat,
    user,
    dispatch,
    currentRespondent?.id,
  ]);

  const handleInputChange = (_innerHtml, textContent, _innerText, _nodes) => {
    setMsg(textContent);
  };

  const handleSelectChat = (chat) => {
    if (
      chat.messages.some(
        (msg) => msg.message && !msg.sender && !msg.message.system
      )
    ) {
      toast.error("User doesn't exist");
      return;
    }

    dispatch({ type: "SET_SELECTED_CHAT", payload: { chatID: chat.id } });
  };

  const handleAddImage = (e) => {
    if (e.target.files[0].size > 10485760) {
      toast.error("Max. image size 10MB");
      return;
    }

    setMsgImage(e.target.files[0]);
    setMsg(" ");
  };

  const handleRemoveSelectedChat = () => {
    dispatch({ type: "CLOSE_SELECTED_CHAT" });
    setMsgImage(null);
    setMsg("");
  };

  const getActiveBorrowerLoanRequests = async () => {
    try {
      const { data } = await getloans(
        `userID=${currentRespondent.id}&type=chat`
      );

      return data.loans.borrowed;
    } catch (e) {
      toast.error("Something went wrong. Try again");
      dispatch({ type: "UNLOAD" });
    }
  };

  const handleGetBorrowerRequests = async () => {
    try {
      dispatch({ type: "LOAD" });

      const borrowerRequests = await getActiveBorrowerLoanRequests();
      if (borrowerRequests.length) {
        setActiveLoanRequests(borrowerRequests);
      } else {
        setActiveLoanRequests([]);
      }
    } catch (e) {
      toast.error("Something went wrong. Try again");
    } finally {
      dispatch({ type: "UNLOAD" });
    }
  };

  const grantLoanHandler = (loan) => {
    dispatch(
      getLoanById(loan.id, (response) => {
        if (response.isLoanEditing) {
          toast.error("Loan is editing by borrower");
        } else {
          navigate(`/dashboard/loan-board/${loan.id}/grant`, {
            state: { loan },
          });
        }
      })
    );
  };

  const handleLoanNotRepaid = useCallback(
    (loan) => {
      const isPaybackDatePassed = moment.utc().isAfter(moment(loan.date));
      if (!isPaybackDatePassed) {
        const formatedDate = moment(loan.date).format("MMM Do, YYYY");
        toast(`Loan could be marked as not repaid after - ${formatedDate}`);
      } else {
        navigate(`/dashboard/loan-board/${loan.id}/unpaid`, {
          state: { loan },
        });
      }
    },
    [navigate]
  );

  let separatorDate;
  let previousSeparatorDate;

  const handlePrefetchSingleChat = useCallback(
    (chatID, fetchBefore) => {
      dispatch(getSingleChatAction(chatID, fetchBefore));
    },
    [dispatch]
  );

  const handleInfiniteScroll = useCallback(
    (target) => {
      /*Stop observing previous target */
      if (observerRef.current?.target) {
        observerRef.current.observer.unobserve(observerRef.current.target);
      }
      /*Set options */
      const root = document.querySelector("#messageList > div");
      let options = {
        root, // Loan cards wrapper
        threshold: 1.0, //Envoke callback when target will appear on the screen on 100%
      };
      /*Create observer */
      let observer = new IntersectionObserver((event) => {
        if (event[0].isIntersecting) {
          handlePrefetchSingleChat(
            selectedChat?.id,
            selectedChat?.messages.length
          );
        }
      }, options);

      if (target && observer) {
        // Start observing
        observer.observe(target);
        observerRef.current = { observer, target };
      }
    },
    [handlePrefetchSingleChat, selectedChat?.messages.length, selectedChat?.id]
  );

  const getRequestedLoanData = useCallback(
    (loanID) => {
      const requestedLoan = loans?.requested?.find(
        (loan) => loan.id === loanID
      );
      const isRequestedLoanGranted =
        requestedLoan?.created_by.id === user.id &&
        !requestedLoan.isGrantingConfirmed;

      return { requestedLoan, isRequestedLoanGranted };
    },
    [loans]
  );

  const processGrantedLoan = useCallback(
    (type, loan) => {
      dispatch({ type: "LOAND" });
      navigate(`/dashboard/loan-board/${loan.id}/${type}`, {
        state: {
          loan,
        },
      });
      dispatch({ type: "UNLOAND" });
    },
    [navigate, dispatch]
  );
  const handleRequestPaystubs = useCallback(
    (status, lender, borrower, message) => {
      socket.emit("paystubs", {
        lender,
        borrower,
        status,
        ...(message && { messageID: message._id }),
      });
      if (status !== "request" && selectedChat.id) {
        const updatedMessage = { ...message };
        updatedMessage.message.paystubs.status = status;
        dispatch({
          type: "UPDATE_SELECTED_CHAT",
          payload: {
            type: "existed",
            newMessage: updatedMessage,
            chatID: selectedChat.id,
          },
        });
      }
    },

    [socket, dispatch, selectedChat?.id]
  );

  const navigateToUserProfile = () => {
    navigate(
      `/${"dashboard"}/profile/${
        currentRespondent?.id
      }?location=dashboard/messages`
    );
  };

  const getChatAsPDF = async () => {
    setIsChatToPDFModalOpen(false);
    dispatch({ type: "LOAD" });
    try {
      const resp = await getChatAsPDFAPI(selectedChat.id);
      const href = URL.createObjectURL(resp.data);

      // create "a" HTML element with href to download the file
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `chat-${new Date(Date.now()).toISOString().slice(0, -5)}.pdf`
      );
      //   Remove link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      toast("Something went wrong");
    } finally {
      dispatch({ type: "UNLOAD" });
    }
  };
  useEffect(() => {
    const target = document.getElementById(selectedChat?.messages[0]?._id);
    if (target) handleInfiniteScroll(target);
  }, [selectedChat?.messages.length]);

  useEffect(() => {
    if (receiverIDRef.current) {
      dispatch({
        type: "SET_SELECTED_CHAT",
        payload: { receiverID: receiverIDRef.current },
      });
    }
  }, []);

  useEffect(() => {
    if (selectedChat?.id) {
      socket?.emit("delete-notifications", {
        senderID: currentRespondent?.id,
        receiverID: user.chatBotID || user.id,
        type: ["chat", "system-chat"],
      });

      dispatch({
        type: "DELETE_NOTIFICATIONS",
        payload: {
          senderID: currentRespondent?.id,
          type: ["chat", "system-chat"],
        },
      });
    }
  }, [selectedChat?.id]);

  useEffect(() => () => dispatch({ type: "CLOSE_SELECTED_CHAT" }), []);

  return (
    <>
      <ChatToPDFModal
        isModalOpen={isChatToPDFModalOpen}
        submitHandler={getChatAsPDF}
        cancelHandler={() => setIsChatToPDFModalOpen(false)}
      />
      <ActiveLoanRequestsModal
        isModalOpen={!!activeLoanRequests}
        closeModal={() => setActiveLoanRequests(null)}
        username={currentRespondent?.username}
        userID={currentRespondent?.id}
        activeLoanRequests={activeLoanRequests}
        grantLoanHandler={grantLoanHandler}
        handleLoanNotRepaid={handleLoanNotRepaid}
      />
      <ImageModal imageSrc={imageSrc} handleClose={() => setImageSrc(null)} />
      <MainContainer
        style={{
          maxHeight: "80vh",
          border: "1px solid white",
          borderRadius: "10px",
          backgroundColor: "transparent",
          marginTop: "1rem",
          gap: "3px",
        }}
      >
        {selectedChat ? (
          <>
            <ChatContainer style={{ backgroundColor: "transparent", order: 0 }}>
              {
                <ConversationHeader style={{ backgroundColor: "white" }}>
                  {
                    <Avatar
                      style={{
                        backgroundColor: "rgb(110, 169, 215)",
                        textAlign: "center",
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                      onClick={navigateToUserProfile}
                    >
                      {currentRespondent?.username[0]}
                    </Avatar>
                  }
                  <ConversationHeader.Content>
                    <span
                      style={{
                        alignSelf: "flex-center",
                      }}
                    >
                      {"@" + currentRespondent?.username}
                    </span>
                  </ConversationHeader.Content>
                  <ConversationHeader.Actions>
                    <Tooltip title="Get chat in PDF" placement="top">
                      <IconButton
                        aria-label="Loan Request"
                        onClick={() => setIsChatToPDFModalOpen(true)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    {!isAdminChat && (
                      <Tooltip title="Active Loan Requests" placement="top">
                        <IconButton
                          aria-label="Loan Request"
                          onClick={handleGetBorrowerRequests}
                        >
                          <RequestIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <IconButton
                      aria-label="Close"
                      onClick={handleRemoveSelectedChat}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ConversationHeader.Actions>
                </ConversationHeader>
              }
              <MessageList
                loading={loading}
                id="messageList"
                ref={bottomRef}
                autoScrollToBottom={true}
                style={{ backgroundColor: "transparent" }}
                autoScrollToBottomOnMount={true}
              >
                {!isUserStartChating && !isAdminChat && (
                  <Message style={{ marginTop: "8px" }}>
                    <Message.CustomContent
                      style={{ backgroundColor: "transparent" }}
                    >
                      <SystemMessageBadge />
                      <DefaultRemindingMessage />
                    </Message.CustomContent>
                  </Message>
                )}

                {selectedChat &&
                  selectedChat?.messages?.map((message, index) => {
                    const [text, loanID] =
                      (message.message?.system &&
                        message.message?.system?.split("|")) ||
                      [];

                    const { isRequestedLoanGranted, requestedLoan } =
                      getRequestedLoanData(loanID);
                    const isOutgoing = message?.sender?.id === user.id;
                    if (index === 0) {
                      separatorDate = previousSeparatorDate = moment(
                        message.createdAt
                      ).format("dddd,MMM Do YYYY");
                    }
                    if (
                      index &&
                      moment(message.createdAt).format("dddd,MMM Do YYYY") ===
                        previousSeparatorDate
                    ) {
                      separatorDate = null;
                    } else {
                      separatorDate = previousSeparatorDate = moment(
                        message.createdAt
                      ).format("dddd,MMM Do YYYY");
                    }
                    return message.message?.system ? (
                      <Message
                        id={message._id}
                        key={message._id}
                        style={{ marginTop: "8px" }}
                      >
                        <Message.Header>
                          {separatorDate && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              {separatorDate}
                            </div>
                          )}
                          <p style={{ margin: "15px 0 0 auto" }}>
                            {moment(message.createdAt).format("HH:mm")}
                          </p>
                        </Message.Header>
                        <Message.CustomContent
                          style={{ backgroundColor: "transparent" }}
                        >
                          <SystemMessageBadge />
                          <Alert
                            severity="warning"
                            sx={{
                              mt: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "rgb(102, 60, 0)!important",
                                fontSize: "14px!important",
                              }}
                            >
                              {text}
                            </Typography>
                          </Alert>
                          <Grid sx={{ display: "flex", gap: "5px" }}>
                            {!!message.message.paystubs.images?.length &&
                              message.message.paystubs.images.map((image) => {
                                return (
                                  <Grid
                                    className="paystubs-wrapper"
                                    key={image.url}
                                  >
                                    <MessageContent
                                      message={{
                                        message: { image: image.url },
                                      }}
                                      setImageSrc={setImageSrc}
                                    />
                                  </Grid>
                                );
                              })}
                          </Grid>
                          {message.message?.paystubs?.status === "request" &&
                            message.message?.paystubs?.initiatorID !==
                              user.id && (
                              <Grid
                                container
                                sx={{
                                  justifyContent: "center",
                                  gap: "5px",
                                  mt: "5px",
                                }}
                              >
                                <LoanCardButton
                                  title="Allow"
                                  cb={() =>
                                    handleRequestPaystubs(
                                      "allow",
                                      currentRespondent,
                                      user,
                                      message
                                    )
                                  }
                                />
                                <LoanCardButton
                                  title="Deny"
                                  cb={() =>
                                    handleRequestPaystubs(
                                      "deny",
                                      currentRespondent,
                                      user,
                                      message
                                    )
                                  }
                                  btnProps={{ color: "warning" }}
                                />
                              </Grid>
                            )}
                          {isRequestedLoanGranted && (
                            <Grid
                              container
                              sx={{
                                justifyContent: "center",
                                gap: "5px",
                                mt: "5px",
                              }}
                            >
                              <LoanCardButton
                                title="Confirm"
                                cb={() =>
                                  processGrantedLoan("confirm", {
                                    ...requestedLoan,
                                    granted_by: currentRespondent,
                                  })
                                }
                              />
                              <LoanCardButton
                                title="Deny"
                                cb={() =>
                                  processGrantedLoan("deny-grant", {
                                    ...requestedLoan,
                                    granted_by: currentRespondent,
                                  })
                                }
                                btnProps={{ color: "warning" }}
                              />
                            </Grid>
                          )}
                        </Message.CustomContent>
                      </Message>
                    ) : (
                      <Message
                        id={message._id}
                        key={message._id}
                        model={{
                          direction: isOutgoing ? "outgoing" : "incoming",
                          position: "single",
                        }}
                        style={{
                          marginTop: separatorDate && index ? "15px" : "5px",
                        }}
                      >
                        <Message.Header
                          sentTime={moment(message.createdAt).format("HH:mm")}
                        >
                          {separatorDate && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              {separatorDate}
                            </div>
                          )}
                          <p style={{ margin: "15px 0 0 auto" }}>
                            {moment(message.createdAt).format("HH:mm")}
                          </p>
                        </Message.Header>
                        <Message.CustomContent>
                          <MessageContent
                            message={message}
                            setImageSrc={setImageSrc}
                          />
                        </Message.CustomContent>
                        <Avatar
                          style={{
                            backgroundColor: "rgb(110, 169, 215)",
                            textAlign: "center",
                            fontSize: "25px",
                          }}
                        >
                          {isOutgoing
                            ? user.username[0]
                            : message.sender?.username[0]}
                        </Avatar>
                      </Message>
                    );
                  })}
              </MessageList>
              {/* Message Input */}
              <MessageInput
                id="msg-input"
                sendDisabled={false}
                autoFocus
                attachButton
                onSend={handleSendMessage}
                style={{ backgroundColor: "transparent" }}
                placeholder="Type message here"
                onChange={handleInputChange}
                onAttachClick={(e) => {
                  document.getElementById("msg-image").click();
                }}
              />
            </ChatContainer>
          </>
        ) : loading ? (
          <ChatLoader />
        ) : chats?.length ? (
          <Box
            sx={{
              width: { xs: "100%", md: "75%" },
            }}
          >
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              PLEASE OPEN A CHAT
            </Typography>

            <Box
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <Conversations
                user={user}
                chats={chats}
                handleSelectChat={handleSelectChat}
              />
            </Box>
          </Box>
        ) : (
          <Typography
            sx={{
              display: "block",
            }}
          >
            NO ACTIVE CHATS
          </Typography>
        )}

        <Box
          sx={{
            display: "none",
            [theme.breakpoints.up(900)]: {
              display: "block",
              width: "25%",
              marginLeft: "auto",
            },
          }}
        >
          <Sidebar
            position="right"
            scrollable={false}
            style={{
              backgroundColor: "white",
              maxWidth: "30vw",
              paddingTop: "1vw",
            }}
          >
            <Conversations
              user={user}
              chats={chats}
              handleSelectChat={handleSelectChat}
            />
          </Sidebar>
        </Box>
      </MainContainer>
      {msgImage && (
        <Grid sx={{ position: "relative", cursor: "pointer" }}>
          <Grid
            sx={{
              position: "absolute",
              top: -80,
              left: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pl: 2,
              zIndex: 100,
            }}
          >
            <Typography
              sx={{ color: theme.palette.primary.main, fontSize: "13px" }}
            >
              {msgImage.name}
            </Typography>
            <IconButton sx={{}} onClick={() => setMsgImage(null)}>
              <Typography sx={{ color: "red", fontSize: "13px" }}>x</Typography>
            </IconButton>
          </Grid>
        </Grid>
      )}
      {/* Image Input */}
      <label htmlFor="msg-image">
        <Input
          style={{ display: "none" }}
          id="msg-image"
          onChange={handleAddImage}
          name="msg-image"
          type="file"
          inputProps={{ accept: "image/png, image/jpeg" }}
          value={msgImage?.filename}
        />
        <IconButton
          id="msg-image"
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
        ></IconButton>
      </label>
    </>
  );
}

export default ChatScreen;

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export const PublishFee = ({ open, modalClose, monthlySubscription }) => {
  const rows = [
    { id: 1, min: 1, max: 25, price: 7 },
    { id: 2, min: 26, max: 60, price: 19.99 },
    { id: 3, min: 61, max: 100, price: 29.99 },
    { id: 4, min: 101, max: 140, price: 39.99 },
    { id: 5, min: 141, max: 200, price: 59.99 },
    { id: 6, min: 201, max: 300, price: 79.99 },
    { id: 7, min: 301, max: 399, price: 99.99 },
    { id: 8, min: 400, max: 1000, price: 149.99 },
  ];
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={modalClose}
    >
      <DialogContent>
        <Typography sx={{ mt: 1, textAlign: "center", fontSize: "18px" }}>
          The monthly subscription allows users to publish or to grant loan
          requests.
        </Typography>
        <Typography sx={{ mt: 1, width: "100%" }}>
          The price of a <strong>grant</strong> subscription is - USD{" "}
          {monthlySubscription}
        </Typography>
        <Typography sx={{ mt: 1, width: "100%" }}>
          The price of a <strong>publish</strong> subscription depends on the
          requested amount
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Request amount range</b>, USD
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <b>Price</b>, monthly
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ width: "fit-content" }}
                  >
                    {row.min} - {row.max}
                  </TableCell>
                  <TableCell align="right">USD {row.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={modalClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

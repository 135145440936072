import React from "react";
import { Box, Fab, Fade, useScrollTrigger } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export function ScrollToTop() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = document.getElementById("back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 5,
        }}
      >
        <Fab
          size="small"
          aria-label="scroll back to top"
          sx={{
            backgroundColor: "rgba(25, 118, 210,0.8)",
            "&:hover": {
              backgroundColor: "rgba(25, 118, 210,1)",
            },
          }}
        >
          <KeyboardArrowUpIcon sx={{ color: "white" }} />
        </Fab>
      </Box>
    </Fade>
  );
}

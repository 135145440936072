import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FormHelperText } from "@mui/material";
import { useEffect } from "react";

export const PhoneInputComponent = ({
  contact,
  setError,
  setData,
  error,
  theme,
}) => {
  const phoneInput = document.getElementById("phone-input");
  const regularUnfocusedBorder = "1px solid rgba(0, 0, 0, 0.23)";

  const primaryBorder = (width) =>
    `${width}px solid ${theme.palette.primary.main}`;
  const errorBorder = (width) => `${width}px solid #d32f2f`;

  const handlePhoneInputChange = (value, country, e) => {
    if (value.substring(country.dialCode.length).length >= 10) {
      e.target.style.border = primaryBorder(2);
      e.target.classList.remove("error");
      setError((prevState) => ({ ...prevState, contact: false }));
    }
    setData((prevState) => ({
      ...prevState,
      contact: {
        number: value,
        dialCode: country.dialCode,
      },
    }));
  };

  const checkClass = (className) => phoneInput?.classList.contains(className);

  useEffect(() => {
    if (phoneInput) {
      phoneInput?.addEventListener("mouseenter", ({ target: { style } }) => {
        const isPhoneInputHasFocusOrErr =
          checkClass("focused") || checkClass("error");
        if (isPhoneInputHasFocusOrErr) {
          return;
        }
        style.border = "1px solid black";
      });
      phoneInput?.addEventListener("mouseleave", ({ target: { style } }) => {
        const isPhoneInputHasFocusOrErr =
          checkClass("focused") || checkClass("error");
        if (isPhoneInputHasFocusOrErr) {
          return;
        }
        style.border = regularUnfocusedBorder;
      });
    }
  }, [phoneInput]);
  return (
    <>
      <PhoneInput
        country={"us"}
        placeholder="Enter phone number"
        value={contact.number}
        onChange={handlePhoneInputChange}
        onFocus={({ target: { style, classList } }) => {
          style.border = !error ? primaryBorder(2) : errorBorder(2);
          classList.add("focused");
        }}
        onBlur={({ target: { style, classList } }) => {
          if (error) {
            style.border = errorBorder(1);
          } else {
            style.border = regularUnfocusedBorder;
          }
          classList.remove("focused");
        }}
        inputProps={{
          name: "contact",
          required: true,
          id: "phone-input",
        }}
        containerStyle={{
          fontFamily: "Mulish",
          fontWeight: 400,
          fontSize: "1rem",
          margin: "8px 0 0 0",
        }}
        inputStyle={{
          height: "2.5rem",
          backgroundColor: "rgba(255,255,255,0.6)",
          width: "100%",
          borderRadius: "10px",
        }}
        buttonStyle={{
          backgroundColor: "inherit",
          borderColor: "transparent",
        }}
      />
      {error && (
        <FormHelperText sx={{ ml: "14px" }} error={error}>
          Please enter valid phone number
        </FormHelperText>
      )}
    </>
  );
};

import { InputAdornment, TextField } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import styled from "@emotion/styled";

export const CssTextField = styled(TextField)`
  width: ${(props) => props.extend && "fit-content"};
  & > .MuiOutlinedInput-root {
    background-color: rgba(255, 255, 255, 0.6);
    height: 3em;
    border-radius: 10px;
  }
`;
export const PickDateInput = ({
  dateState,
  error,
  errorText,
  handleChangeState,
  extendDate,
  isPastDateDisabled = true,
  pastDate,
  isFullWidth = true,
  inputId = "date",
  disabled = false,
  maxDate,
}) => {
  function addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const disablePastDate = () => {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = addLeadingZeros(today.getDate() + 1, 2);
    mm = addLeadingZeros(today.getMonth() + 1, 2);

    yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <CssTextField
      extend={extendDate?.toString()}
      sx={{ mt: "7px" }}
      fullWidth={isFullWidth}
      value={dateState}
      required
      error={error}
      helperText={errorText}
      type="date"
      inputProps={{
        min: isPastDateDisabled
          ? pastDate
            ? pastDate
            : disablePastDate()
          : null,
        max: maxDate,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarTodayIcon />
          </InputAdornment>
        ),
        style: { width: extendDate && "fit-content" },
      }}
      id={inputId}
      onChange={handleChangeState}
      disabled={disabled}
    />
  );
};

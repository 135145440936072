import React from "react";

import { Tooltip, BarChart, XAxis, YAxis, CartesianGrid, Bar } from "recharts";

export const BarChartGraph = ({ data, locationType }) => {
  return (
    <BarChart
      width={900}
      height={300}
      data={data}
      margin={{
        top: 10,
        right: 10,
        left: 10,
        bottom: 10,
      }}
      barSize={10}
    >
      <XAxis
        dataKey={locationType === "cities" ? "_id.city" : "_id"}
        scale="point"
        padding={{ left: 1, right: 1 }}
      />
      <YAxis dataKey="value" allowDecimals={false} />
      <Tooltip />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar dataKey="value" fill="#8884d8" background={{ fill: "#eee" }} />
    </BarChart>
  );
};

import React, { useState, useEffect } from "react";

import { Box, Grid, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Button } from "@chatscope/chat-ui-kit-react";
import { getImage } from "../../utils";
import { useCallback } from "react";

export const ImageModal = ({ imageSrc, handleClose }) => {
  const [currentMeasureBase, setCurrentMeasureBase] = useState(null);
  const [image, setImage] = useState(null);

  const isLarge = window.innerWidth > 1200;
  const isMedium = window.innerWidth > 901 && window.innerWidth < 1200;
  const isSmall = window.innerWidth < 901 && window.innerWidth > 600;
  const isXSmall = window.innerWidth < 600;

  let orientation;
  let aspectRatio;

  if (image) {
    aspectRatio = image.width / image.height;
    if (image.height > 0 && image.width > 0) {
      if (image.height > image.width) {
        orientation = "p";
      } else if (image.width > image.height) {
        orientation = "l";
      } else if (image.width === image.height) {
        orientation = "s";
      }
    }
  }

  const handleImageSize = useCallback(() => {
    if (isXSmall) {
      setCurrentMeasureBase("calc(35em + 1vw)");
    } else if (isSmall) {
      setCurrentMeasureBase("calc(40em + 1vw)");
    } else if (isMedium) {
      setCurrentMeasureBase("calc(30em + 1vw)");
    } else if (isLarge) {
      setCurrentMeasureBase("calc(30em + 1vw)");
    }
  }, []);

  useEffect(() => {
    if (imageSrc) {
      getImage(imageSrc).then((img) => {
        if (img) {
          setImage(img);
          handleImageSize();
          window.addEventListener("resize", handleImageSize);
        }
      });
    }

    return () => window.removeEventListener("resize", handleImageSize);
  }, [imageSrc, handleImageSize]);

  return (
    <Grid sx={{ width: "90vw" }}>
      <Dialog
        open={!!imageSrc}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "100%",
            maxHeight: "100%",
            margin: 0,
            padding: 0,
          },

          "& .MuiDialog-container": {
            display: "flex",
          },
        }}
      >
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
        <DialogContent
          sx={{
            margin: 0,
            padding: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={imageSrc}
            sx={{
              width:
                orientation === "p"
                  ? `calc(${currentMeasureBase}  * ${aspectRatio})`
                  : isXSmall
                  ? `calc(25em + 1vw)`
                  : `calc(30em + 1vw )`,

              height: "auto",
              objectFit: "cover",
            }}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

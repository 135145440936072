import React from "react";

import { List, ListItem, Typography, useMediaQuery } from "@mui/material";
import { ActionButtonTemplate } from "../ActionButtonTemplate";
import { Delete as DeleteIcon } from "@mui/icons-material";

export const TipOne = () => {
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>1. Go to the LoanBoard page </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          2. Click {<ActionButtonTemplate title="Request a Loan" />} on the
          top-right corner
        </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>3. Fill out the form and add paystubs</Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          4. Click on {<ActionButtonTemplate title="Next" />}.
        </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography component="div">
          5. The Borrower has 2 options:
          <Typography sx={{ mt: "3px" }}>
            {<ActionButtonTemplate title="Confirm & Save" />} - loan request
            will be added to your Dashboard. You have to pay for monthly
            subscription for posting on Loanboard.
          </Typography>
          <Typography sx={{ mt: "3px" }}>
            {<ActionButtonTemplate title="Confirm & Publish" />} - loan request
            will appear on the Loanboard if you have valid monthly subscription.
          </Typography>
        </Typography>
      </ListItem>
    </List>
  );
};

export const TipTwo = () => {
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          1. Required fields are marked with{" "}
          <span style={{ color: "#d32f2f", fontSize: "1rem" }}>*</span> (red
          asterisk)
        </Typography>
      </ListItem>
    </List>
  );
};

export const TipThree = () => {
  const isMobileView = useMediaQuery("(max-width:1100px)");
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          1. You can only delete loan request if it hasn't been granted yet!{" "}
        </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          2. Go to the Dashboard page, find your request, and click on{" "}
          {isMobileView ? (
            <ActionButtonTemplate title="Delete" color="warning" />
          ) : (
            <span>
              <DeleteIcon />
            </span>
          )}{" "}
          button
        </Typography>
      </ListItem>
    </List>
  );
};

export const TipFour = () => {
  return (
    <List sx={{ p: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          1. When your loan request status has been changed to{" "}
          <span
            style={{
              display: "inline",
              color: "rgb(113 137 201)",
              fontWeight: "600",
              margin: 0,
            }}
          >
            granted
          </span>
          {", "}
          you will have the option to confirm the loan grant to change the
          status of the loan
        </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          2. To do this, go to the Dashboard page, find the loan request and
          click on <ActionButtonTemplate title="Confirm" />
        </Typography>
      </ListItem>
    </List>
  );
};

export const TipFive = () => {
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>1. The payback amount is up to you to decide</Typography>
      </ListItem>
    </List>
  );
};

import React from "react";
import { Collateral } from "../../Components/Collateral";

import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";

const Cards = ({ loan, handleOnMessageClick, setSelectedLoanID }) => {
  return (
    <Grid
      id={loan.id}
      container
      alignItems={{
        xs: "flex-start",
        md: "center",
      }}
      sx={{
        flexDirection: {
          xs: "column",
          md: "row",
        },
        marginBottom: "1em",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: "0px 1px 6px 0px #7C9AEA",
        padding: "1em",
        borderRadius: "6px",
        justifyContent: "space-around",
        width: "700px",
        gap: "10px",
      }}
    >
      <Grid item>
        <Typography fontWeight={400} fontSize={"15px"}>
          Payback date: {moment(loan.date).format("MMM DD,yyyy")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={400} fontSize={"15px"}>
          Username: {loan?.created_by?.username || "deleted"}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: "inline-flex",
          alignItems: "baseline",
          gap: "10px",
        }}
      >
        <Typography fontWeight={400} fontSize={"15px"}>
          Collateral:
        </Typography>
        <Collateral collateral={loan.collateral} />
      </Grid>
      <Grid item>
        <Typography
          fontWeight={400}
          fontSize={"15px"}
          sx={{ width: "fit-content", margin: 0 }}
        >
          Requested Amount: ${loan.requestedAmount}
        </Typography>
      </Grid>
      <Grid sx={{ display: "flex", gap: "8px", margin: { xs: "auto" } }}>
        <Button
          variant="outlined"
          onClick={() => handleOnMessageClick(loan.created_by.id)}
        >
          Message
        </Button>
        {loan.status !== "refunded" && (
          <Button variant="outlined" onClick={setSelectedLoanID}>
            Refund
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Cards;

import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

export const PrivacyPolicy = () => {
  return (
    <Grid container>
      <Grid container sx={{ gap: "10px" }}>
        <Typography component="p" variant="body2" fontSize="1rem">
          Note: By creating a <strong>Profile</strong>, you are consenting to
          this Privacy Policy, meaning you give us permission to use your data
          as described herein. Although your consent is voluntary, without it,
          we will not be able to provide you with access to the{" "}
          <strong>Platform</strong>.
        </Typography>
        <Typography component="p" variant="body2" fontSize="1rem">
          Federal law gives consumers the right to limit some but not all
          sharing. Federal law also requires us to tell you how we collect,
          share, and protect your personal information. Please read this notice
          carefully to understand what we do.
        </Typography>
        <Typography component="p" variant="body2" fontSize="1rem">
          <strong>Loanr</strong>, a Colorado corporation, together with its
          applicable subsidiaries, affiliates, assignees, and successors
          (collectively,"<strong>Loanr</strong>" , “<strong>we</strong>“, “
          <strong>us</strong>” or “<strong>our</strong>“) are committed to your
          personal privacy and your security online. This Privacy Policy
          describes the ways we collect, use, share, and protect your personal
          information.
        </Typography>
        <Grid sx={{ border: "1px solid grey", width: "100%", pl: 1, pr: 1 }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody
                sx={{
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#F2F2F2",
                  },
                  "& tr td ": { border: 0 },
                }}
              >
                <TableRow>
                  <TableCell
                    align="left"
                    colSpan={12}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      backgroundColor: "white",
                      border: "1px solid F2F2F2",
                      pl: 0,
                    }}
                  >
                    Information We Collect About You
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "white" }}>
                  <TableCell
                    align="left"
                    colSpan={12}
                    sx={{
                      border: 0,
                      textDecoration: "underline",
                      fontSize: "15px",
                      pl: 0,
                    }}
                  >
                    We collect information about you in the following ways:
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>(I) Information You Provide</TableCell>
                  <TableCell sx={{ xs: { display: "block" } }} align="left">
                    To use <strong>Loanr’s</strong> website [Getloanr.com],
                    artificial intelligence chatbot, or application
                    (collectively, the “<strong>Platform</strong>“), whether as
                    a prospective borrower or a prospective lender, you will be
                    required to create a user profile (a{" "}
                    <strong>Profile</strong>).This will include furnishing
                    personal information, which may include your name, email
                    address, mailing address, and telephone number. If you are
                    interested in funding or obtaining a loan, you may also be
                    required to provide additional financial information, which
                    may include income, employment, and bank account
                    information. To verify your identity and the information
                    that you have provided, you may also be asked to scan and
                    upload images of certain documents, including your driver’s
                    license or other forms of identification.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>(II) Information from Third Parties</TableCell>
                  <TableCell align="left">
                    Third parties, such as social media platforms, may provide
                    us with information related to you. We may combine this
                    information with information we already have about you to
                    assist prospective lenders in evaluating your loan request
                    or for similar purposes.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    (III) Information from Your Use of the Platform
                  </TableCell>
                  <TableCell align="left">
                    We may collect additional behavioral information based on
                    your use of the <strong>Platform</strong>. This may include
                    information regarding the performance of loans you obtain on
                    the <strong>Platform</strong>, as well as history of
                    Applications or Loan offers, and more general information
                    such as device information and log-in information.{" "}
                    <strong>Loanr</strong> uses cookies when you access your{" "}
                    <strong>Profile</strong> to keep track of your session,
                    authenticate your account, and detect fraud. You can control
                    the use of cookies within your web browser; however, if you
                    reject cookies, your ability to use some features or areas
                    of the <strong>Platform</strong> may be limited.
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: 0 }}>
                  <TableCell>(IV) Mobile analytics</TableCell>
                  <TableCell align="left">
                    We use mobile analytics software which allows us to better
                    understand the functionality of the{" "}
                    <strong>Platform</strong> on your device. This software may
                    record information such as how, when and where you use the
                    application, the events that occur within the application,
                    aggregated usage, performance data, and how the application
                    was downloaded, such as your internet protocol addresses,
                    browser type, internet service provider, location, date/time
                    stamp, referring/exit pages, and information about clicks
                    and taps
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    colSpan={12}
                    sx={{
                      backgroundColor: "white",
                      textDecoration: "underline",
                      fontSize: "15px",
                    }}
                  >
                    How Long We Retain Information We Collect:
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    colSpan={12}
                    style={{ backgroundColor: "white" }}
                  >
                    We will retain your information for the period necessary to
                    fulfill the purposes outlined in this Privacy Policy, or as
                    necessary to comply with legal obligations and our backup,
                    archival, and audit procedures. In the event you request to
                    delete your account, <strong>Loanr</strong> is required to
                    comply with state and federal laws regarding record
                    retention.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Typography
          component="p"
          variant="body1"
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            mt: "10px",
            display: "block",
            width: "100%",
          }}
        >
          How We Use Information We Collect
        </Typography>
        <Typography
          component="p"
          variant="body1"
          sx={{ textDecoration: "underline", fontSize: "15px" }}
        >
          Information we collect from you may be used in one or more of the
          following ways:
        </Typography>
        <Box component="ul">
          <Box component="li">
            <Typography component="p" variant="body2">
              To verify your identity and age as well as to guard against
              potential fraud
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To obtain, with your authorization, third-party data from social
              media sites, or others in connection with your loan request
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To personalize your experience using the <strong>Platform</strong>
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To provide customer service and to send regular communications
              regarding your transactions and related offers
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To assist prospective lenders in evaluating your loan request or
              for similar purposes
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To enable our service providers to provide you with services, such
              as funds payments, billing, and collection activities, as needed
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To market additional products and services to you
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To improve the <strong>Platform</strong> and to develop or offer
              new products and services
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To enforce agreements with you
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              To comply with a court order, legal process, or applicable law,
              including retaining personal data or responding to any government
              or regulatory request
            </Typography>
          </Box>
          <Box component="li">
            <Typography component="p" variant="body2">
              For any other purpose for which you provide your consent
            </Typography>
          </Box>
        </Box>
        <Grid sx={{ border: "1px solid grey", width: "100%", pl: 1, pr: 1 }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody
                sx={{
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#F2F2F2",
                  },
                }}
              >
                <TableRow>
                  <TableCell
                    align="left"
                    colSpan={12}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      backgroundColor: "white",
                      border: 0,
                      pl: 0,
                    }}
                  >
                    Information We Share
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "white" }}>
                  <TableCell
                    align="left"
                    colSpan={12}
                    sx={{
                      border: 0,
                      textDecoration: "underline",
                      fontSize: "15px",
                      pl: 0,
                    }}
                  >
                    We may share information with third parties for everyday
                    business purposes, including:
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={12} sx={{ border: 0 }}>
                    Other <strong>Loanr</strong> users who are interested in
                    considering your loan request, including{" "}
                    <strong>Loanr</strong> users other than the one who
                    ultimately funds your loan request (the “
                    <strong>Lender</strong>“)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={12} sx={{ border: 0 }}>
                    A debt collection company
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={12} sx={{ border: 0 }}>
                    Government officials, including law enforcement, when we are
                    subject to an inquiry, information request, subpoena, court
                    order or similar legal procedure, or we need to do so to
                    comply with law
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={12} sx={{ border: 0 }}>
                    <strong>Loanr</strong> will not sell or license any of your
                    personal information to third parties for their marketing
                    purposes and will only share your personal information with
                    third parties as described above. We may share aggregated,
                    non-identifiable user information with third parties, such
                    as advertisers, prospective lenders, and content
                    distributors.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          sx={{
            border: "1px solid grey",
            width: "100%",
            p: 1,
            mt: "10px",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody
                sx={{
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#F2F2F2",
                  },
                  "& tr td ": { border: 0 },
                }}
              >
                <TableRow>
                  <TableCell>Third Party Data Collection</TableCell>
                  <TableCell sx={{ xs: { display: "block" } }} align="left">
                    Some content on the <strong>Platform</strong> may be
                    provided by third parties, including advertisers, analytics
                    companies, and application providers, which may use cookies
                    or other tracking technologies to collect information about
                    you on the <strong>Platform</strong>. To enrich our profiles
                    of individual users, we may tie this information to the
                    personally identifiable information that they have provided
                    to us. If you have any questions about an advertisement or
                    related content, you should contact the third party provider
                    directly as they may give you the option to not have your
                    information collected or used for targeted advertising
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Information from Children</TableCell>
                  <TableCell align="left">
                    <strong>Loanr</strong> never intends to collect personal
                    information from children under 13 years of age. If we
                    obtain actual knowledge that we have collected personal
                    information from a child under the age of 13, we will
                    promptly remove the data from our system, unless we are
                    legally obligated to retain such data. Please contact us if
                    you believe we have unintentionally collected information
                    from a child under the age of 13
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Compliance with State and Federal Laws</TableCell>
                  <TableCell align="left">
                    This Privacy Policy may not constitute your entire set of
                    privacy rights, as these may also vary from state to state.
                    To be certain of your privacy rights, you may wish to
                    contact the appropriate agency in your state charged with
                    overseeing privacy rights for consumers
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          sx={{
            border: "1px solid grey",
            width: "100%",
            pl: 1,
            pr: 1,
            mt: "10px",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow sx={{ border: 0, "& td": { border: 0 } }}>
                  <TableCell
                    colSpan={12}
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Modifications to This Policy
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: 0 }}>
                  <TableCell
                    colSpan={12}
                    sx={{ backgroundColor: "white", pt: 0 }}
                  >
                    <strong>Loanr</strong> may update this Privacy Policy from
                    time to time as it deems necessary in its sole discretion.
                    By using or accessing the <strong>Platform</strong>, you
                    agree that we may modify this policy at any time without
                    prior notice. If there are material changes to this Privacy
                    Policy, we will provide notice where and in the manner
                    required by applicable law, including but not limited to,
                    through the <strong>Loanr</strong> website. The last
                    modified date at the bottom of this page reflects the most
                    recent version of the Privacy Policy.
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: 0 }}>
                  <TableCell colSpan={12}>
                    Your continued use of the <strong>Platform</strong> after
                    any modification of this policy shall constitute your
                    agreement to be bound by any such changes. We will, however,
                    seek your consent to future modifications to the extent we
                    are required by applicable law.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          sx={{
            border: "1px solid grey",
            width: "100%",
            pl: 1,
            pr: 1,
            mt: "10px",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow sx={{ border: 0, "& td": { border: 0 } }}>
                  <TableCell
                    colSpan={12}
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    How You Can Contact Us
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: 0 }}>
                  <TableCell
                    colSpan={12}
                    sx={{ backgroundColor: "white", pt: 0 }}
                  >
                    You may either log in to your <strong>Profile</strong> on
                    the <strong>Platform</strong> or email us at{" "}
                    <strong>help@getloanr.com</strong> to request access to,
                    correct, or delete any personal information that you have
                    provided to us or to exercise any other data protection
                    right to which we are subject under applicable law, statute,
                    or regulation. Please note that we may not accommodate a
                    request to change information if we believe the change would
                    violate any law or legal requirement or cause the
                    information to be incorrect.
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: 0 }}>
                  <TableCell colSpan={12}>
                    If you have any questions or concerns regarding this Privacy
                    Policy, please contact us by emailing us at{" "}
                    <strong>help@getloanr.com</strong>.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          sx={{
            border: "1px solid grey",
            width: "100%",
            pl: 1,
            pr: 1,
            mt: "10px",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow sx={{ border: 0, "& td": { border: 0 } }}>
                  <TableCell
                    colSpan={12}
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Paypal Acceptable Use Policy
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: 0 }}>
                  <TableCell colSpan={12}>
                    Please visit{" "}
                    <strong
                      style={{ color: "rgba(25, 118, 210)", cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "https://www.paypal.com/us/legalhub/acceptableuse-full?_ga=2.125983949.1775219501.1684426569-1151571425.1684426249",
                          "_blank"
                        )
                      }
                    >
                      this link
                    </strong>{" "}
                    for getting last updated version of Paypal Acceptable Use
                    Policy.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

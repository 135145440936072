import { Grid, TextField, Typography } from "@mui/material";
import React from "react";

export const AddressForm = ({
  addressForm,
  setAddressForm,
  errors,
  setErrors,
  secondAgreementSide = "Lender",
}) => {
  const onChangeAddressForm = ({ target }) => {
    const { name, value } = target;
    if (name === "zip") {
      if (isNaN(value) || value.length > 5) {
        return;
      } else {
        const updatedErrors = errors.filter((key) => key !== name);
        setErrors(updatedErrors);
      }
    }
    if (
      ["state", "city"].includes(name) &&
      value &&
      !/^[a-zA-Z]+$/.test(value)
    ) {
      return;
    }

    const updatedErrors = errors.filter((key) => key !== name);
    setErrors(updatedErrors);
    setAddressForm({
      ...addressForm,
      [name]: value,
    });
  };
  return (
    <Grid>
      <Typography>
        Please fill in the form with your address,{" "}
        <strong>it will not be displayed publicly</strong> on the Loan Board, it
        will be part of the Loan Agreement with the {secondAgreementSide}.
      </Typography>
      {secondAgreementSide === "Lender" && (
        <Typography fontSize={"14px"} sx={{ mt: 2 }}>
          <strong>IMPORTANT:</strong> If you do not get funded for your loan
          request in 24 hours, you will receive your money back!
        </Typography>
      )}
      <TextField
        required
        error={errors.includes("state")}
        helperText={
          errors.includes("state") &&
          "The size of the input data can't be less than 2"
        }
        label="State"
        sx={{
          mt: 1.5,
          "& .MuiOutlinedInput-root": {
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: "10px",
            height: "50px",
          },
        }}
        fullWidth
        name="state"
        placeholder="State (required)"
        value={addressForm.state}
        onChange={onChangeAddressForm}
      />
      <TextField
        label="City"
        error={errors.includes("city")}
        helperText={
          errors.includes("city") &&
          "The size of the input data can't be less than 2"
        }
        required
        sx={{
          mt: 1.5,
          "& .MuiOutlinedInput-root": {
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: "10px",
            height: "50px",
          },
        }}
        fullWidth
        name="city"
        placeholder="City (required)"
        value={addressForm.city}
        onChange={onChangeAddressForm}
      />
      <TextField
        label="Street"
        error={errors.includes("street")}
        helperText={
          errors.includes("street") &&
          "The size of the input data can't be less than 2"
        }
        required
        sx={{
          mt: 1.5,
          "& .MuiOutlinedInput-root": {
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: "10px",
            height: "50px",
          },
        }}
        fullWidth
        name="street"
        placeholder="Street (required)"
        value={addressForm.street}
        onChange={onChangeAddressForm}
      />
      <TextField
        label="Building"
        error={errors.includes("building")}
        helperText={errors.includes("building") && "The input can't be empty"}
        sx={{
          mt: 1.5,
          "& .MuiOutlinedInput-root": {
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: "10px",
            height: "50px",
          },
        }}
        fullWidth
        name="building"
        placeholder="Building (optional)"
        value={addressForm.building}
        onChange={onChangeAddressForm}
      />
      <TextField
        label="Apartment"
        sx={{
          mt: 1.5,
          "& .MuiOutlinedInput-root": {
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: "10px",
            height: "50px",
          },
        }}
        fullWidth
        name="apartment"
        placeholder="Apartment (optional)"
        value={addressForm.apartment}
        onChange={onChangeAddressForm}
      />
      <TextField
        label="ZIP"
        error={errors.includes("zip")}
        helperText={errors.includes("zip") && "Min. length - 5 digits"}
        required
        sx={{
          mt: 1.5,
          "& .MuiOutlinedInput-root": {
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: "10px",
            height: "50px",
          },
        }}
        fullWidth
        name="zip"
        placeholder="ZIP (required)"
        value={addressForm.zip}
        onChange={onChangeAddressForm}
        type="text"
      />
    </Grid>
  );
};

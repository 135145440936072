import React from "react";
import Box from "@mui/material/Box";
import { Avatar, Card, Grid, Typography } from "@mui/material";
import moment from "moment";

export const ReviewCard = ({ review }) => {
  const { authorUsername, authorRole, content, createdAt } = review;

  return (
    <Card
      sx={{
        display: "flex",
        backgroundColor: "#f3f3f3",
        borderRadius: "8px",
        mb: "8px",
        width: {
          sx: "90vw",
          md: "400px",
        },
        p: 1,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Grid
          sx={{
            flex: "1 0 auto",
            display: "flex",
            alignItems: "center",
            paddingBottom: "8px",
          }}
        >
          <Avatar alt={authorUsername}>
            {authorUsername[0].toUpperCase()}
          </Avatar>
          <Typography component="p" variant="subtitle1" sx={{ ml: "5px" }}>
            @{authorUsername}
            <Typography
              variant="subtitle2"
              component="span"
              sx={{ color: "rgb(155 143 143)" }}
            >
              ({authorRole})
            </Typography>
          </Typography>
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ color: "rgb(155 143 143)", pt: "5px", ml: "3px" }}
          >
            {moment(createdAt).format("MMM-DD-YY")}
          </Typography>
        </Grid>
        <Grid>
          <Typography>{content}</Typography>
        </Grid>
      </Box>
    </Card>
  );
};

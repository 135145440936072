import { setReviewAPI } from "../api/review";
import { toast } from "react-toastify";

export const setReviewAction =
  (loanId, type, cb, loanData) => async (dispatch) => {
    try {
      const response = await setReviewAPI(loanId, type, loanData);
      if (typeof cb === "function" && response.status === 200) {
        cb();
      }
      dispatch({ type: "UNLOAD" });
    } catch (error) {
      dispatch({ type: "UNLOAD" });
      toast.error(error.response?.data?.message || "Something went wrong");
      if (typeof cb === "function") {
        cb(error);
      }
    }
  };

import React from "react";

import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const LinkToProfile = ({
  user,
  backLocation = "dashboard",
  target,
  admin = false,
  fontSize = "13px",
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const path = admin ? "admin" : "dashboard";

  return (
    <Typography
      onClick={() =>
        user &&
        navigate(
          `/${path}/profile/${user?.id}?location=${backLocation}&target=${target}`
        )
      }
      sx={{
        color: user ? theme.palette.primary.main : "#ccc",
        cursor: user ? "pointer" : "default",
        textDecoration: "none",
        textTransform: "none",
        fontSize: fontSize,
        p: 0,
        ml: "3px ",
      }}
    >
      {user ? `@${user.username}` : "@_deleted"}
    </Typography>
  );
};

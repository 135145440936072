import { Button } from "@mui/material";
import React from "react";

export const ActionButtonTemplate = ({
  title,
  styles,
  color = "primary",
  variant = "contained",
  size = "small",
}) => {
  return (
    <Button
      variant={variant}
      size={size}
      color={color}
      sx={{
        textTransform: "none",
        width: "fit-content",
        ...styles,
      }}
    >
      {title}
    </Button>
  );
};

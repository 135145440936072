import { ClipLoader } from "react-spinners";
export const ChatLoader = ({ color = "rgba(25, 118, 210)", size = 110 }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ClipLoader color={color} size={size} />
      <p style={{ color }}>Please Wait...</p>
    </div>
  );
};

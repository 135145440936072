import React from "react";

import { Button, Grid, Typography } from "@mui/material";
import { ImageCarousel } from "./ImageCarousel";

export const CollateralView = ({ collateral, closeCollateral }) => {
  const { title, description, url, urlCaption, images } = collateral;
  const currentImages = images?.length ? images : null;
  return (
    <Grid
      container
      sx={{ flexDirection: "column", gap: "15px", width: "100%" }}
    >
      <ImageCarousel images={currentImages} />
      {title && (
        <Grid item>
          <Typography sx={{ color: "#a59e9e" }}>Title:</Typography>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>
        </Grid>
      )}
      {description && (
        <Grid item>
          <Typography sx={{ color: "#a59e9e" }}>Description:</Typography>
          <Typography
            component="h3"
            sx={{
              fontSize: "18px",
            }}
          >
            {description}
          </Typography>
        </Grid>
      )}
      <Button
        onClick={closeCollateral}
        sx={{ width: "fit-content", marginLeft: "auto" }}
      >
        Back
      </Button>
    </Grid>
  );
};

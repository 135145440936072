export const styles = (
  heroBg,
  heroBgMob,
  isSmallMediaQuery,
  gradientCircle
) => {
  return {
    header: {
      position: "relative",
      width: "100%",
      minHeight: "100vh",
      backgroundImage: {
        xs: `url(${heroBgMob})`,
        md: `url(${heroBg})`,
      },
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      overflow: "hidden",
    },
    headerOverlay: {
      position: "absolute",
      backgroundColor: "#141c2bb9",
      zIndex: 1,
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
    },
    headerContentContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 15,
      color: "white",
      width: "90%",
    },
    headerBtnContainer: {
      position: "absolute",
      top: "70%",
      left: "50%",
      witdh: "fit-content",
      transform: "translate(-50%,-100px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 15,
      color: "white",
      mt: 8,
      animation: `btn 1.5s ease-in-out forwards`,
      "@keyframes btn": {
        "0%": {
          transform: "translate(-50%,1000px)",
        },
        "100%": {
          transform: "translate(-50%,0)",
        },
      },
    },
    headerTitle: {
      fontSize: "calc(3em + 1vmin)",
      lineHeight: { xs: 1.1, md: 0.9 },
      opacity: 0,
      textAlign: "center",
      animation: `title 1.5s ease-in-out forwards`,
      origin: "bottom",
      "@keyframes title": {
        "0%": {
          opacity: 0,
        },
        "100%": {
          opacity: 1,
        },
      },
    },
    headerDescription: {
      fontSize: "1.5rem",
      lineHeight: 1.2,
      display: "block",
      textAlign: "center",
      opacity: 0,
      width: "100%",
      animation: `desc 1s ease-in-out 0.5s forwards`,
      "@keyframes desc": {
        "0%": {
          opacity: 0,
        },
        "100%": {
          opacity: 1,
        },
      },
    },
    headerExplanation: {
      fontSize: "16px",
      opacity: 0,
      mt: "8px",
      textAlign: "center",
      animation: `expl 1s ease-in-out 0.5s forwards`,
      "@keyframes expl": {
        "0%": {
          opacity: 0,
        },
        "100%": {
          opacity: 1,
        },
      },
    },
    section: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "65px 25px",
      //   mt: "25px",
    },
    sectionTitle: {
      fontSize: "36px",
      textAlign: "center",
    },
    p2pList: {
      display: "inline-block",
      flexDirection: "column",
      listStyle: "none",
      gap: 2,
      p: "0 25px",
    },
    p2pListItem: {
      padding: ".5rem 0",
      "&::before": {
        content: '""',
        height: "1.5rem",
        width: "1.5rem",
        display: "block",
        float: "left",
        marginLeft: "-1.5rem",
        background: `url(${gradientCircle})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      },
    },
    p2pListItemContent: {
      fontSize: 20,
      mt: isSmallMediaQuery ? 0 : 2,
      textAlign: "left",
      display: "inline",
    },
    p2pBtn: {
      display: "inline-block",
      color: "white",
      fontSize: "1rem",
      textDecoration: "none",
      padding: "8px 10px",
      margin: {
        xs: "15px 0px 0px 50%",
        md: "15px 0 0 25px",
      },
      transform: {
        xs: "translateX(-50%)",
        md: "translateX(0)",
      },
    },
    p2pImageContainer: {
      width: isSmallMediaQuery ? "90%" : 400,
      order: isSmallMediaQuery ? -1 : 1,
    },
    p2pImage: {
      width: "100%",
      marginLeft: isSmallMediaQuery ? 0 : 24,
    },
    faqContainer: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      background:
        "linear-gradient(90deg, rgba(47,120,177,0.8575805322128851) 34%, rgba(35,136,210,0.6867121848739496) 79%)",
      pb: 5,
      pt: 5,
      mt: 4,
    },
  };
};

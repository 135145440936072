import React from "react";

import { Button, Grid } from "@mui/material";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";

const options = {
  navigation: {
    nextEl: ".slider-next",
    prevEl: ".slider-prev",
  },
  lazy: {
    loadPrevNext: true,
  },
  effect: "fade",
  loop: true,
  pagination: { clickable: true },
  autoHeight: true,
};

export const ImageCarousel = ({ images }) => {
  return (
    <>
      {images?.length && (
        <div style={{ width: "100%" }}>
          <Button variant="text" className="slider-prev">
            Prev
          </Button>
          <Button variant="text" className="slider-next">
            Next
          </Button>
          <Swiper {...options} modules={[Navigation, Pagination]}>
            {images.map((imagePath) => {
              return (
                <SwiperSlide key={imagePath}>
                  <Grid container sx={{ justifyContent: "center" }}>
                    <img
                      src={imagePath}
                      alt=""
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </Grid>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </>
  );
};

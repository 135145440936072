import React, { memo } from "react";

import { Paper, Grid, useMediaQuery, Modal, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { LoanCardButton } from "../../../Components";
import { terms } from "../../../constants";

import { getStyles } from "./EditLoanModalStyles";

export const EditLoanModal = memo(
  ({ open, closeModalHandler, submitCallback, revise, PaymentComponent }) => {
    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    const styles = getStyles();

    let currentTerms = !revise && terms.updateLoan;

    return (
      <Modal sx={{ zIndex: 1300 }} open={open}>
        <Paper
          className="request-terms-wrapper fade-in-slow"
          sx={styles.wrapper}
          elevation={isSmallMediaQuery ? 0 : 6}
        >
          {!revise && terms.updateLoan.icon}
          {!revise && (
            <Typography
              fontWeight={700}
              style={{ marginBottom: "1em" }}
              fontSize={"20px"}
            >
              {currentTerms.title}
            </Typography>
          )}

          {!revise && currentTerms.text}

          <Grid
            item
            className="buttons-container"
            xs={12}
            spacing={1}
            sx={styles.buttonsContainer}
            container
          >
            <Grid item sx={styles.buttonsWrapper}>
              {PaymentComponent && (
                <Grid sx={{ mt: 1, order: 2 }}>{PaymentComponent}</Grid>
              )}

              {!revise && (
                <LoanCardButton
                  title={terms.updateLoan.actionTitle}
                  btnProps={{
                    variant: "contained",
                  }}
                  cb={submitCallback}
                  styles={styles.publishButton}
                />
              )}
            </Grid>
            <LoanCardButton
              title="Cancel"
              btnProps={{
                color: "secondary",
                variant: "contained",
              }}
              cb={closeModalHandler}
              styles={styles.cancelButton}
            />
          </Grid>
        </Paper>
      </Modal>
    );
  }
);

import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AppBar, Toolbar, Box, IconButton, Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Menu as MenuIcon,
  Login as LoginIcon,
  Home as HomeIcon,
} from "@mui/icons-material";

export const AppNav = ({
  navItems,
  handleDrawerToggle,
  handleMenuItemClick,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const isPrivacyOrTermsPage = ["/privacy-policy", "/terms"].includes(
    location.pathname
  );

  const CurrentMenuIcon = isPrivacyOrTermsPage ? HomeIcon : MenuIcon;
  return (
    <AppBar
      component="nav"
      sx={{
        mt: 1,
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "95vw",
        backgroundColor: "transparent",
        paddingRight: "0 !important",
      }}
    >
      <Toolbar sx={{ backgroundColor: theme.palette.primary.main }}>
        <Box
          sx={{
            width: "100px",
            backgroundColor: "transparent",
            border: "none",
            flexGrow: 1,
          }}
        >
          <img
            src="/logo.svg"
            width="100"
            alt="logo"
            style={{ filter: "invert(1)" }}
          />
        </Box>
        <Grid
          container
          sx={{ display: { md: "none", sm: "flex" } }}
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ fontSize: "2rem" }}
          >
            <CurrentMenuIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            sx={{
              color: "white",
              fontSize: "2rem",
              pb: 0,
            }}
          >
            <Link to="/login" style={{ color: "white" }}>
              <LoginIcon fontSize="inherit" />
            </Link>
          </IconButton>
        </Grid>

        <Box sx={{ display: { sm: "none", md: "block", xs: "none" } }}>
          {navItems.map((item) => (
            <Button
              onClick={() => handleMenuItemClick(item)}
              key={item}
              sx={{
                color: "#fff",
                "&:hover": {
                  borderBottom: "1px solid white",
                  borderRadius: 0,
                },
              }}
            >
              {item}
            </Button>
          ))}
        </Box>

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/login")}
          sx={{
            p: "6px 16px",
            ml: 1,
            transition: "all ease-in-out 250ms",
            border: `1px solid ${theme.palette.secondary.main}`,
            display: {
              xs: "none",
              md: "block",
            },
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Log in
        </Button>
      </Toolbar>
    </AppBar>
  );
};

import { useState } from "react";
import { useDispatch } from "react-redux";

import { Grid, TextField } from "@mui/material";
import { LoanCardButton } from "./LoanCardButton";
import { setReviewAction } from "../actions/review";

import { toast } from "react-toastify";

export const ReviewForm = ({
  user,
  loanID,
  authorRole,
  closeReviewForm,
  reviewReceiver,
  postReviewCallback,
  isOnLoanCard,
}) => {
  const [review, setReview] = useState({
    receiverID: reviewReceiver.id,
    authorRole,
    content: "",
  });
  const dispatch = useDispatch();

  const handlePostReview = () => {
    if (review.content.trim()) {
      dispatch(
        setReviewAction(
          loanID,
          "feedback",
          () => {
            setReview({ receiverID: "", authorRole: "", content: "" });
            toast("Review has been added successfully");
            closeReviewForm();
            if (typeof postReviewCallback === "function") {
              postReviewCallback();
            }
          },
          {
            review: {
              authorID: user.id,
              authorUsername: user.username,
              ...review,
            },
          }
        )
      );
    } else {
      toast.error("Review couldn't be empty");
    }
  };

  return (
    <Grid
      container
      sx={{ mt: "8px", alignItems: "center", paddingLeft: !isOnLoanCard && 2 }}
    >
      <Grid container item sx={{ flexDirection: "column", flex: 1 }}>
        <TextField
          value={review.content}
          onChange={(e) =>
            setReview((prevState) => ({
              ...prevState,
              content: e.target.value,
            }))
          }
          inputProps={{
            style: {
              fontSize: "14px",
              flex: 1,
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: "12px",
            },
          }}
          id="feedbackText"
          label={`Review to @${reviewReceiver.username}`}
          variant="outlined"
          size="small"
          multiline
          rows={isOnLoanCard ? 2 : 3}
        />
      </Grid>
      <Grid
        container
        sx={{
          flexDirection: "column",
          ml: "10px",
          width: "fit-content",
        }}
      >
        <LoanCardButton
          title={isOnLoanCard ? "Cancel" : "Not Now"}
          btnProps={{
            color: "warning",
            size: isOnLoanCard ? "small" : "large",
          }}
          cb={() => {
            closeReviewForm();
            !isOnLoanCard && postReviewCallback();
            setReview({
              receiverID: "",
              content: "",
              authorRole: "",
            });
          }}
        />
        <LoanCardButton
          styles={{ mt: "5px", width: "100%" }}
          btnProps={{ size: isOnLoanCard ? "small" : "large" }}
          title="Post"
          cb={handlePostReview}
        />
      </Grid>
    </Grid>
  );
};

import React from "react";
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

export function AddForm({ children, announcement, handleAnnoucementChange }) {
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        mt: 2,
        flexDirection: "column",
      }}
    >
      <Grid
        container
        sx={{
          flexDirection: "column",
        }}
      >
        <TextField
          name="description"
          value={announcement.description}
          label="Description"
          onChange={handleAnnoucementChange}
          multiline
          rows={3}
          sx={{ mt: 1 }}
        />
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <TextField
            name="severity"
            select
            label="Type"
            value={announcement.severity}
            onChange={handleAnnoucementChange}
            variant="outlined"
            sx={{ mt: 1, width: "50%" }}
          >
            {["Success", "Info", "Error"].map((option) => (
              <MenuItem key={option} value={option}>
                {option === "Error" ? "Warning" : option}
              </MenuItem>
            ))}
          </TextField>
          <RadioGroup
            value={announcement.enabled}
            onChange={handleAnnoucementChange}
          >
            <FormControlLabel
              name="enabled"
              value={true}
              control={<Radio />}
              label="Enable"
            />
            <FormControlLabel
              name="enabled"
              value={false}
              control={<Radio />}
              label="Disable"
            />
          </RadioGroup>
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
}

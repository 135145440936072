import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

export const TermsAndConditions = () => {
  return (
    <Grid container>
      <Grid container sx={{ gap: "10px" }}>
        <Typography component="p" variant="body2" fontSize="1rem">
          <strong>Loanr</strong> is a secured loaning marketplace, which means
          that your peers will store your belongings in their possession until
          the loan is repaid in full. Other loaning platforms may refer you to
          collections if you do not pay back on time. At <strong>Loanr</strong>,
          the lender will keep your possession in case of repayment- no
          collection agencies involved!
        </Typography>
        <Grid
          sx={{
            border: "1px solid grey",
            width: "100%",
            p: 1,
            mt: "10px",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody
                sx={{
                  "& tr:nth-of-type(even)": {
                    backgroundColor: "#F2F2F2",
                  },
                  "& tr td ": { border: 0 },
                }}
              >
                <TableRow sx={{ border: 0 }}></TableRow>
                <TableRow>
                  <TableCell>About APR</TableCell>
                  <TableCell sx={{ xs: { display: "block" } }} align="left">
                    <strong>APR</strong> (Annual Percentage Rate) is the
                    annualized interest rate charged when you request money in
                    advance. It combines the nominal interest rate and some fees
                    or additional costs that can be involved when you get a
                    loan. The APR on quick loan options varies, with lenders
                    offering rates <strong>of 5.99% to 35.99%</strong> on
                    unsecured personal loans for borrowers with a good credit
                    score.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={12}>
                    <strong>Disclosure:</strong> The maximum Annual Interest
                    Rate can only go up to <strong>36%</strong> on{" "}
                    <strong>Loanr</strong>.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container sx={{ gap: "10px" }}>
          <Typography component="p" variant="body2" fontSize="1rem">
            Our app is not involved in the lending process, thus we can’t state
            any specific APR you will be offered applying for a loan today. The
            credit decisions and terms depend solely on the lender but can not
            exceed <strong>36% APR</strong>.
          </Typography>
          <Typography component="p" variant="body2" fontSize="1rem">
            The lender is legally required to inform you of the APR and other
            loan terms before executing the agreement. Some states have strict
            laws limiting the APR rates and fees that a lender can charge you.
          </Typography>
          <Typography component="p" variant="body2" fontSize="1rem">
            Only the lender you are connected with can inform you of specific
            rates and terms in accordance with the local laws.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

import React from "react";

import {
  List,
  ListItem,
  Typography,
  useMediaQuery,
  Grid,
  Button,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { ActionButtonTemplate } from "../ActionButtonTemplate";

export const TipOne = () => {
  const isMobileView = useMediaQuery("(max-width:1100px)");
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>1. Go to the LoanBoard page</Typography>
        {isMobileView && <MenuIcon sx={{ ml: "3px" }} />}
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>2. Select a loan request from the list</Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          3. Click on {<ActionButtonTemplate title="Message" />} to start a
          conversation with the borrower
        </Typography>
      </ListItem>
    </List>
  );
};

export const TipTwo = () => {
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>1. Go to the LoanBoard page </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>2. Select a loan request</Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography sx={{ width: "fit-content" }}>
          3. Click on the collateral title{" "}
        </Typography>
        {
          <Grid
            container
            item
            sx={{
              width: "fit-content",
              flexDirection: "column",
              textAlign: { xs: "", md: "center" },
              border: "1px solid grey",
              p: "3px 5px",
              ml: "5px",
            }}
          >
            <Typography
              fontSize={"15px"}
              fontWeight={400}
              sx={{ margin: "0 auto" }}
            >
              Collateral:
            </Typography>

            <Button
              variant="text"
              sx={{
                p: 0,
                m: 0,
                width: "fit-content",
              }}
              onClick={() => {}}
            >
              <Typography
                sx={{
                  textTransform: "none",
                  fontSize: "13px",
                }}
              >
                Collateral Title
              </Typography>
            </Button>
          </Grid>
        }
      </ListItem>
    </List>
  );
};

export const TipThree = () => {
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>1. Go to the LoanBoard page </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>2. Select the loan you want to grant</Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          3. Click on {<ActionButtonTemplate title="Grant" />}
        </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>4. Wait for borrower confirmation.</Typography>
      </ListItem>
    </List>
  );
};

export { TipOne as LenderTipOne };
export { TipTwo as LenderTipTwo };
export { TipThree as LenderTipThree };

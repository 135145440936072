import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { LinkToProfile, CollateralView } from "../../Components";
const Cards = ({ loan }) => {
  const [isCollateralModalOpen, setIsCollateralModalOpen] = useState(false);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        marginBottom: "1em",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: "0px 1px 6px 0px #7C9AEA",
        borderRadius: "6px",
        padding: "1em",
      }}
    >
      <Grid item xs={12}>
        {loan.granted_by ? (
          <Typography>
            <LinkToProfile user={loan.granted_by} admin={true} /> granted a loan
            requested by <LinkToProfile user={loan.created_by} admin={true} />
          </Typography>
        ) : (
          <Typography>
            User <LinkToProfile user={loan.created_by} admin={true} /> requested
            for a loan
          </Typography>
        )}
        <Typography></Typography>
        <Grid container style={{ marginTop: "0.3em" }} spacing={1} item xs={12}>
          <Grid item container xs={4}>
            <Grid item xs={12}>
              <Typography fontSize={14} textAlign="center">
                Payback Date
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={14} textAlign="center">
                {moment(loan.date).format("MMM DD,yyyy")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            <Grid item xs={12}>
              <Typography fontSize={14} textAlign="center">
                Collateral
              </Typography>
            </Grid>
            <Grid item container xs={12} sx={{ justifyContent: "center" }}>
              {loan.isCollateralExist ? (
                <>
                  <Button
                    variant="text"
                    sx={{
                      p: 0,
                      m: 0,
                      width: "fit-content",
                      margin: { xs: 0, md: "0 auto" },
                    }}
                    onClick={
                      loan.collateral?.airbnb_url
                        ? () =>
                            window.open(loan.collateral.airbnb_url, "_blank")
                        : () => setIsCollateralModalOpen(true)
                    }
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        fontSize: "1rem",
                      }}
                    >
                      {loan.collateral.caption}
                    </Typography>
                  </Button>
                  <Dialog
                    open={isCollateralModalOpen}
                    onClose={() => setIsCollateralModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                      "& .MuiDialog-container .MuiPaper-root": {
                        width: { xs: "100vw", md: "90vw" },
                        m: {
                          xs: 1,
                          md: 4,
                        },
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <DialogContent>
                      <CollateralView
                        collateral={loan.collateral}
                        closeCollateral={() => setIsCollateralModalOpen(false)}
                      />
                    </DialogContent>
                  </Dialog>
                </>
              ) : (
                <Typography>{loan.collateral.caption}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            <Grid item xs={12}>
              <Typography fontSize={14} textAlign="center">
                Amount
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={14} textAlign="center">
                ${loan.requestedAmount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Cards;

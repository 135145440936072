import { forwardRef } from "react";

import { LoanCard } from "../../Components";
import {
  Dialog,
  IconButton,
  List,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const LoansList = ({ isModalOpen, closeModal, username, data }) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      TransitionComponent={Transition}
      sx={{
        "& > div > div": {
          margin: {
            xs: "10px",
            md: "24px",
          },
        },
      }}
    >
      <Toolbar
        sx={{
          position: "relative",
          backgroundColor: "#6c89d8",
        }}
      >
        <Typography sx={{ color: "white" }}>
          @{username} {data?.title}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          size="small"
          onClick={closeModal}
          aria-label="close"
          sx={{ ml: "auto", color: "white" }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ padding: "5px", overflowY: "auto", height: "auto" }}>
        {data?.loans?.map((loan) => (
          <LoanCard
            key={loan.id}
            loan={loan}
            isShort={true}
            styles={{ width: { xs: "100%", md: "fit-content" } }}
          />
        ))}
      </List>
    </Dialog>
  );
};

import React from "react";

import { Alert, Collapse } from "@mui/material";

export function AnnouncementsTable({
  children,
  announcements,
  isAnnouncemetsTableOpen,
}) {
  return (
    <Collapse in={isAnnouncemetsTableOpen}>
      {announcements.map((announcement) => {
        return (
          <Alert
            key={announcement._id}
            severity={announcement.severity.toLowerCase()}
            sx={{ mt: 1 }}
          >
            {announcement.description}
          </Alert>
        );
      })}
    </Collapse>
  );
}

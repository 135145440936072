import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

const LoginNav = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{ padding: "1rem" }}
      item
      xs={12}
      justifyContent="space-between"
      alignItems={"center"}
    >
      <Grid
        item
        xs={6}
        sx={{
          [theme.breakpoints.up("md")]: {
            "&>a>img": {
              filter: "invert(1)",
            },
          },
        }}
      >
        <Link to="/">
          <img src="/logo.svg" width="100" alt="logo" />
        </Link>
      </Grid>
    </Grid>
  );
};

export default LoginNav;

import { forwardRef } from "react";

import { LoanCard } from "../../Components";
import {
  AppBar,
  Dialog,
  IconButton,
  List,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ActiveLoanRequestsModal = ({
  isModalOpen,
  closeModal,
  username,
  activeLoanRequests,
  grantLoanHandler,
  handleLoanNotRepaid,
}) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      TransitionComponent={Transition}
      sx={{
        "& > div > div": {
          margin: {
            xs: "10px",
            md: "24px",
          },
        },
      }}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "#6c89d8",
        }}
      >
        <Toolbar>
          <Typography>@{username} active loan requests</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeModal}
            aria-label="close"
            sx={{ ml: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!activeLoanRequests?.length ? (
        <Typography
          sx={{ p: 3, fontSize: "1rem", fontWeight: "bold", m: "0 auto" }}
        >
          No data to display
        </Typography>
      ) : (
        <List sx={{ padding: "5px", overflowY: "scroll", height: "auto" }}>
          {activeLoanRequests.map((loanRequest) => (
            <LoanCard
              key={loanRequest.id}
              loan={loanRequest}
              isShort={true}
              styles={{ width: { xs: "100%", md: "fit-content" } }}
              grantLoanHandler={() => {
                closeModal();
                grantLoanHandler(loanRequest);
              }}
              handleLoanNotRepaid={() => {
                closeModal();
                handleLoanNotRepaid(loanRequest);
              }}
            />
          ))}
        </List>
      )}
    </Dialog>
  );
};

import { Grid, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import { LoanCardButton } from "../../Components";

export const ChatToPDFModal = ({
  isModalOpen,
  submitHandler,
  cancelHandler,
}) => {
  return (
    <Modal sx={{ zIndex: 5000 }} open={isModalOpen}>
      <>
        <Paper
          className="fade-in-slow"
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            padding: "1em 1.5em",
            borderRadius: "8px",
            maxHeight: "100%",
            width: "fit-content",
            maxWidth: {
              xs: "90%",
              sm: "75%",
              md: "450px",
            },
            overflowY: "auto",
          }}
        >
          <Typography style={{ marginBottom: "1em" }} fontSize="15px">
            Do you want to download message history as PDF file?
          </Typography>

          <Grid
            item
            xs={12}
            spacing={1}
            sx={{
              width: "100%",
              marginTop: "0.5em",
              justifyContent: {
                xs: "center",

                md: "flex-end",
              },
            }}
            container
          >
            <Grid
              item
              sx={{
                height: "fit-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <LoanCardButton
                title="Cancel"
                btnProps={{
                  color: "secondary",
                  variant: "contained",
                }}
                cb={cancelHandler}
                styles={{
                  mr: 1,
                  fontSize: { xs: "16px", md: "14px" },
                }}
              />

              <LoanCardButton
                title="Download"
                btnProps={{
                  variant: "contained",
                }}
                cb={submitHandler}
                styles={{
                  mr: 1,
                  fontSize: { xs: "16px", md: "14px" },
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    </Modal>
  );
};

import { createChatAction } from "./actions/chats";
import { getLoans } from "./actions/loan";
import { months } from "./constants";

export const getFormatedDate = (data) => {
  const day = new Date(data.createdAt).getDate();
  const month = months[new Date(data.createdAt).getMonth()];
  return [month, day];
};

const maxRequestedAmount = 1000;
const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

export const validationRules = (blockName, formState) => {
  if (blockName === "loan") {
    return {
      description: {
        minLength: 3,
        required: true,
      },
      requestedAmount: {
        minValue: 0.01,
        maxValue: maxRequestedAmount,
        required: true,
      },
      paybackAmount: {
        minValue: +formState.requestedAmount,
        maxValue: (
          +formState.requestedAmount +
          +formState.requestedAmount * 0.15
        ).toFixed(0),
        required: true,
      },
      date: {
        dateGreaterThan: Date.now(),
        required: true,
      },
      borrowerLocation: {
        minLength: 3,
        required: true,
      },
    };
  }
  if (blockName === "registration") {
    return {
      name: {
        minLength: 3,
        required: true,
      },
      username: {
        minLength: 3,
        required: true,
      },
      email: {
        match: emailRegex,
        required: true,
      },
      contact: {
        minLength: 10,
        required: true,
      },
      password: {
        minLength: 1,
        required: true,
      },
      idCard: {
        required: true,
      },
    };
  }
};

export const formValidator = (formState, blockName) => {
  let isValid = true;
  let rejectedField;
  const stateInputKeys = Object.keys(formState);
  const currentRuleSet = validationRules(blockName, formState);

  for (let inputKey of stateInputKeys) {
    if (inputKey in currentRuleSet) {
      for (let rule of Object.keys(currentRuleSet[inputKey])) {
        let currentInputValue = formState[inputKey];
        let currentRule = currentRuleSet[inputKey];

        switch (rule) {
          case "requiredWith":
            if (formState[currentRuleSet[inputKey][rule]]) {
              isValid = currentInputValue;
              break;
            }
            break;
          case "required":
            isValid = !!formState[inputKey] === currentRuleSet[inputKey][rule];
            break;
          case "minLength":
            isValid = currentInputValue.length >= currentRule.minLength;
            break;
          case "dateGreaterThan":
            const formatedPaybackDate = new Date(currentInputValue).getTime();
            isValid = formatedPaybackDate > currentRule.dateGreaterThan;
            break;
          case "minValue":
            isValid = Number(currentInputValue) >= currentRule.minValue;
            break;
          case "maxValue":
            isValid = Number(currentInputValue) <= currentRule.maxValue;
            break;

          case "isIncluded":
            const index = currentRule.isIncluded.indexOf(currentInputValue);
            isValid = index === -1 ? false : true;
            break;
          case "match":
            if (currentInputValue) {
              isValid = currentRuleSet[inputKey].match.test(currentInputValue);
              break;
            }
            break;

          default:
            return false;
        }
        if (!isValid) {
          rejectedField = inputKey;
          break;
        }
      }
    }
    if (!isValid) break;
  }
  if (isValid) {
    return [isValid];
  } else {
    return [isValid, rejectedField];
  }
};

export const imageReducer = (file) => {
  if (!file) return;
  return new Promise((resolve) => {
    const MAX_WIDTH = 1280;
    const MAX_HEIGHT = 720;
    const MIME_TYPE = file.type;
    const QUALITY = 1;

    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onload = async function () {
      URL.revokeObjectURL(this.src);
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement("canvas");
      canvas.style.display = "none";
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      const blob = await getBlob(canvas, MIME_TYPE, QUALITY);
      let reducedImageFile = new File([blob], file.name, file);
      resolve(reducedImageFile);
    };
  });

  function getBlob(canvas, MIME_TYPE, QUALITY) {
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        MIME_TYPE,
        QUALITY
      );
    });
  }

  function calculateSize(img, maxWidth, maxHeight) {
    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }
    return [width, height];
  }
};

export const getImage = (src) => {
  return new Promise(function (resolve, reject) {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = function () {
      reject(src);
    };
    img.src = src;
  });
};

export const createEllipsis = (str, num) => str.slice(0, num) + "...";

export const normalizeCollateral = (loan) => {
  const { collateral, own_collateral, airbnb_url } = loan;

  let currentCollateral = {
    ...collateral,
    caption:
      collateral.title?.length > 22
        ? createEllipsis(collateral.title, 22)
        : collateral.title
        ? collateral.title
        : "check",
  };
  if (!currentCollateral.title) {
    currentCollateral.paystubsOnly = true;
  }

  if (typeof collateral === "string") {
    switch (collateral) {
      case "Room/rent on Airbnb":
        currentCollateral = {
          caption: collateral,
          description: "",
          category: "",
          airbnb_url,
        };
        loan.isCollateralExist = true;
        break;
      case "Own collateral":
        currentCollateral = {
          caption:
            own_collateral.length > 22
              ? createEllipsis(own_collateral, 22)
              : own_collateral,
          description: own_collateral,
          type: "own",
        };
        loan.isCollateralExist = true;
        break;
      default:
        currentCollateral = {
          caption: collateral,
        };
        loan.isCollateralExist = false;
    }
  }
  return currentCollateral;
};

export const getPublishingFee = (requestedAmount) => {
  if (requestedAmount && +requestedAmount < 199) {
    return 1;
  } else {
    return Math.floor(+requestedAmount * 0.01);
  }
};
/*Handler for the API response of the create new chat event */
const handleCreateChatResponse = (
  setShowPaymentModal,
  dispatch,
  navigate,
  response,
  messagesURL //Chat page url
) => {
  if (response.singleChat) {
    /*If user have valid monthly subscription response will include new chat object */
    dispatch({
      type: "SET_SELECTED_CHAT",
      payload: { singleChat: response.singleChat, newOne: true },
    });

    navigate(messagesURL);
  }
  if (response.status === "invalid") {
    /*If monthly subscription is invalid - initiate openning notification modal with payment subscribe button*/
    setShowPaymentModal(response);
  }
};
export const createChat = (
  userID,
  setShowPaymentModal,
  dispatch,
  navigate,
  receiverID,
  messagesURL
) => {
  /* Dispacth create new chat action with response handler */
  dispatch(
    createChatAction(`${userID},${receiverID}`, (response) => {
      handleCreateChatResponse(
        setShowPaymentModal,
        dispatch,
        navigate,
        response,
        messagesURL
      );
    })
  );
  dispatch({ type: "UNLOAD" });
};

/* Handler for the click on the Message button (Loan card and Profile page) */
export const handleOnMessageClick = async (
  chats,
  userID,
  setSelectedReceiver,
  dispatch,
  navigate,
  receiverID,
  messagesURL = "/dashboard/messages"
) => {
  /*Check for: Chat has been fetched already */
  const chatID = chats?.find((chat) =>
    chat.users.find((user) => user.id === receiverID)
  )?.id;
  if (chatID) {
    dispatch({ type: "SET_SELECTED_CHAT", payload: { chatID } });
    return navigate(messagesURL);
  } else {
    /*if chat doesn't exist, initiate creation of the new chat */
    createChat(
      userID,
      setSelectedReceiver,
      dispatch,
      navigate,
      receiverID,
      messagesURL
    );
  }
};

export const getLoansUtil = (
  queryString,
  dispatch,
  loadingHandler = () => {}
) => {
  loadingHandler(true);
  dispatch({ type: "RESET_LOANS" });

  dispatch(
    getLoans(
      queryString,
      (loans) => {
        loadingHandler(false);
      },
      false
    )
  );
};

export const getPaginatedLoansUtil = (
  paginationPage,
  totalPages,
  dispatch,
  queryString,
  loadingHandler = () => {}
) => {
  if (paginationPage <= totalPages) {
    loadingHandler(true);
    // let queryString = `page=${paginationPage}&type=loanboard`;
    dispatch(
      getLoans(
        queryString,
        (loans) => {
          loadingHandler(false);
        },
        true
      )
    );
  }
};

export const infiniteScrollUtil = (
  observerRef,
  loans,
  paginationPageSetter
) => {
  /*Stop observing previous target */
  if (observerRef.current?.target) {
    observerRef.current.observer.unobserve(observerRef.current.target);
  }
  /*Set last loan card as target for observing */
  const target = document.getElementById(loans[loans.length - 1]?.id);
  /*Set options */
  let options = {
    root: document.querySelector("main"), // Loan cards wrapper
    threshold: 1.0, //Envoke callback when target will appear on the screen on 100%
  };
  /*Create observer */
  let observer = new IntersectionObserver((event) => {
    if (event[0].isIntersecting) {
      paginationPageSetter((prevState) => prevState + 1);
    }
  }, options);

  if (target && observer) {
    // Start observing
    observer.observe(target);
    observerRef.current = { observer, target };
  }
};

export const getRelevantData = (
  type,
  changeLoanStatus,
  setTerms,
  setOpenLoanAgreement
) => {
  let currentActionBtnTitle = "Confirm";
  let toastMessage = "Request has been sent successfully";
  let currentActionBtnHandler = changeLoanStatus;
  switch (type) {
    case "grant":
      currentActionBtnTitle = "Grant Loan";
      toastMessage =
        "Loan has been granted and sent to the borrower for confirmation";
      currentActionBtnHandler = () => setTerms(true);
      break;
    case "confirm":
      currentActionBtnTitle = "Send request";
      toastMessage = "Loan grant has been confirmed";
      currentActionBtnHandler = () => {
        setOpenLoanAgreement(true);
      };
      break;
    case "deny-grant":
      currentActionBtnTitle = "Send request";
      toastMessage =
        "Loan grant has been denied successfully. Other lenders are now welcome to contact you";
      break;
    case "repaid":
      currentActionBtnTitle = "Repaid";
      toastMessage = "Loan has been repaid. Please leave a review!";
      break;
    case "unpaid":
      currentActionBtnTitle = "Not Repaid";
      toastMessage =
        "Loan has been marked as not repaid. Please leave a review!";
      break;
    case "extend-date":
      toastMessage = "Request has been sent to the borrower for confirmation";
      break;
    case "confirm-extend-date":
      toastMessage = "Confirmation has been sent successfully";
      break;
    case "cancel-extend-date" || "confirm-extend-date":
      currentActionBtnTitle = "Send Request";
      break;

    default:
      break;
  }

  return { currentActionBtnTitle, toastMessage, currentActionBtnHandler };
};

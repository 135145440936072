export const navItems = [
  "p2p lending",
  "faq",
  "about",
  "privacy policy",
  "terms & conditions",
];

export const p2pExplanationItems = [
  `Peer-to-peer (P2P) lending allows people to lend or borrow money from one another
   without going through a bank.`,
  "You request a loan, and the loan will be funded within 24 hours or you will be refunded.",
  "If you are a lender, you can earn $200-300 monthly from pre-vetted borrowers.",
];

export const faq = [
  {
    title: "What is getloanr.com?",
    paragraphs: [
      `Getloanr is a peer-to-peer lending platform where users can lend to one another. Borrowers provide the lenders with the their updated paychecks. Lenders will fund you within 24 hours. That is our guarantee!  `,
    ],
  },
  {
    title: "How do I make a loan request?",
    paragraphs: [
      `Making a loan request is easy. Type in your loan request,
followed by the repayment amount and repayment date.`,
    ],
  },
  {
    title: "Are collaterals necessary when asking for a loan?",
    paragraphs: [
      `No, they are not required. We only need your two recent W-2 paystubs.`,
    ],
  },
  {
    title: "How are loans processed?",
    paragraphs: [
      <p>
        Loans are processed outside of getloanr.com through Venmo and PayPal.
        All lenders are <b>REQUIRED</b> to mark it through Goods and Services.
        Getloanr does <b>NOT</b> handle any transactions.
      </p>,
    ],
  },
];

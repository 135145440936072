import { Alert, IconButton, Typography } from "@mui/material";
import { ReportProblemOutlined as ReportProblemOutlinedIcon } from "@mui/icons-material";

export const DefaultRemindingMessage = () => {
  return (
    <>
      <Alert severity="error" sx={{ mt: "3px" }}>
        Do NOT send any user asking for money who has not posted a loan in the
        LoanBoard. Please use the report button above (
        {
          <IconButton sx={{ padding: 0 }}>
            <ReportProblemOutlinedIcon />
          </IconButton>
        }
        ) to report spam or misuse.
      </Alert>
      <Alert severity="error" sx={{ mt: "3px" }}>
        Do not send your ID to any user!
      </Alert>
    </>
  );
};

export const SystemMessageBadge = () => {
  return (
    <Typography
      sx={{
        width: "fit-content",
        fontSize: "14px !important",
        padding: "3px 7px",
        marginLeft: "auto",
        borderRadius: "8px",
        backgroundColor: "rgb(255, 244, 229)",
        color: "rgb(102, 60, 0) !important",
      }}
    >
      Getloanr
    </Typography>
  );
};

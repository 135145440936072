import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const LoanStatuses = ({ open, modalClose }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={modalClose}
    >
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Typography>
            <strong>SAVED</strong> - loan request has been added to the
            Dashbaord. Publishing fee should be paid for posting on the
            Loanboard.
          </Typography>
          <Typography>
            <strong>PRECONFIRMED</strong> - loan request was granted by the
            Lender and is waiting confirmation by Borrower.
          </Typography>
          <Typography>
            <strong>GRANTED</strong> - The Lender granted the loan request and
            the Borrower confirmed it.
          </Typography>
          <Typography>
            <strong>PAID</strong> - loan was repiad by the Borrower.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={modalClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export const LocationTable = ({ locationType, data, allCount }) => {
  return (
    <TableContainer component={Paper} sx={{ height: 300, zIndex: 1 }}>
      <Table stickyHeader aria-label="locations table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
              {locationType}
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              align="right"
            >
              Count
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => {
            return (
              <TableRow
                key={Math.random()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {locationType === "cities" ? row._id.city : row._id}
                  {locationType === "cities" && ` (${row._id.state})`}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            );
          })}
          <TableRow sx={{ marginTop: "10px" }}>
            <TableCell sx={{ fontWeight: "bold" }}>Total Count</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              {allCount}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import React from "react";
import { Dialog, DialogContent, DialogTitle, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AddAnouncementModal({
  children,
  isModalOpen,
  handleCloseModal,
}) {
  return (
    <Dialog
      open={isModalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseModal}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        margin: "0 auto",
        "& > .MuiDialog-container .MuiPaper-root": {
          width: "400px",
        },
      }}
    >
      <DialogTitle sx={{ pb: 0, color: "rgba(0,0,0,.7)" }}>
        Add new announcement
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

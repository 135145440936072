import React from "react";

import {
  Avatar,
  Conversation,
  ConversationList,
} from "@chatscope/chat-ui-kit-react";

import moment from "moment";

export const Conversations = ({ chats, user, handleSelectChat }) => {
  return (
    <ConversationList>
      {chats &&
        chats.map((chat, index) => {
          // If chat doesn't have messages return null
          if (!chat.messages.length) return null;

          const isAdminChat = !!chat?.users.some(
            (user) => user.role === "chat-bot"
          );

          const respondent = chat.users.filter((chatUser) => {
            if (user.role === "admin") {
              /*Prevent selecting chat bot as a respondent user */
              return chatUser.role !== "chat-bot" && chatUser.id !== user.id;
            } else {
              return chatUser.id !== user.id;
            }
          })[0];

          let chatLastMessage;
          const lastIndex = chat.messages.length - 1;

          if (chat.messages[lastIndex]?.message?.system) {
            chatLastMessage = "<system message>";
          } else {
            chatLastMessage =
              chat.messages.length !== 0
                ? typeof chat.messages[lastIndex].message === "string"
                  ? chat.messages[lastIndex].message
                  : chat.messages[lastIndex].message.text
                  ? chat.messages[lastIndex].message.text
                  : "<image>"
                : "";
          }

          const truncatedLastMessage =
            chatLastMessage.length > 25
              ? chatLastMessage.substring(0, 25) + "..."
              : chatLastMessage;
          if (respondent) {
            return (
              <Conversation
                key={chat.id}
                name={`@${respondent?.username}`}
                lastSenderName={
                  chatLastMessage.includes("system")
                    ? null
                    : chat.messages.length !== 0
                    ? chat.messages[lastIndex].sender?.username
                    : null
                }
                info={truncatedLastMessage}
                lastActivityTime={moment(chat.updatedAt).format("MM/DD/YY")}
                onClick={() => handleSelectChat(chat)}
                style={{
                  background:
                    user.role !== "admin" && isAdminChat
                      ? "rgb(76, 175, 80, 0.7)"
                      : "",
                }}
              >
                {
                  <Avatar
                    style={{
                      backgroundColor: "rgb(110, 169, 215)",
                      textAlign: "center",
                      fontSize: "25px",
                    }}
                  >
                    {respondent?.username[0]}
                  </Avatar>
                }
              </Conversation>
            );
          } else return null;
        })}
    </ConversationList>
  );
};

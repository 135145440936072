import { TextField, styled } from "@mui/material";

export const LoanRequestFormInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(255,255,255,0.6)",
    height: "2.8rem",
    borderRadius: "10px",
    marginTop: "7px",
  },
});

import React, { memo } from "react";

import {
  Paper,
  Grid,
  useMediaQuery,
  Modal,
  Typography,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { LoanCardButton } from "../../../Components";
import { StripePayments } from "../../../Components/Payments/Stripe/StripePyaments";

export const SubscriptionModal = memo(
  ({ open, closeModalHandler, closePaymentModal, subscriptionData }) => {
    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);
    const isSubscriptionPaused = subscriptionData?.isSubscriptionPaused;

    let description =
      subscriptionData?.type === "grant"
        ? `For granting loan requests you have to pay for monthly subscription - USD ${subscriptionData?.price}.`
        : `To reduce spam, you must sign up for a monthly subscription -
        USD ${subscriptionData?.price}, which will allow you to
        grant loans, and message users.
     `;

    return (
      <Modal sx={{ zIndex: 1300 }} open={open}>
        <>
          <Paper
            className="fade-in-slow"
            sx={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              padding: "1em 1.5em",
              borderRadius: "8px",
              maxHeight: "100%",
              maxWidth: {
                xs: "90%",
                sm: "75%",
                md: "450px",
              },
              overflowY: "auto",
            }}
            elevation={isSmallMediaQuery ? 0 : 6}
          >
            <Typography
              fontWeight={700}
              style={{ marginBottom: "1em" }}
              fontSize={"20px"}
            >
              Monthly Subscription
            </Typography>
            {!isSubscriptionPaused ? (
              <Grid sx={{ overflowY: "auto" }}>
                <StripePayments
                  {...subscriptionData}
                  handleCancelBtnClick={closePaymentModal}
                  description={description}
                />
              </Grid>
            ) : (
              <Alert severity="warning">{subscriptionData.message}</Alert>
            )}

            <Grid
              item
              sx={{
                height: "fit-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <LoanCardButton
                title="Cancel"
                btnProps={{
                  color: "secondary",
                  variant: "contained",
                }}
                cb={closeModalHandler}
                styles={{
                  mr: 1,
                  mt: 2,
                  fontSize: "16px",
                }}
              />
            </Grid>
          </Paper>
        </>
      </Modal>
    );
  }
);

const initilaState = {
  chats: null,
  selectedChat: null,
  loading: false,
};

export const chatsReducer = (state = initilaState, action) => {
  const { payload } = action;
  let chatIndex = state?.chats?.findIndex((chat) => {
    return chat.id === payload?.chatID;
  });
  /*Chat list without chat that was changed */
  const chatsExcludedTargeted =
    state.chats?.filter((chat) => chat.id !== payload?.chatID) || [];

  switch (action.type) {
    case "SET_CHATS_LIST":
      return {
        ...state,
        chats: payload.chats,
        loading: false,
      };
    case "UPDATE_CHATS_LIST_MESSAGE":
      if (chatIndex > -1) {
        chatsExcludedTargeted.unshift({
          ...state.chats[chatIndex],
          messages: [...state.chats[chatIndex].messages, payload.newMessage],
        });
      } else {
        chatsExcludedTargeted.unshift({
          id: payload.chatID,
          users: payload.users,
          adminID: payload.adminID,
          messages: [payload.newMessage],
        });
      }

      return {
        ...state,
        chats: chatsExcludedTargeted,
        loading: false,
      };

    case "SET_SELECTED_CHAT":
      let chatList = [...state.chats];
      if (payload.newOne) chatList.push(payload.singleChat);
      return {
        ...state,
        chats: chatList,
        selectedChat: payload.singleChat || state.chats[chatIndex],
        loading: false,
      };

    case "UPDATE_SELECTED_CHAT":
      let updatedSelectedChat;
      /*initial - get messages after chat was selected  */
      if (payload.type === "initial") {
        updatedSelectedChat = {
          ...state.selectedChat,
          messages: [...payload.messages, ...state.selectedChat.messages],
          fetched: true,
        };
      } else if (payload.type === "existed") {
        const messageIndex = state.selectedChat.messages.findIndex(
          (message) => message._id === payload.newMessage._id
        );
        const messages = [...state.selectedChat.messages];
        messages[messageIndex] = payload.newMessage;

        updatedSelectedChat = {
          ...state.selectedChat,
          messages,
        };
      } else {
        updatedSelectedChat = {
          ...state.selectedChat,
          messages: [...state.selectedChat.messages, payload.newMessage],
        };
      }

      return {
        ...state,
        selectedChat: updatedSelectedChat,
        chats: [updatedSelectedChat, ...chatsExcludedTargeted],
        loading: false,
      };

    case "CLOSE_SELECTED_CHAT":
      return {
        ...state,
        selectedChat: null,
        loading: false,
      };

    case "CLEAN_CHATS":
      return initilaState;

    case "CHATS_LOAD":
      return {
        ...state,
        loading: true,
      };
    case "CHATS_UNLOAD":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

import React, { useCallback, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  Slide,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { CustomAccessForm } from "./CustomAccessForm";
import { grantUserAccessAPI } from "../../api/admin";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialPredefinedState = {
  user: false,
  admin: false,
};

export function GrantAccessModal({
  isModalOpen,
  handleModalClose,
  user,
  toast,
  getAllUsers,
}) {
  const [formIds, setFormIds] = useState([]);
  const [predefinedAccess, setPredefinedAccess] = useState(
    initialPredefinedState
  );
  const [userAccess, setUserAccess] = useState({});

  const handleChangePredefinedAccess = ({ target }) => {
    setPredefinedAccess((prevState) => ({
      ...initialPredefinedState,
      [target.id]: target.checked,
    }));
  };

  const handleSetCustomAccess = (formId, selectedSection, e) => {
    let updatedUserAcces = { ...userAccess };
    const { target } = e;
    if ("checked" in target) {
      if (selectedSection) {
        if (target.checked) {
          updatedUserAcces[formId][selectedSection] = target.id;
        } else {
          updatedUserAcces[formId][selectedSection] = "";
        }
      }
    } else if (target.value !== 1) {
      updatedUserAcces[formId] = {
        ...userAccess[formId],
        [target.value]: "",
      };
    }
    setUserAccess(updatedUserAcces);
  };

  const handleDeleteCustomAccessForm = useCallback(
    (formId) => {
      const filteredFormsId = formIds.filter((id) => id !== formId);
      setFormIds(filteredFormsId);
      delete userAccess[formId];
    },
    [formIds, userAccess]
  );

  const handleCloseAccessModal = () => {
    setFormIds([]);
    setUserAccess({});
    setPredefinedAccess(initialPredefinedState);
    handleModalClose();
  };

  const handleGrantUserAccess = async () => {
    let accessObj = {};
    for (const [key, value] of Object.entries(predefinedAccess)) {
      if (value && user.role === key) {
        toast("User already has the same role");
      } else {
        if (value) accessObj.role = key;
      }
    }
    for (const [_, value] of Object.entries(userAccess)) {
      if (value) {
        accessObj = {
          ...accessObj,
          ...value,
        };
      }
    }
    if (!Object.keys(accessObj).length) return;

    try {
      const { data } = await grantUserAccessAPI(user.id, accessObj);

      if (data.id) {
        handleCloseAccessModal();
        toast("User access privileges has been updated successfully");
        getAllUsers();
      } else {
        toast.error("Something went wrong");
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseAccessModal}
      aria-describedby="Grant user access"
      sx={{
        "& > .MuiDialog-container > .MuiPaper-root": {
          width: {
            xs: "100vw",
            md: "60vw",
          },
        },
      }}
    >
      <DialogTitle sx={{ color: "rgba(0,0,0,.7)" }}>
        Grant User Privileges
      </DialogTitle>

      {user && (
        <DialogContent sx={{ padding: "0 24px" }}>
          <Grid key={user.id} sx={{ position: "relative" }}>
            <Grid container>
              <Typography>
                <strong>@{user.username} </strong> role -
              </Typography>
              <Typography>{user.role.toUpperCase()}</Typography>
            </Grid>
            {!formIds.length && (
              <FormGroup
                sx={{ display: "flex", flexDirection: "row" }}
                onChange={handleChangePredefinedAccess}
              >
                <FormControlLabel
                  control={<Checkbox id="admin" />}
                  label="ADMIN"
                  checked={predefinedAccess.admin}
                />
                <FormControlLabel
                  control={<Checkbox id="user" />}
                  label="USER"
                  checked={predefinedAccess.user}
                />
              </FormGroup>
            )}
            <Grid>
              {formIds.length > 0 &&
                formIds.map((formId) => {
                  const selectedSection =
                    userAccess[formId] && Object.keys(userAccess[formId])[0];
                  return (
                    <CustomAccessForm
                      key={formId}
                      id={formId}
                      selectedSection={selectedSection}
                      setSelect={(e) =>
                        handleSetCustomAccess(formId, selectedSection, e)
                      }
                      deleteForm={() => handleDeleteCustomAccessForm(formId)}
                      checked={userAccess?.[formId]?.[selectedSection]}
                    />
                  );
                })}
            </Grid>
            <Grid container sx={{ justifyContent: "center" }}>
              <Fab
                sx={{ margin: "10px auto" }}
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => {
                  setFormIds((prevState) => [...prevState, Math.random()]);
                  setPredefinedAccess(initialPredefinedState);
                }}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleGrantUserAccess}>Grant</Button>
        <Button onClick={handleCloseAccessModal} color="warning">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

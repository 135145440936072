import { combineReducers } from "redux";

import auth from "./auth";
import loans from "./loan";
import loader from "./loader";
import { appSettingsReducer } from "./appSettings";
import { chatsReducer } from "./chats";
import { notificationReducer } from "./notifications";
import { announcementsReducer } from "./announcements";

export default combineReducers({
  auth,
  loans,
  loader,
  appSettings: appSettingsReducer,
  chats: chatsReducer,
  notifications: notificationReducer,
  announcements: announcementsReducer,
});

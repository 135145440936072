import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  useMediaQuery,
  Button,
  Typography,
  Menu,
  MenuItem,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation } from "react-router-dom";

import { deleteUsers, getUsers, suspendUsers } from "../../actions/admin";
import Delete from "../../Components/DeleteModal";
import Table from "./Table";
import Suspend from "../../Components/SuspendModal";
import { GrantAccessModal } from "./GrantAccessModal";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
const CssButton = styled(Button)({
  backgroundColor: "#4267b2",
  color: "white",
  borderRadius: "5px",
  height: "2.5em",
});
const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#D9D9D9",
    margin: "5px 2px",
    color: "black",
    height: "2.5em",
    borderRadius: "10px",
  },
});

function createData(
  id,
  name,
  username,
  email,
  contact,
  active,
  createdAt,
  role
) {
  return {
    id,
    name,
    username,
    email,
    contact,
    active,
    createdAt,
    role,
  };
}

const Users = () => {
  const small = useMediaQuery("(max-width:756px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [suspend, setSuspend] = useState(false);
  const [remove, setRemove] = useState(false);
  const [filter, setFilter] = useState([]);
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [grantAccessUser, setGrantAccessUser] = useState(null);

  const open = Boolean(anchorEl);

  const { user } = useSelector((state) => state.auth);

  const location = useLocation();

  const currentRoute =
    user.role !== "admin" &&
    user.currentRoutes.filter((route) => {
      if (location.pathname[location.pathname.length - 1] === "/") return route;
      return route.menuItem.to.replace(/\/+$/, "") === location.pathname;
    });
  const isCurrentPathReadOnly =
    user.role !== "admin" &&
    currentRoute[0]?.access === "ro" &&
    user?.role.includes("manager");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteAccounts = async () => {
    await deleteUsers(selected, (data) => {
      setRows(
        data.map((obj) => {
          return createData(
            obj.id,
            obj.name,
            obj.username,
            obj.email,
            obj.contact,
            obj.active,
            obj.createdAt
          );
        })
      );
      setSelected([]);
    });
    return;
  };

  const suspendAccounts = async () => {
    await suspendUsers(selected, (data) => {
      setRows(
        data.map((obj) => {
          return createData(
            obj.id,
            obj.name,
            obj.username,
            obj.email,
            obj.contact,
            obj.active,
            obj.createdAt
          );
        })
      );
      setSelected([]);
    });
    return;
  };

  const handleOpenGrantAccessModal = () => {
    if (selected.length > 1 || !selected.length)
      return toast("You have to select one user for one access change request");
    const users = filter.find((user) => user.id === selected[0]);
    setGrantAccessUser(users);
  };

  const getAllUsers = useCallback(async () => {
    const data = await getUsers("");

    setRows(
      data.filter((obj) => {
        if (obj.id !== user._id) {
          return createData(
            obj.id,
            obj.name,
            obj.username,
            obj.email,
            obj.contact,
            obj.active,
            obj.createdAt,
            obj.role
          );
        }
        return false;
      })
    );
  }, [user.id]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  useEffect(() => {
    let removed_id = rows.filter(
      (item) => !item.name.toLowerCase().includes(search.toLowerCase())
    );
    removed_id = removed_id.map((obj) => obj.id);
    setFilter(
      rows.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    );
    setSelected((state) => [
      ...state.filter((item) => !removed_id.includes(item)),
    ]);
  }, [setFilter, setSelected, search, rows]);

  return (
    <>
      <GrantAccessModal
        isModalOpen={!!grantAccessUser}
        handleModalClose={() => setGrantAccessUser(null)}
        user={grantAccessUser}
        toast={toast}
        getAllUsers={getAllUsers}
      />

      <Delete
        open={remove}
        setOpen={setRemove}
        deleteAccounts={deleteAccounts}
      />
      <Suspend open={suspend} setOpen={setSuspend} suspend={suspendAccounts} />
      <Grid
        item
        xs={12}
        style={{ padding: small ? "0 1rem" : "0 1rem 0 1rem" }}
        container
      >
        <Grid item container xs={12} justifyContent={"space-between"}>
          <Grid item xs={6}>
            <Typography fontSize={"35px"} fontWeight={400}>
              Users
            </Typography>
          </Grid>
          {!isCurrentPathReadOnly && (
            <Grid
              item
              xs={6}
              container
              alignItems="center"
              justifyContent="flex-end"
            >
              <CssButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="contained"
                style={{
                  color: "white",
                  fontSize: "1em",
                  textTransform: "none",
                  borderRadius: "5px",
                }}
              >
                <p
                  style={{
                    margin: "0",
                  }}
                >
                  Actions{" "}
                </p>
                <ArrowDropDownIcon />
              </CssButton>{" "}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {user.role === "admin" && (
                  <MenuItem
                    onClick={() => {
                      handleOpenGrantAccessModal();
                      handleClose();
                    }}
                  >
                    Grant Access
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setSuspend(true);
                    handleClose();
                  }}
                >
                  Suspend Account
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setRemove(true);
                    handleClose();
                  }}
                >
                  Delete Account
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1em" }} container>
          <Paper style={{ width: "100%" }}>
            <Grid item xs={8} md={4}>
              <CssTextField
                fullWidth
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Table
              rows={filter}
              setSelected={setSelected}
              selected={selected}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Users;

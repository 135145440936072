import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AccordionCell({ amounts }) {
  return (
    <Accordion sx={{ width: "fit-content" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {amounts?.total || 0}
      </AccordionSummary>
      <AccordionDetails>
        {amounts?.list?.map((l, i, arr) => (
          <Typography key={l + i}>
            {l + (arr.length - 1 !== i ? "," : "")}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionCell;

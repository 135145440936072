import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Modal,
  Paper,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Requested from "./Requested";
import Granted from "./Granted";
import {
  getLoansUtil,
  getPaginatedLoansUtil,
  handleOnMessageClick,
  infiniteScrollUtil,
  normalizeCollateral,
} from "../../utils";
import { useNavigate } from "react-router";
import { updateLoan } from "../../actions/loan";
import { toast } from "react-toastify";

const Loans = () => {
  const [loansType, setLoansType] = useState("requested");
  const [paginationPage, setPaginationPage] = useState(1);
  const [selectedLoanID, setSelectedLoanID] = useState(null);

  const {
    auth: { user },
    loans: { loans, totalPages },
    chats: { chats },
  } = useSelector((state) => state);

  const small = useMediaQuery("(max-width:756px)");

  const observerRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getLoansFilter = () =>
    JSON.stringify({
      granted_by: { $exists: loansType === "granted" },
    });

  const handleGetLoans = useCallback(
    (paginationPage) => {
      const grantedFilter = getLoansFilter();

      let queryString = `page=1&type=admin&f_granted=${grantedFilter}`;
      getLoansUtil(queryString, dispatch);
    },
    [dispatch, loansType]
  );

  const handleGetPaginatedLoans = useCallback(
    (paginationPage) => {
      const grantedFilter = getLoansFilter();
      let queryString = `page=${paginationPage}&type=admin&f_granted=${grantedFilter}`;

      getPaginatedLoansUtil(paginationPage, totalPages, dispatch, queryString);
    },
    [dispatch, totalPages]
  );

  /*Infinite Scroll Handler */
  const handleInfiniteScroll = useCallback(() => {
    infiniteScrollUtil(observerRef, loans, setPaginationPage);
  }, [loans]);

  const toggleLoanRequests = () => {
    dispatch({ type: "RESET_LOANS" });
    setLoansType((prevState) =>
      prevState === "requested" ? "granted" : "requested"
    );
    setPaginationPage(1);
  };

  const handleRefundRequest = () => {
    dispatch(
      updateLoan(selectedLoanID, "refunded", (response) => {
        if (response.data.id && response.data.status === "refunded") {
          setSelectedLoanID(null);
          toast.success("Loan request has been updated");
        }
      })
    );
  };

  useEffect(() => {
    if (paginationPage === 1) {
      handleGetLoans();
    } else {
      handleGetPaginatedLoans(paginationPage);
    }
  }, [paginationPage, loansType]);

  useEffect(() => {
    if (loans && paginationPage <= totalPages) {
      handleInfiniteScroll();
    }
  }, [loans]);

  return (
    <Grid
      xs={12}
      container
      item
      style={{ padding: small ? "0 1em" : "0 3em 0 3em" }}
    >
      <Modal open={!!selectedLoanID}>
        <Paper
          className="fade-in-slow"
          sx={{
            position: "absolute",
            top: small ? "50%" : "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            padding: "1.5em 2em",
            borderRadius: "1em",
            textAlign: "center",
            width: { xs: "90vw", md: "30vw" },
          }}
          elevation={small ? 0 : 6}
        >
          <Grid
            sx={{ mt: 3 }}
            style={{ textAlign: "center", fontSize: "1.4em", fontWeight: 500 }}
            item
            xs={12}
          >
            Do you want to continue refund?
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", gap: "8px", margin: "0 auto" }}
          >
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => setSelectedLoanID(null)}
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "1em",
                borderRadius: "5px",
                textTransform: "none",
              }}
            >
              No
            </Button>
            <Button
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleRefundRequest}
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "1em",
                borderRadius: "5px",
                textTransform: "none",
              }}
            >
              Yes
            </Button>
          </Grid>
        </Paper>
      </Modal>

      <Grid item container xs={12} justifyContent={"space-between"}>
        <Grid item xs={6}>
          <Typography
            fontSize={"35px"}
            fontWeight={400}
            sx={{ textTransform: "capitalize" }}
          >
            Loans {loansType}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            onClick={toggleLoanRequests}
            sx={{
              color: "white",
              fontSize: "1rem",
              textTransform: "capitalize",
              borderRadius: "5px",
            }}
          >
            Loans {loansType === "requested" ? "granted" : "requested"}
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "1em" }} item container>
        {loans?.map((loan, index) => {
          const collateral = normalizeCollateral(loan);
          loan.collateral = collateral;
          return loansType === "requested" ? (
            <Requested
              loan={loan}
              key={loan.id}
              handleOnMessageClick={() => {
                handleOnMessageClick(
                  chats,
                  user.id,
                  null,
                  dispatch,
                  navigate,
                  loan.created_by.id,
                  "/admin/messages"
                );
              }}
              setSelectedLoanID={() => setSelectedLoanID(loan.id)}
            />
          ) : (
            <Granted loan={loan} key={loan.id} />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Loans;

import React, { useState } from "react";

import { getImage } from "../../utils";
import { useEffect } from "react";
import { Loader, Message } from "@chatscope/chat-ui-kit-react";

import Linkify from "linkify-react";

export const MessageContent = ({ message, setImageSrc }) => {
  const [image, setImage] = useState(null);

  const currentMessage =
    typeof message.message === "string"
      ? message.message
      : message.message.text;
  let isImageExist = message.message.image;

  useEffect(() => {
    if (message.message.image) {
      getImage(message.message.image).then((image) => {
        setImage(image);
      });
    }
  }, [message.message.image]);

  return (
    <>
      {currentMessage && (
        <Linkify
          as="p"
          options={{
            target: "_blank",
            truncate: 25,
            attributes: {
              title: "External Link",
            },
          }}
          style={{ margin: 0 }}
        >
          {currentMessage}
        </Linkify>
      )}
      {image ? (
        <Message
          style={{ cursor: "pointer" }}
          onClick={() => setImageSrc(image.src)}
          type="image"
          model={{
            direction: "incoming",
            payload: {
              src: image.src,
              width: "150px",
            },
          }}
        />
      ) : isImageExist ? (
        <Loader />
      ) : null}
    </>
  );
};

import React from "react";

import { Alert, Button, Grid } from "@mui/material";
import {
  VisibilityOutlined as VisibilityIcon,
  VisibilityOffOutlined as VisibilityOffIcon,
} from "@mui/icons-material";

const visibilityIconStyles = {
  p: 0,
  mr: "5px",
  fontSize: "20px !important",
  color: "rgba(0,0,0,.55)",
};

export function Announcement({
  announcement,
  handleUpdateAnnouncement,
  handleDeleteAnnouncement,
}) {
  return (
    <Alert
      severity={announcement.severity.toLowerCase()}
      sx={{ width: "fit-content" }}
    >
      {announcement.enabled ? (
        <VisibilityIcon sx={visibilityIconStyles} />
      ) : (
        <VisibilityOffIcon sx={visibilityIconStyles} />
      )}
      {announcement.description}

      <Grid container sx={{ gap: "8px", mt: 2, justifyContent: "center" }}>
        <Button
          variant="outlined"
          color={announcement.enabled ? "warning" : "primary"}
          size="small"
          onClick={() =>
            handleUpdateAnnouncement(announcement._id, {
              enabled: !announcement.enabled,
            })
          }
        >
          {announcement.enabled ? "Disable" : "Enable"}
        </Button>
        <Button
          variant="outlined"
          color="warning"
          size="small"
          onClick={handleDeleteAnnouncement}
        >
          Delete
        </Button>
      </Grid>
    </Alert>
  );
}

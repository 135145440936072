import React, { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItem,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ZipcodesTable = ({ data }) => {
  return (
    <Box sx={{ width: "100%", mt: "10px" }}>
      <Typography sx={{ fontSize: "22px", mb: "10px" }}>
        Users zipcodes
      </Typography>
      <Box
        sx={{
          width: "70%",
          height: "350px",
          overflowY: "auto",
          margin: "0 auto",
        }}
      >
        {data.map((item) => {
          return (
            <Accordion
              key={item._id + item.citiesLength}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {item._id + ` (total cities - ${item.citiesLength})`}{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {item.data.map((city) => {
                  return (
                    <Accordion
                      key={city.city + city.zipcodes.length}
                      TransitionProps={{ unmountOnExit: true }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {city.city +
                            ` (total areas -${city.zipcodes.length})`}{" "}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {city.zipcodes.map((zipcode) => {
                          return (
                            <ListItem>
                              <Typography>
                                {zipcode.zip + ` (${zipcode.total} users)`}{" "}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

import { toast } from "react-toastify";
import { getAnnouncementsAPI } from "../api/admin";

export const getAnnouncementsAction = () => async (dispatch) => {
  try {
    const { data } = await getAnnouncementsAPI();
    const { announcements } = data;

    if (announcements) {
      dispatch({
        type: "SET_ANNOUNCEMENTS",
        payload: announcements,
      });
    }
  } catch (error) {
    toast.error("Something went wrong. Try again");
  }
};

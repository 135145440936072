import React, { memo } from "react";

import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";

import { styles } from "./muiStyles";

const questions = [
  {
    title: "Are you currently employed?",
    id: Math.random().toFixed(7),
    inputName: "employed",
  },
  {
    title:
      "Do you have two recent W-2 paystubs? Only W-2 paystubs are allowed. Please note that loan requests have fees associated with them. Your fee will be returned if you do not get funded within a day!",
    id: Math.random().toFixed(7),
    inputName: "paystubs",
  },
  {
    title:
      "Do you have a PayPal where lenders can deposit money to your account?",
    id: Math.random().toFixed(7),
    inputName: "deposit",
  },
];

export const PreSignupForm = memo(({ isUserBorrower, handlePreformChange }) => {
  return (
    <>
      <FormLabel sx={styles.inputTitle}>
        Are you applying to be a lender?
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group-label"
        onChange={handlePreformChange}
      >
        <FormControlLabel
          value="yes"
          control={<Radio name="isUserLender" />}
          label="Yes"
        />
        <FormControlLabel
          value="no"
          control={<Radio name="isUserLender" />}
          label="No"
        />
      </RadioGroup>
      {isUserBorrower ? (
        questions.map((q) => {
          return (
            <Grid
              key={q.id}
              sx={{ display: isUserBorrower ? "block" : "none" }}
            >
              <FormLabel sx={styles.inputTitle}>{q.title}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                onChange={handlePreformChange}
                defaultChecked={false}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio name={q.inputName} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio name={q.inputName} />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          );
        })
      ) : (
        <Grid></Grid>
      )}
    </>
  );
});

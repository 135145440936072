import React, { useState } from "react";

import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { CollateralView } from "./CollateralView";

export const Collateral = ({ collateral }) => {
  const [isCollateralModalOpen, setIsCollateralModalOpen] = useState(false);
  return (
    <>
      <Button
        variant="text"
        sx={{
          p: 0,
          m: 0,
          width: "fit-content",
          minWidth: "unset",
          margin: { xs: 0, md: "0 auto" },
        }}
        onClick={
          collateral?.airbnb_url
            ? () => window.open(collateral.airbnb_url, "_blank")
            : () => setIsCollateralModalOpen(true)
        }
      >
        <Typography
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            mb: "2px",
          }}
        >
          {collateral.caption}
        </Typography>
      </Button>
      <Dialog
        open={isCollateralModalOpen}
        onClose={() => setIsCollateralModalOpen(false)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          "& .MuiDialog-container .MuiPaper-root": {
            width: { xs: "100vw", md: "90vw" },
            m: {
              xs: 1,
              md: 4,
            },
            borderRadius: "8px",
          },
        }}
      >
        <DialogContent>
          <CollateralView
            collateral={collateral}
            closeCollateral={() => setIsCollateralModalOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

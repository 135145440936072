import { TipOne, TipTwo, TipThree, TipFour, TipFive } from "./BorrowerTips";
import { LenderTipOne, LenderTipThree } from "./LenderTips";
import { GeneralTipOne, GeneralTipTwo } from "./GeneralTips";

export const tips = {
  borrower: [
    { title: "To Publish a Loan Request", component: <TipOne /> },
    { title: "Form input fields", component: <TipTwo /> },
    { title: "To Delete Loan Requests", component: <TipThree /> },
    { title: "Confirmation", component: <TipFour /> },
    {
      title: "The payback amount",
      component: <TipFive />,
    },
  ],
  lender: [
    { title: "To Browse Loan Requests", component: <LenderTipOne /> },
    { title: "To Grant Loans", component: <LenderTipThree /> },
  ],
  general: [
    { title: "UI Tip", component: <GeneralTipOne /> },
    {
      title: "Get User Active Loan Requests",
      component: <GeneralTipTwo />,
    },
  ],
};

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  createAnnouncementAction,
  deleteAnnouncementAction,
  updateAnnouncementAction,
} from "../../actions/admin";

import { toast } from "react-toastify";

import { Button, Fab, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AddForm } from "./AddForm";

import { Announcement } from "./Announcement";
import { AddAnouncementModal } from "./AddAnouncementModal";
import { getAnnouncementsAction } from "../../actions/announcements";

const initialAnnouncementState = {
  description: "",
  severity: "Success",
  enabled: true,
};

export function AnnouncementsPage() {
  const { announcements } = useSelector((state) => state.announcements);

  const [announcement, setAnnouncement] = useState(initialAnnouncementState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleAnnoucementChange = ({ target: { name, value } }) => {
    setAnnouncement((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleAddAnnouncement = () => {
    if (!announcement.description.trim()) {
      toast.error("Description is not allowed to be empty");
      return;
    }
    dispatch(createAnnouncementAction(announcement));
    setAnnouncement(initialAnnouncementState);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAnnouncement(initialAnnouncementState);
  };

  const handleUpdateAnnouncement = (announcementID, newData) => {
    dispatch(updateAnnouncementAction(announcementID, newData));
  };
  const handleDeleteAnnouncement = (announcementID) => {
    dispatch(deleteAnnouncementAction(announcementID));
  };

  useEffect(() => {
    dispatch(getAnnouncementsAction());
  }, []);

  useEffect(() => {
    return handleCloseModal;
  }, []);

  return (
    <Grid
      xs={12}
      sx={{ padding: { xs: "0 1rem", md: "0 1rem 0 1rem" } }}
      item
      container
    >
      <AddAnouncementModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      >
        <AddForm
          announcement={announcement}
          handleAnnoucementChange={handleAnnoucementChange}
        >
          <Grid container sx={{ justifyContent: "center", gap: "5px", mt: 2 }}>
            <Button
              variant="outlined"
              onClick={handleAddAnnouncement}
              sx={{ width: "fit-content" }}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="warning"
              onClick={handleCloseModal}
              sx={{ width: "fit-content" }}
            >
              Close
            </Button>
          </Grid>
        </AddForm>
      </AddAnouncementModal>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item xs={6}>
          <Typography fontSize={"35px"} fontWeight={400}>
            Announcements
          </Typography>
        </Grid>

        <Fab
          color="primary"
          aria-label="add"
          onClick={() => setIsModalOpen(true)}
          sx={{ position: "fixed", bottom: "15px", right: "15px" }}
        >
          <AddIcon />
        </Fab>
      </Grid>
      <Grid
        container
        sx={{
          flexDirection: "column",
          gap: "8px",
          "& > .MuiPaper-root .MuiAlert-action": {
            display: "flex",
            padding: 0,
          },
        }}
      >
        {!announcements?.length ? (
          <Typography>No announcements to display</Typography>
        ) : (
          announcements.map((announcement) => {
            return (
              <Announcement
                key={announcement._id}
                announcement={announcement}
                handleUpdateAnnouncement={handleUpdateAnnouncement}
                handleDeleteAnnouncement={() =>
                  handleDeleteAnnouncement(announcement._id)
                }
              />
            );
          })
        )}
      </Grid>
    </Grid>
  );
}

import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";

export const MobileDrawer = ({
  mobileOpen,
  handleDrawerToggle,
  navItems,
  handleMenuItemClick,
}) => {
  const drawerWidth = "100vw";

  return (
    <Box component="nav">
      <Drawer
        anchor="bottom"
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: {
            sm: "block",
            md: "none",
          },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderTopRightRadius: "25px",
            borderTopLeftRadius: "25px",
          },
        }}
      >
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
          <Divider />
          <List>
            {navItems.map((item) => (
              <ListItem key={item} disablePadding>
                <ListItemButton
                  sx={{ textAlign: "center" }}
                  onClick={() => handleMenuItemClick(item)}
                >
                  <ListItemText
                    primary={item}
                    sx={{
                      "&>span": {
                        fontSize: "1rem",
                        textTransform: "uppercase",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            {/* <Divider /> */}
            <ListItem key={"Sign up"} disablePadding>
              <Button
                component={Link}
                variant="outlined"
                to="sign-up"
                sx={{
                  margin: "0 auto",
                }}
              >
                Sign up
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

import {
  Grid,
  useMediaQuery,
  Paper,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getDashboard, getDashboardCounts } from "../../actions/admin";
import Counts from "./Counts";
import { useDispatch, useSelector } from "react-redux";
import { getLoans } from "../../actions/loan";
import Charts from "./Charts";
import Cards from "./Cards";
import { normalizeCollateral } from "../../utils";
import { LocationsChart } from "./LocationsChart/LocationsChart";
import { ClipLoader } from "react-spinners";
import { ZipcodesTable } from "./LocationsChart/ZipcodesTable";
import { getUsersZipcodesDataAPI } from "../../api/admin";
import { toast } from "react-toastify";
import { PickDateInput } from "../../Components";
import { GrantedLoansTable } from "./components/GrantedLoansTable";

const Home = () => {
  const dispatch = useDispatch();
  const [counts, setCounts] = useState(null);
  const [zipcodesData, setZipcodesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requestType, setRequestType] = useState("weekly");
  const [grantedType, setGrantedType] = useState("weekly");
  const [statsDate, setStatsDate] = useState({ from: "", to: "" });
  const [grantedStats, setGrantedStats] = useState(null);

  const [granted, setGranted] = useState([]);
  const [requests, setRequests] = useState([]);
  const { loans } = useSelector((state) => state.loans);

  const handleClickZipcodesBtn = async () => {
    if (!zipcodesData) {
      setLoading(true);
      try {
        const { data } = await getUsersZipcodesDataAPI();
        if (data) {
          setZipcodesData(data);
        }
      } catch (e) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    } else {
      setZipcodesData(null);
    }
  };

  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    setStatsDate((prevState) => ({ ...prevState, [id]: value }));
  }, []);

  const getGrantedLoansStats = async () => {
    dispatch({ type: "LOAD" });
    const data = await getDashboard(
      `type=grant-paid&range=${statsDate.from}to${statsDate.to}`
    );
    setGrantedStats(data);
    dispatch({ type: "UNLOAD" });
  };

  useEffect(() => {
    dispatch(getLoans("type=admin"));
    const getCounts = async () => {
      const loans = await getDashboard(`type=requested&range=${requestType}`);
      const data = await getDashboardCounts();
      setRequests(loans);
      const granted = await getDashboard(`type=granted&range=${grantedType}`);
      setGranted(granted);
      setCounts(data);
    };
    getCounts();
  }, [setCounts, setRequests, requestType, grantedType, setGranted, dispatch]);

  const small = useMediaQuery("(max-width:756px)");

  return (
    <Grid
      item
      xs={12}
      style={{ padding: small ? "0 1rem" : "1rem " }}
      container
    >
      {!counts && (
        <ClipLoader
          color="#4267b2"
          size={110}
          css={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%)",
            zIndex: 999,
          }}
        />
      )}
      {counts && (
        <Grid container>
          <Counts counts={counts} />

          <Button
            onClick={handleClickZipcodesBtn}
            sx={{ width: "200px", mt: "20px", display: "block" }}
            variant="outlined"
          >
            {loading ? (
              <ClipLoader
                color="#4267b2"
                size={22}
                css={{
                  padding: 0,
                }}
              />
            ) : zipcodesData ? (
              "Hide"
            ) : (
              "Get Zipcodes data"
            )}
          </Button>
          {zipcodesData && <ZipcodesTable data={zipcodesData} />}
          <LocationsChart
            locationData={{
              states: counts.data.states,
              cities: counts.data.cities,
              all: counts.data.allWithLocation[0]?.count,
            }}
          />
          {/* Granted Loans Stats */}
          <Paper
            style={{
              width: "100%",
              padding: "1rem",
              backgroundColor: "white",
              overflow: "hidden",
            }}
          >
            <Grid>
              <Typography fontSize={22}>Granted Loans Stats</Typography>
              <Grid sx={{ display: "flex", gap: "5px" }}>
                <PickDateInput
                  dateState={statsDate.from}
                  handleChangeState={handleInputChange}
                  isPastDateDisabled={false}
                  isFullWidth={false}
                  inputId="from"
                  maxDate={new Date().toISOString().split("T")[0]}
                />
                <PickDateInput
                  dateState={statsDate.to}
                  handleChangeState={handleInputChange}
                  isFullWidth={false}
                  inputId="to"
                  disabled={!statsDate.from}
                  isPastDateDisabled={!!statsDate.from}
                  pastDate={statsDate.from}
                  maxDate={new Date().toISOString().split("T")[0]}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", gap: "5px", mt: 1 }}>
              <Button variant="outlined" onClick={getGrantedLoansStats}>
                Get
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setGrantedStats(null);
                }}
              >
                Clear
              </Button>
            </Grid>
            {!grantedStats && (
              <Typography sx={{ mt: 2 }}>
                Select the period to display
              </Typography>
            )}
          </Paper>
          {grantedStats && <GrantedLoansTable rows={grantedStats} />}

          <Grid
            style={{ marginTop: "1em" }}
            item
            xs={12}
            container
            spacing={3}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} md={7} spacing={2} container>
              <Grid item xs={12}>
                <Paper style={{ padding: "1em" }}>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6}>
                      <Typography>Loan Requests</Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                      <TextField
                        variant="outlined"
                        style={{ width: "60%" }}
                        select
                        value={requestType}
                        onChange={(e) => setRequestType(e.target.value)}
                      >
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="thisweek">7 days</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                  <Charts points={requests} type={"request"} />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper style={{ padding: "1em" }}>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6}>
                      <Typography>Loans Granted</Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                      <TextField
                        variant="outlined"
                        style={{ width: "60%" }}
                        select
                        value={grantedType}
                        onChange={(e) => setGrantedType(e.target.value)}
                      >
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="thisweek">7 days</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>{" "}
                  <Charts points={granted} type={"granted"} />
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Paper
                style={{
                  height: "100%",
                  width: "100%",
                  padding: "1em",
                  backgroundColor: "white",
                }}
              >
                <Grid
                  item
                  spacing={1}
                  style={{ overflowY: "auto" }}
                  container
                  xs={12}
                >
                  <Grid item xs={12}>
                    <Typography fontSize={22}>Recent Activity</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    {loans.length &&
                      loans?.map((loan) => {
                        const collateral = normalizeCollateral(loan);
                        loan.collateral = collateral;
                        return <Cards loan={loan} key={loan.id} />;
                      })}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Home;

import {
  createloan,
  deleteloan,
  getloanbyId,
  getloans,
  updateloan,
} from "../api/loan";
import { toast } from "react-toastify";

export const getLoans = (query, cb, isPaginated) => async (dispatch) => {
  try {
    const { data } = await getloans(query);
    const { loans, appSettings, announcements } = data;

    if (loans) {
      dispatch({ type: "GET LOANS", payload: { loans, isPaginated } });
    }
    if (appSettings) {
      dispatch({ type: "SET_APP_SETTINGS", appSettings });
    }
    if (announcements) {
      dispatch({
        type: "SET_ANNOUNCEMENTS",
        payload: announcements,
      });
    }
    if (typeof cb === "function") {
      cb(loans);
    }
    dispatch({ type: "UNLOAD" });
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    return error;
  }
};

export const createLoans = (formdata, cb) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const { data } = await createloan(formdata);
    dispatch({ type: "UNLOAD" });
    if (typeof cb === "function") {
      cb(data);
    }
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    return error;
  }
};

export const getLoanById = (id, cb) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const { data } = await getloanbyId(id);
    if (typeof cb === "function") {
      cb(data);
    }
    dispatch({ type: "SINGLE LOAN", data: data });
    dispatch({ type: "UNLOAD" });
  } catch (error) {
    dispatch({ type: "UNLOAD" });

    //console.log(error);
  }
};

export const updateLoan = (loanId, type, cb, loanData) => async (dispatch) => {
  try {
    dispatch({ type: "LOAD" });
    const response = await updateloan(loanId, type, loanData);
    if (typeof cb === "function" && response.status === 200) {
      cb(response);
    }
    dispatch({ type: "UNLOAD" });
  } catch (error) {
    dispatch({ type: "UNLOAD" });
    toast.error(error.response?.data?.message || "Something went wrong");
    if (typeof cb === "function") {
      cb(error);
    }
  }
};
export const updateLoanLocallyAction =
  (loanID, loanData) => async (dispatch) => {
    dispatch({ type: "UPDATE_LOAN_LOCALLY", payload: { loanID, loanData } });
    dispatch({ type: "UNLOAD" });
  };

export const deleteLoan = (id, query) => async (dispatch) => {
  try {
    dispatch({ type: "LOAN" });
    await deleteloan(id);
    dispatch(getLoans(query));
    toast.success("Loan Deleted");

    dispatch({ type: "UNLOAD" });
  } catch (error) {
    const errMessage = error.response.data.message;
    if (errMessage === "The loan has been granted") {
      toast.error(errMessage);
    }
    dispatch({ type: "UNLOAD" });
  }
};

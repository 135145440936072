import React, { useCallback, useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Typography,
  Button,
  IconButton,
  Modal,
  Paper,
  Box,
} from "@mui/material";
import { AutorenewOutlined as AutorenewOutlinedIcon } from "@mui/icons-material";

import { LoanCard } from "../../../Components";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

import "./index.css";
import {
  getLoansUtil,
  getPaginatedLoansUtil,
  infiniteScrollUtil,
} from "../../../utils";
import { AnnouncementsTable } from "./AnnouncementsTable";
import { SubscriptionModal } from "../components/SubscriptionModa";
import { createChatAction } from "../../../actions/chats";

const LoanBoard = ({ socket, deleteLoanBtnClick }) => {
  /*Redux slices */
  const {
    loans: { loans, totalPages },
    auth: { user },
    chats: { chats, selectedChat },
    announcements: { announcements },
  } = useSelector((state) => state);

  /*Component States */
  const [paginationPage, setPaginationPage] = useState(1);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isLoansLoading, setIsLoansLoading] = useState(false);
  const [borrowerForChat, setBorrowerForChat] = useState(null);
  const [borrowerForPaystubs, setBorrowerForPaystubs] = useState(null);
  const [isAnnouncemetsTableOpen, setIsAnnouncemetsTableOpen] = useState(true);
  const [paystubsRequestData, setPaystubsRequestData] = useState(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const observerRef = useRef(null);

  const targetedLoanCard = location.state?.target;

  /*Handlers */

  /*Get Loans Handlers */
  const handleGetLoans = useCallback(
    (paginationPage) => {
      let queryString = `page=1&type=loanboard`;
      getLoansUtil(queryString, dispatch, setIsLoansLoading);
    },
    [dispatch, setIsLoansLoading]
  );

  const handleGetPaginatedLoans = useCallback(
    (paginationPage) => {
      const queryString = `page=${paginationPage}&type=loanboard`;
      getPaginatedLoansUtil(
        paginationPage,
        totalPages,
        dispatch,
        queryString,
        setIsPaginationLoading
      );
    },
    [dispatch, totalPages]
  );

  const onMessageClick = useCallback(
    (receiverID) => {
      dispatch({ type: "LOAD" });
      const chatID = chats?.find((chat) =>
        chat.users.find((user) => user.id === receiverID)
      )?.id;
      if (chatID) {
        dispatch({ type: "SET_SELECTED_CHAT", payload: { chatID } });
        dispatch({ type: "UNLOAD" });
        return navigate("/dashboard/messages");
      } else {
        setBorrowerForChat(receiverID);
      }
    },
    [borrowerForChat, chats, user.id]
  );

  /*Grant loan Button Click */
  const onGrantLoanClick = async (loan) => {
    try {
      dispatch({ type: "LOAD" });
      navigate(`/dashboard/loan-board/${loan.id}/grant`, {
        state: { loan },
      });
    } catch (e) {
      toast.error("Something went wrong");
    } finally {
      dispatch({ type: "UNLOAD" });
    }
  };

  /*Infinite Scroll Handler */
  const handleInfiniteScroll = useCallback(() => {
    infiniteScrollUtil(observerRef, loans, setPaginationPage);
  }, [loans]);

  const handleLoanCardHighlighting = () => {
    /*Get loan card */
    const loanCard = document.getElementById(targetedLoanCard);
    if (!loanCard && paginationPage <= totalPages) {
      /*Fetch new paginated portion of loan cards */
      setPaginationPage((prevState) => prevState + 1);
    } else {
      /*If loan card was fetched - scroll in to viewport */
      loanCard?.scrollIntoView({ behavior: "smooth", block: "start" });
      /*Add css class for loan card highlighting */
      loanCard.classList.add("activeLoanCard");
      location.state.target = null;
    }
  };

  const onRequestPaystubsClick = useCallback((borrower) => {
    setBorrowerForPaystubs(borrower);
  }, []);

  const submitRequestPaystubs = useCallback(() => {
    dispatch({ type: "LOAD" });

    socket.emit(
      "paystubs",
      {
        borrower: borrowerForPaystubs,
        lender: user,
        status: "request",
      },
      handleSocketPaystubsResponse
    );
  }, [socket, borrowerForPaystubs]);

  const handleSocketPaystubsResponse = (response) => {
    if (response.status === "invalid") {
      setShowSubscriptionModal(response);
    } else {
      setShowSubscriptionModal(null);
      setBorrowerForPaystubs(null);
      setPaystubsRequestData(null);
      toast(
        `@${response.borrower.username} has been notified of the paystub request.`
      );
    }

    dispatch({ type: "UNLOAD" });
  };

  const createChat = useCallback(() => {
    dispatch(
      createChatAction(`${user.id},${borrowerForChat}`, (response) => {
        if (response.singleChat) {
          /*If user have valid monthly subscription response will include new chat object */
          dispatch({
            type: "SET_SELECTED_CHAT",
            payload: { singleChat: response.singleChat, newOne: true },
          });

          navigate("/dashboard/messages");
        }
        if (response.status === "invalid") {
          /*If monthly subscription is invalid - initiate openning notification modal with payment subscribe button*/
          setShowSubscriptionModal(response);
        }
        dispatch({ type: "UNLOAD" });
      })
    );
  }, [borrowerForChat, user.id]);

  /*useEffects */
  useEffect(() => {
    handleGetLoans();
  }, []);

  useEffect(() => {
    if (paginationPage !== 1) {
      handleGetPaginatedLoans(paginationPage);
    }
  }, [paginationPage]);

  useEffect(() => {
    if (targetedLoanCard && totalPages) {
      handleLoanCardHighlighting();
    }
  }, [targetedLoanCard, loans]);

  useEffect(() => {
    if (loans && paginationPage <= totalPages) {
      handleInfiniteScroll();
    }
  }, [loans]);

  useEffect(() => {
    if (borrowerForChat && !selectedChat) {
      createChat();
    }
    if (borrowerForPaystubs && paystubsRequestData?.borrower) {
      console.log("here");
      submitRequestPaystubs(paystubsRequestData);
    }
    socket?.removeAllListeners("subscription-paid");
    socket?.on("subscription-paid", ({ message }) => {
      if (message === "OK") {
        toast("Payment was successful.");
        if (borrowerForChat) {
          createChat();
        } else if (borrowerForPaystubs) {
          submitRequestPaystubs();
        }
      }
    });
  }, [
    borrowerForChat,
    borrowerForPaystubs,
    selectedChat,
    createChat,
    paystubsRequestData,
    submitRequestPaystubs,
  ]);

  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_LOANS" });
      dispatch({ type: "RESET_ANNOUNCEMENTS" });
    };
  }, [dispatch]);

  return (
    <Grid item container xs={12}>
      <Modal
        open={!!borrowerForPaystubs}
        onClose={() => setPaystubsRequestData(null)}
      >
        <Paper
          className="fade-in-slow"
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            padding: "1em 1.5em",
            borderRadius: "8px",
            maxHeight: "100%",
            width: "fit-content",
            maxWidth: {
              xs: "90%",
              sm: "75%",
              md: "450px",
            },
            overflowY: "auto",
          }}
        >
          <Box>
            <Typography>
              Confirm paystubs request for @{borrowerForPaystubs?.username}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={() => setBorrowerForPaystubs(null)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setPaystubsRequestData({
                    status: "request",
                    lender: user,
                    borrower: borrowerForPaystubs,
                  });
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
      <SubscriptionModal
        open={!!showSubscriptionModal}
        closeModalHandler={() => {
          setBorrowerForChat(null);
          setBorrowerForPaystubs(null);
          setShowSubscriptionModal(null);
        }}
        closePaymentModal={() => setShowSubscriptionModal(false)}
        subscriptionData={showSubscriptionModal}
      />
      {isLoansLoading && (
        <Grid
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: 1301,
          }}
        >
          <ClipLoader color="#4267b2" size={110} />
          <p style={{ color: "#4267b2" }}>Please Wait...</p>
        </Grid>
      )}
      {announcements.length > 0 && (
        <Grid sx={{ backgroundColor: "inherit" }}>
          <Button
            variant="outlined"
            sx={{ fontSize: "16px" }}
            onClick={() => setIsAnnouncemetsTableOpen(!isAnnouncemetsTableOpen)}
          >
            {isAnnouncemetsTableOpen
              ? "Hide"
              : `Announcements (${announcements.length})`}
          </Button>
          <AnnouncementsTable
            announcements={announcements}
            isAnnouncemetsTableOpen={isAnnouncemetsTableOpen}
          />
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: {
            xs: "column-reverse",
            md: "row",
          },
        }}
      >
        <Grid container item md={3} sx={{ mr: "auto" }}>
          <Typography
            sx={{
              fontSize: "calc(1em + 1vmin)",
              width: "fit-content",
              mb: {
                xs: 1,
                md: 0,
              },
            }}
            fontWeight={400}
          >
            Loan Board
            <IconButton
              onClick={() => {
                setPaginationPage(1);
                handleGetLoans(1);
              }}
            >
              <AutorenewOutlinedIcon sx={{ color: "rgba(25, 118, 210)" }} />
            </IconButton>
          </Typography>
        </Grid>
        <Grid
          container
          item
          sx={{
            width: {
              xs: "100%",
              md: "fit-content",
            },
            mt: {
              xs: 2,
              md: 3,
            },
            mb: { xs: 0, md: 2 },
            justifyContent: {
              xs: "space-between",
              md: "flex-end",
            },
          }}
        >
          <Button
            onClick={() => navigate("/dashboard/loan-board/request")}
            variant="contained"
            sx={{
              fontSize: "1rem",
              textTransform: "none",
              width: "fit-content",
              marginLeft: "auto",
            }}
          >
            Request a Loan
          </Button>
        </Grid>
      </Grid>

      <Grid container item>
        {loans.length > 0 ? (
          loans.map((loan) => {
            return (
              <LoanCard
                key={loan.id}
                loan={loan}
                userId={user.id}
                deleteLoanBtnClick={deleteLoanBtnClick}
                grantLoanHandler={onGrantLoanClick}
                handleOnMessageClick={onMessageClick}
                handleRequestPaystubs={onRequestPaystubsClick}
                backLocation="dashboard/loan-board"
                target={loan.id}
                styles={{ scrollMarginTop: "70px" }}
              />
            );
          })
        ) : (
          <Typography fontSize={"20px"} fontWeight={400}>
            No Loan Requests
          </Typography>
        )}
        {isPaginationLoading && (
          <Grid
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,.3)",
              width: "100%",
              "& span": { marginLeft: "calc(50% - 30px)" },
            }}
            id="getloanr-paginate-spinner"
          >
            <ClipLoader color="#4267b2" size={30} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LoanBoard;

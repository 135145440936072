import url from "./url";
import axios from "axios";
const API = axios.create({ baseURL: `${url}/loans` });
API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = localStorage.getItem("token");
  }
  return req;
});

export const getloans = (options) => API.get(`?${options}`);
export const createloan = (loanData) => API.post(`/`, loanData);
export const updateloan = (id, type, body) =>
  API.put(`/${id}?type=${type}`, body);
export const getloanbyId = (id) => API.get(`/${id}`);
export const deleteloan = (id) => API.delete(`/${id}`);
export const getAgreementDataAPI = (id, addressForm) =>
  API.post(`/agreement/${id}`, { addressForm });

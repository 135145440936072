import { Button } from "@mui/material";

export const LoanCardButton = ({ title, cb, btnProps, styles, centered }) => {
  return (
    <Button
      variant="contained"
      onClick={cb}
      size="small"
      color="primary"
      {...btnProps}
      sx={{
        display: centered ? "block" : "inline-block",
        width: "fit-content",
        fontSize: "12px",
        textTransform: "none",
        margin: centered && "0 auto",
        ...styles,
      }}
    >
      {title}
    </Button>
  );
};

import React from "react";
import { useNavigate } from "react-router";

import { Box, Grid, Typography } from "@mui/material";

import { PrivacyPolicy } from "../../Components/PrivacyPolicy";
import { AppNav } from "../../Components/AppNav";
import { ScrollToTop } from "../../Components/ScrollToTop";
const styles = {
  section: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "65px 25px",
  },
  sectionTitle: {
    fontSize: "36px",
    textAlign: "center",
  },
};

export const PrivacyPolicyPage = () => {
  const navigate = useNavigate();

  const handleBackToHomeClick = () => {
    navigate("/");
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
      <div id="back-to-top-anchor" />
      <AppNav
        navItems={["Back to home"]}
        handleDrawerToggle={handleBackToHomeClick}
        handleMenuItemClick={handleBackToHomeClick}
      />
      <Grid
        component="section"
        container
        sx={{
          ...styles.section,
          p: { xs: "70px 25px" },
        }}
      >
        <Grid item>
          <Typography variant="h2" sx={styles.sectionTitle}>
            Privacy Policy
          </Typography>
        </Grid>
        <Grid container mt={2}>
          <PrivacyPolicy />
        </Grid>
      </Grid>
      <ScrollToTop />
    </Box>
  );
};

import React, { memo, useCallback, useState } from "react";

import { Paper, useMediaQuery, Modal } from "@mui/material";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

import { LenderPreAgreement } from "./LenderPreAgreement";
import { LoanAgreement } from "../../../../Components/LoanAgreement/LoanAgreement";
import { useDispatch } from "react-redux";

export const GrantLoanTerms = memo(
  ({
    open,
    closeModalHandler,
    submitCallback,
    addressForm,
    setAddressForm,
    lender,
    loanID,
    signatureValue,
    changeSignatureValue,
    user,
  }) => {
    const [checked, setChecked] = useState(false);
    const [openLoanAgreement, setOpenLoanAgreement] = useState(false);

    const [addressFormErrors, setAddressFormErrors] = useState([]);
    const theme = useTheme();
    const dispatch = useDispatch();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    const preAgreementCallback = useCallback(() => {
      if (!checked) {
        return toast("Please check the checkbox");
      } else {
        let error = false;

        Object.entries(addressForm).forEach(([key, value]) => {
          if (
            (!["apartment", "building"].includes(key) && value.length < 2) ||
            (key === "zip" && value.length < 5)
          ) {
            error = true;
            if (!addressFormErrors.includes(key)) {
              setAddressFormErrors((prevState) => [...prevState, key]);
              return toast.error(`Please check the ${key} field`);
            }
          }
        });

        if (!error) {
          setOpenLoanAgreement(true);
        }
      }
    }, [checked, addressForm, addressFormErrors, dispatch]);

    return (
      <Modal sx={{ zIndex: 1300 }} open={open}>
        <>
          <Paper
            className="fade-in-slow"
            sx={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              p: "8px",
              borderRadius: "8px",
              maxHeight: "100%",
              width: "fit-content",
              maxWidth: {
                xs: "90%",
                sm: "75%",
                md: "450px",
              },
              overflowY: "auto",
            }}
            elevation={isSmallMediaQuery ? 0 : 6}
          >
            {!openLoanAgreement ? (
              <LenderPreAgreement
                addressForm={addressForm}
                setAddressForm={setAddressForm}
                addressFormErrors={addressFormErrors}
                setAddressFormErrors={setAddressFormErrors}
                closeModalHandler={() => {
                  closeModalHandler();
                  setChecked(false);
                  setAddressFormErrors([]);
                  setAddressForm({
                    state: "",
                    city: "",
                    street: "",
                    building: "",
                    apartment: "",
                    zip: "",
                  });
                }}
                actionBtnClick={preAgreementCallback}
                checked={checked}
                setChecked={setChecked}
              />
            ) : (
              <LoanAgreement
                open={openLoanAgreement}
                loanID={loanID}
                closeModalHandler={() => {
                  setOpenLoanAgreement(false);
                  closeModalHandler();
                }}
                dispatch={dispatch}
                lenderData={lender}
                submitCallback={submitCallback}
                signatureValue={signatureValue}
                changeSignatureValue={changeSignatureValue}
                addressForm={addressForm}
                user={user}
              />
            )}
          </Paper>
        </>
      </Modal>
    );
  }
);

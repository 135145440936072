const initilaState = {
  notifications: null,
  loading: false,
};

export const notificationReducer = (state = initilaState, action) => {
  const { payload } = action;

  switch (action.type) {
    case "SET_INITIAL_NOTIFICATIONS":
      return {
        ...state,
        notifications: payload.notifications.results,
        loading: false,
      };
    case "ADD_NOTIFICATIONS":
      return {
        ...state,
        notifications: [...payload.newNotifications, ...state.notifications],
        loading: false,
      };
    case "DELETE_NOTIFICATIONS":
      const updatedNotifications = state.notifications?.filter(
        (notification) => {
          return (
            notification.from.id !== payload.senderID ||
            (notification.from.id === payload.senderID &&
              !payload.type.includes(notification.type))
          );
        }
      );
      return {
        ...state,
        notifications: updatedNotifications,
        loading: false,
      };

    default:
      return state;
  }
};

import React, { memo, useCallback, useState } from "react";

import {
  Paper,
  Grid,
  useMediaQuery,
  TextField,
  Modal,
  Typography,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { LoanCardButton } from "../../../Components";
import { terms } from "../../../constants";
import { getStyles } from "./RequestTermsStyles";
import { toast } from "react-toastify";
import { AddressForm } from "../../../Components/AddressForm";

export const LoanboardRequestModal = memo(
  ({
    open,
    closeModalHandler,
    submitCallback,
    PaymentComponent,
    publishData,
    revise,
    requestedAmount,
    addressForm,
    setAddressForm,
  }) => {
    const [showPaymentComponent, setShowPaymentComponent] = useState(false);
    const [errors, setErrors] = useState([]);
    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    const styles = getStyles(PaymentComponent);

    const handleSubmitCallback = useCallback(
      (status) => {
        let error = false;
        Object.entries(addressForm).forEach(([key, value]) => {
          if (
            (!["apartment", "building"].includes(key) && value.length < 2) ||
            (key === "zip" && value.length < 5)
          ) {
            error = true;
            if (!errors.includes(key)) {
              setErrors((prevState) => [...prevState, key]);
              return toast.error(`Please check the ${key} field`);
            }
          }
        });

        if (!error) {
          submitCallback(status, addressForm);
        }
      },
      [addressForm, errors]
    );

    return (
      <Modal sx={{ zIndex: 1300 }} open={open}>
        <Paper
          className="request-terms-wrapper fade-in-slow"
          sx={styles.wrapper}
          elevation={isSmallMediaQuery ? 0 : 6}
        >
          {!PaymentComponent && !revise && (
            <AddressForm
              addressForm={addressForm}
              setAddressForm={setAddressForm}
              errors={errors}
              setErrors={setErrors}
            />
          )}
          {PaymentComponent && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                overflowY: "auto",
                className: "teest",
              }}
            >
              {
                <PaymentComponent
                  payeerRole="borrower"
                  price={publishData?.price}
                  handleCancelBtnClick={() => {
                    closeModalHandler();
                  }}
                  setShowPaymentComponent={setShowPaymentComponent}
                  requestedAmount={requestedAmount}
                  revise={revise}
                />
              }
            </Grid>
          )}
          <Grid sx={{ mt: 1 }}>
            {publishData?.isPublishAllowed === false && (
              <Alert severity="warning">
                Only one published request is allowed until your outstanding
                loan is repaid. You can save this request to the Dasboard.
              </Alert>
            )}
            {publishData?.isSubscriptionPaused && (
              <Alert severity="warning">{publishData.message}</Alert>
            )}
          </Grid>
          <Grid
            item
            className="buttons-container"
            xs={12}
            spacing={1}
            sx={styles.buttonsContainer}
            container
          >
            <LoanCardButton
              title="Cancel"
              btnProps={{
                color: "secondary",
                variant: "contained",
              }}
              cb={() => {
                closeModalHandler();
              }}
              styles={styles.cancelButton}
            />
            {!showPaymentComponent && (
              <LoanCardButton
                title={"Confirm & Save"}
                styles={styles.saveButton}
                cb={() => handleSubmitCallback("saved")}
              />
            )}
            {!PaymentComponent && !revise && (
              <LoanCardButton
                title={terms.requestLoan.actionTitle}
                btnProps={{
                  variant: "contained",
                  disabled:
                    publishData?.isPublishAllowed === false ||
                    publishData?.isSubscriptionPaused,
                }}
                cb={() => handleSubmitCallback("published")}
                styles={styles.publishButton}
              />
            )}
          </Grid>
        </Paper>
      </Modal>
    );
  }
);

import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { Button, useMediaQuery } from "@mui/material";

export const CustomButtonLink = ({ styles, buttonProps, children, to }) => {
  const theme = useTheme();
  const smallMediaQuery = theme.breakpoints.between(0, 756);
  const isSmallMediaQuery = useMediaQuery(smallMediaQuery);
  return (
    <Button
      size={isSmallMediaQuery ? "" : "large"}
      as={Link}
      to={to}
      sx={{
        borderRadius: "4px",
        color: "black",
        width: "fit-content",
        padding: isSmallMediaQuery ? "0.27rem 1.3rem" : "0.45rem 1.3rem",
        fontSize: "calc(0.5em + 1vmin)",
        textTransform: "none",
        textDecoration: "none",
        boxShadow: "none",

        ...styles,
      }}
      variant="contained"
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export const CustomButton = ({ styles, buttonProps, children }) => {
  const theme = useTheme();
  const smallMediaQuery = theme.breakpoints.between(0, 756);
  const isSmallMediaQuery = useMediaQuery(smallMediaQuery);
  return (
    <Button
      size={isSmallMediaQuery ? "" : "large"}
      sx={{
        borderRadius: "4px",
        color: "black",
        width: "fit-content",
        padding: isSmallMediaQuery ? "0.27rem 1.3rem" : "0.45rem 1.3rem",
        fontSize: "calc(0.4em + 1vmin)",
        textTransform: "none",
        textDecoration: "none",
        ...styles,
      }}
      variant="contained"
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ReportForm = ({
  isReportFormOpen,
  handleCloseReportForm,
  handleSubmitReport,
  reportFormData,
  handleReportFormChange,
  handleSubmitReportForm,
}) => {
  return (
    <Box>
      <Dialog
        open={isReportFormOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseReportForm}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Send Report</DialogTitle>
        <DialogContent>
          <TextField
            id="title"
            label="Title"
            variant="standard"
            fullWidth
            value={reportFormData.title}
            onChange={handleReportFormChange}
          />
          <TextField
            id="message"
            label="Message"
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            sx={{
              mt: 1,
            }}
            value={reportFormData.message}
            onChange={handleReportFormChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReportForm}>Close</Button>
          <Button onClick={handleSubmitReport}>Report an Issue</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

import React from "react";

import { Grid, Paper, Typography, Box } from "@mui/material";

export const TipWrapper = ({ children, title }) => {
  return (
    <Grid
      sx={{
        "& ul li": {
          marginTop: "5px",
        },
      }}
    >
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.default",
        }}
      >
        <Typography
          sx={{ fontSize: "1rem", fontWeight: 600, mt: 1, textAlign: "center" }}
        >
          {title}
        </Typography>
      </Paper>
      <Box
        sx={{ height: 255, maxWidth: 400, width: "100%", overflowY: "auto" }}
      >
        {children}
      </Box>
    </Grid>
  );
};

import axios from "axios";
import url from "./url";

const API = axios.create({ baseURL: `${url}/chats` });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = localStorage.getItem("token");
  }
  return req;
});

export const getAllChatsAPI = (query) => API.get(`/${query}`);
export const getSingleChatAPI = (chatID, fetchAfter) =>
  API.get(`/${chatID}?fetchAfter=${fetchAfter}`);
export const createChatAPI = (usersID) => API.get(`/create/${usersID}`);
export const getChatAsPDFAPI = (chatID) =>
  API.get(`/download/${chatID}`, {
    responseType: "blob",
  });

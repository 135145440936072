import axios from "axios";
import url from "./url";

const API = axios.create({ baseURL: `${url}/reviews` });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = localStorage.getItem("token");
  }
  return req;
});

export const getReviewAPI = (options) => API.get(`?${options}`);
export const setReviewAPI = (id, type, body) =>
  API.post(`/${id}?type=${type}`, body);

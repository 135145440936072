import React from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { LinkToProfile, Collateral } from "../../Components";

const Cards = ({ loan }) => {
  return (
    <Grid
      id={loan.id}
      item
      sx={{
        marginBottom: "1em",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: "0px 1px 6px 0px #7C9AEA",
        padding: "1em",
        borderRadius: "6px",
        width: "850px",
        gap: "10px",
        justifyContent: { xs: "flex-start", md: "space-around" },
      }}
      container
    >
      <Grid item>
        <Typography fontWeight={400} fontSize={"15px"}>
          Payback date: {moment(loan?.date).format("MMM DD,yyyy")}
        </Typography>
      </Grid>

      <Grid
        item
        sx={{ display: "inline-flex", gap: "10px", alignItems: "baseline" }}
      >
        <Typography fontWeight={400} fontSize={"15px"}>
          Collateral:
        </Typography>
        <Collateral collateral={loan.collateral} />
      </Grid>

      <Grid item>
        <Typography fontWeight={400} fontSize={"15px"}>
          Requested Amount: ${loan?.requestedAmount}
        </Typography>
      </Grid>

      <Grid item>
        <Typography fontWeight={400} fontSize={"15px"}>
          Granted By{" "}
          <LinkToProfile
            user={loan?.granted_by}
            admin={true}
            backLocation="admin/loan-board?type=granted"
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Cards;

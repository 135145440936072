import React from "react";

import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import { PhoneInputComponent } from "../../Components/PhoneInput";
import { styles } from "./muiStyles";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(255,255,255,0.6)",
    height: "2.5em",
    borderRadius: "10px",
    marginTop: "8px",
  },
});

function Form({
  formState,
  setFormState,
  theme,
  handleFormChange,
  handleUploadIdCard,
  validate,
  error,
  setError,
}) {
  return (
    <Box component="form" noValidate onSubmit={validate}>
      <p style={{ fontWeight: "500", margin: 0 }}>Full Name</p>
      <CssTextField
        required
        error={error.name}
        onChange={handleFormChange}
        value={formState.name}
        helperText={
          error.name && "Please enter your full name. Min. length - 3 chars"
        }
        fullWidth
        placeholder="John Doe"
        id="name"
        name="name"
        autoComplete="name"
        autoFocus
      />
      <p style={styles.inputTitle}>User name</p>
      <CssTextField
        error={error.username}
        required
        value={formState.username}
        onChange={(e) => e.target.value.length <= 9 && handleFormChange(e)}
        helperText={
          error.username && "Please enter your username. Min. length - 3 chars"
        }
        fullWidth
        placeholder="Username"
        id="username"
        name="username"
        autoComplete="username"
      />
      <p style={styles.inputTitle}>Email address</p>
      <CssTextField
        error={error.email}
        required
        value={formState.email}
        onChange={handleFormChange}
        helperText={error.email && "Please enter your valid email"}
        fullWidth
        placeholder="username@email.com"
        id="email"
        name="email"
        autoComplete="email"
      />

      <p style={styles.inputTitle}>Phone Number</p>
      <PhoneInputComponent
        contact={formState.contact}
        setError={setError}
        setData={setFormState}
        error={error.contact}
        theme={theme}
      />
      <p style={styles.inputTitle}>Password</p>
      <CssTextField
        error={error.password}
        required
        value={formState.password}
        fullWidth
        name="password"
        onChange={handleFormChange}
        placeholder=""
        helperText={error.password && "Please enter a password"}
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <p style={styles.inputTitle}>ID Card</p>
      <CssTextField
        error={error.idCard}
        required
        fullWidth
        name="idCard"
        onChange={handleUploadIdCard}
        placeholder=""
        helperText={error.idCard && "Please upload your ID Card"}
        type="file"
        id="idCard"
        inputProps={{ multiple: false, accept: "image/png, image/jpeg" }}
      />
    </Box>
  );
}

export default Form;

import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Input, Grid, Typography, Button, Switch } from "@mui/material";

import { styled } from "@mui/material/styles";

import { toast } from "react-toastify";

import { logout, updateProfile } from "../../../actions/auth";
import { emailNotificationTypes } from "../../../constants";

import Delete from "../../../Components/DeleteModal";

const EditTextField = styled(Input)({
  "& .MuiInput-input.Mui-disabled": {
    backgroundColor: "#EDEDED",
    color: "black",
    padding: 0,
  },
  "& .MuiInput-input": {
    backgroundColor: "white",
    paddingLeft: "10px",
    height: "2em",
    borderRadius: "10px",
  },
});

const EditButton = styled(Button)({
  backgroundColor: "#E7EFFF",
  color: "black",
  textTransform: "none",
  borderRadius: "5px",
  height: "38px",
  border: "1px solid #DDDDDD",
  boxShadow: " 0px 5px 3px -2px #00000005",
});

const WhiteCssButton = styled(Button)({
  boxShadow: "0px 5px 3px -2px #00000005",
  border: "1px solid #DDDDDD",

  backgroundColor: "white",
  color: "black",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: "white",
    color: "black",
    borderRadius: "5px",
  },
});
const CssButton = styled(Button)({
  backgroundColor: "#4267b2",
  color: "white",
  borderRadius: "5px",
});

const Settings = () => {
  const {
    auth: { user },
    appSettings,
  } = useSelector((state) => state);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [edit, setEdit] = useState({
    username: false,
    email: false,
    contact: false,
  });
  const [update, setUpdate] = useState({
    username: user.username,
    email: user.email,
    contact: user.contact,
    emailNotifications: user.emailNotifications || [],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEdit = (id) => {
    setEdit((state) => ({ ...state, [id]: !state[id] }));
    edit[id] && setUpdate((state) => ({ ...state, [id]: user[id] }));
  };

  const handleChange = (e) => {
    const { id, value, name, checked } = e.target;

    if (id === "emailNotificationTypes") {
      const updatedTypes = new Set(update.emailNotifications);
      checked ? updatedTypes.add(name) : updatedTypes.delete(name);
      setUpdate((prevState) => {
        return {
          ...prevState,
          emailNotifications: [...updatedTypes],
        };
      });
      return;
    }
    setUpdate((state) => ({ ...state, [id]: value }));
  };

  const resetEditState = () =>
    setEdit({
      username: false,
      email: false,
      contact: false,
    });

  const checkIsDataChanged = () => {
    let result = false;

    for (let key in update) {
      if (typeof update[key] === "string") {
        result = update[key] !== user[key];
      } else if (update[key] instanceof Array) {
        result = update[key].length !== user[key]?.length;
      }
      if (result) return result;
    }

    return result;
  };
  const handleSubmit = async () => {
    let error = false;
    if (update.username === "") {
      error = true;
      toast.error("Username cannot be empty");
    }
    if (update.email === "") {
      error = true;
      toast.error("Email cannot be empty");
    }
    if (update.contact === "") {
      error = true;
      toast.error("Phone number cannot be empty");
    }
    const isDataChanged = checkIsDataChanged(update, user);
    if (!error && isDataChanged) {
      const updatedData = {
        ...update,
        email: update.email?.toLowerCase(),
      };
      if (user.contact !== updatedData.contact) {
        updatedData.isPhoneNumberVerified = false;
      }

      dispatch(updateProfile(updatedData, resetEditState));
    }
  };

  const handleDeleteAccount = () => {
    const handleLogout = () => {
      dispatch(logout(navigate));
    };
    dispatch(updateProfile({ active: false }, handleLogout, true));
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      sx={{
        width: "100%",
        marginTop: "1rem",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: "0px 1px 6px 0px #7C9AEA",
        borderRadius: "6px",
        padding: "1rem",
        margin: "1rem auto",
      }}
    >
      <Delete
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        deleteAccounts={handleDeleteAccount}
        deleteByUser={true}
      />
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={"24px"}>
          Settings
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={1} alignItems="center">
        <Grid item>
          <Typography fontSize={"20px"}>{user.username}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{
          backgroundColor: "#EDEDED",
          marginTop: "1em",
          padding: "1em",
          borderRadius: "10px",
        }}
      >
        <Grid item container xs={12} alignItems="center">
          <Grid container spacing={1} item xs={6}>
            <Grid item xs={12}>
              <Typography>USERNAME</Typography>
            </Grid>
            <Grid item xs={12}>
              <EditTextField
                id="username"
                onChange={handleChange}
                disableUnderline={true}
                style={{ padding: 0, border: "none" }}
                fullWidth
                value={!edit.username ? user.username : update.username}
                disabled={!edit.username}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <EditButton
              onClick={() => handleEdit("username")}
              style={{ padding: "12px 16px" }}
            >
              {edit.username ? "Cancel" : "Edit"}
            </EditButton>
          </Grid>
        </Grid>

        <Grid
          item
          container
          style={{ marginTop: "1em" }}
          xs={12}
          alignItems="center"
        >
          <Grid container spacing={1} item xs={6}>
            <Grid item xs={12}>
              <Typography>EMAIL</Typography>
            </Grid>
            <Grid item xs={12}>
              <EditTextField
                id="email"
                onChange={handleChange}
                disableUnderline={true}
                style={{ padding: 0, border: "none" }}
                fullWidth
                value={!edit.email ? user.email : update.email}
                disabled={!edit.email}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <EditButton
              onClick={() => handleEdit("email")}
              style={{ padding: "12px 16px" }}
            >
              {edit.email ? "Cancel" : "Edit"}
            </EditButton>
          </Grid>
        </Grid>

        <Grid
          item
          container
          style={{ marginTop: "1em" }}
          xs={12}
          alignItems="center"
        >
          <Grid container spacing={1} item xs={6}>
            <Grid item xs={12}>
              <Typography sx={{ display: "inline" }}>PHONE NUMBER</Typography>
              {appSettings.enableTwilioVerification && (
                <Typography
                  sx={{
                    ml: 1,
                    color: user.isPhoneNumberVerified ? "green" : "red",
                  }}
                  component="span"
                >
                  {user.isPhoneNumberVerified ? "verified" : "unverified"}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <EditTextField
                id="contact"
                onChange={handleChange}
                disableUnderline={true}
                style={{ padding: 0, border: "none" }}
                fullWidth
                value={!edit.contact ? user.contact : update.contact}
                disabled={!edit.contact}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            container
            sx={{
              justifyContent: { xs: "flex-end" },
            }}
          >
            <EditButton
              onClick={() => handleEdit("contact")}
              sx={{
                padding: "12px 16px",
              }}
            >
              {edit.phone ? "Cancel" : "Edit"}
            </EditButton>
          </Grid>
        </Grid>

        <Grid
          item
          container
          style={{ marginTop: "1em" }}
          xs={12}
          alignItems="center"
        >
          <Grid container spacing={1} item xs={6}>
            <Grid item xs={12}>
              <Typography>PASSWORD</Typography>
            </Grid>
            <Grid item xs={12}>
              <EditTextField
                disableUnderline={true}
                fullWidth
                type="password"
                value={!edit.password ? user.password : update.password}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <EditButton
              component={Link}
              to="/dashboard/settings/change-password"
            >
              Change Password
            </EditButton>
          </Grid>
        </Grid>

        <Grid
          container
          item
          style={{ marginTop: "1em" }}
          xs={12}
          alignItems="center"
        >
          <Grid container spacing={1} item xs={12}>
            <Grid item xs={12}>
              <Typography sx={{ textTransform: "uppercase" }}>
                Email Notifications:
              </Typography>
            </Grid>

            {emailNotificationTypes.map((type) => {
              return (
                <Grid
                  key={type.id}
                  container
                  item
                  xs={12}
                  pl={1}
                  sx={{ alignItems: "center" }}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {type.title}
                  </Typography>
                  <Switch
                    id="emailNotificationTypes"
                    name={type.id}
                    sx={{ marginLeft: "auto" }}
                    onChange={handleChange}
                    checked={update?.emailNotifications.includes(type.id)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={6} container sx={{ margin: "0 auto" }}>
          <Button
            onClick={() => setOpenDeleteModal(true)}
            sx={{ margin: "0 auto" }}
            color="warning"
          >
            Delete Account
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        spacing={1}
        style={{ margin: "0 0 1.5rem 0" }}
        container
        justifyContent={"flex-end"}
        xs={12}
      >
        <Grid item>
          <WhiteCssButton
            component={Link}
            to="/dashboard"
            style={{ textTransform: "capitalize", fontSize: "14px" }}
          >
            Cancel
          </WhiteCssButton>
        </Grid>
        <Grid item>
          <CssButton
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            style={{
              color: "white",
              textTransform: "none",
              borderRadius: "5px",
            }}
          >
            Save Changes
          </CssButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Settings;

import React from "react";

import { List, ListItem, Typography } from "@mui/material";
import { RequestQuoteOutlined as RequestIcon } from "@mui/icons-material";
import { ActionButtonTemplate } from "../ActionButtonTemplate";

const TipOne = () => {
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          1. To get the loan status description, go to the Dashboard and click
          on{" "}
          <ActionButtonTemplate
            title="Loan Statuses"
            variant="text"
            styles={{ fontSize: "0.9rem" }}
          />
        </Typography>
      </ListItem>
    </List>
  );
};
const TipTwo = () => {
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <Typography>
          1. Go to the existing dialog or Start messaging with the User{" "}
        </Typography>
      </ListItem>
      <ListItem sx={{ p: 0 }}>
        <Typography>2. Click on</Typography>{" "}
        {<RequestIcon sx={{ cursor: "pointer" }} />} icon
      </ListItem>
    </List>
  );
};

export { TipOne as GeneralTipOne };
export { TipTwo as GeneralTipTwo };

import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export function CustomAccessForm({
  deleteForm,
  selectedSection = 1,
  setSelect,
  checked,
}) {
  return (
    <Grid
      container
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <FormControl sx={{ mt: 1 }}>
        <InputLabel id="section">Section</InputLabel>
        <Select
          labelId="section"
          value={selectedSection}
          label="Section"
          onChange={setSelect}
        >
          <MenuItem value={1}>Choose Section</MenuItem>
          <MenuItem value={"Users"}>Users</MenuItem>
        </Select>
      </FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox onChange={setSelect} id="ro" checked={checked === "ro"} />
          }
          label="Read Only"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={setSelect}
              id="full"
              checked={checked === "full"}
            />
          }
          label="Full"
        />
      </FormGroup>
      <IconButton onClick={deleteForm}>
        <DeleteOutlineIcon />
      </IconButton>
    </Grid>
  );
}

import React, { useState } from "react";

import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { TipsStepper } from "./TipsStepper";

import { tips } from "./tips";
import { TipWrapper } from "./TipWrapper";

export const TipsModal = ({ isTipsModalOpen, handleCloseTipsModal, user }) => {
  const [tipsCategory, setTipsCategory] = useState(null);
  const [activeTipIndex, setActiveTipIndex] = useState(0);

  const currentCategory = tips?.[tipsCategory];
  const currentTip = currentCategory?.[activeTipIndex];

  const handleCategoryClick = (categoryTitle) => {
    setTipsCategory(categoryTitle);
    setActiveTipIndex(0);
  };

  const handleNext = () => {
    setActiveTipIndex(activeTipIndex + 1);
  };

  const handleBack = () => {
    setActiveTipIndex(activeTipIndex - 1);
  };

  return (
    <Grid>
      <Modal
        open={isTipsModalOpen}
        onClose={handleCloseTipsModal}
        aria-labelledby="Tips Modal"
        sx={{ width: "100%" }}
      >
        <Box
          sx={{
            width: {
              xs: "90%",
              sm: "fit-content",
            },
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
            outline: "none",
            border: "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            p: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            Hi, @{user.username}!
          </Typography>

          <Typography>Please, select category for getting tips:</Typography>
          <Grid sx={{ display: "flex" }}>
            <Button
              variant={tipsCategory === "borrower" ? "outlined" : "text"}
              onClick={() => handleCategoryClick("borrower")}
            >
              Borrower
            </Button>
            <Button
              variant={tipsCategory === "lender" ? "outlined" : "text"}
              onClick={() => handleCategoryClick("lender")}
            >
              Lender
            </Button>
            <Button
              variant={tipsCategory === "general" ? "outlined" : "text"}
              onClick={() => handleCategoryClick("general")}
            >
              General
            </Button>
          </Grid>

          {tipsCategory && (
            <Grid sx={{ width: "100%" }}>
              <TipWrapper title={currentTip.title}>
                {currentTip.component}
              </TipWrapper>
              <TipsStepper
                handleNext={handleNext}
                handleBack={handleBack}
                maxSteps={currentCategory.length}
                activeStep={activeTipIndex}
              />
            </Grid>
          )}
          <Button
            variant="contained"
            sx={{ textTransform: "none", marginLeft: "auto" }}
            onClick={handleCloseTipsModal}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};

import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
  createStripeCheckoutSessionAPI,
  updateStripeSubscriptionAPI,
} from "../../../api/payments";
import { LoanCardButton } from "../../LoanCardButton";
import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { terms } from "../../../constants";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const StripePayments = ({
  payeerRole,
  price,
  handleCancelBtnClick,
  setShowPaymentComponent,
  origin,
  description,
  requestedAmount,
  revise,
}) => {
  const [clientSecret, setClientSecret] = useState("");
  const [showStripeCheckout, setShowStripeCheckout] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  const dispatch = useDispatch();

  const reviseSubscriptionTerms = terms.reviseSubscription(revise);

  const handleClick = () => {
    if (setShowPaymentComponent) {
      setShowPaymentComponent(true);
    }

    setShowStripeCheckout(true);
  };

  const updateSubscription = async () => {
    dispatch({ type: "LOAD" });
    const response = await updateStripeSubscriptionAPI(
      revise.subscriptionID,
      requestedAmount
    );
  };

  const handleComplete = () => {
    handleCancelBtnClick();
    dispatch({ type: "LOAD" });
  };

  useEffect(() => {
    if (!clientSecret && showStripeCheckout) {
      createStripeCheckoutSessionAPI({
        requestedAmount,
        payeerRole,
        type: requestedAmount ? "borrower" : "lender",
      }).then((res) => {
        setClientSecret(res.data.clientSecret);
      });
    }
  }, [clientSecret, showStripeCheckout]);

  return (
    <Grid
      sx={{
        minHeight: showStripeCheckout ? "500px" : "fit-content",
        display: "block",
        width: "100%",
      }}
    >
      {!showStripeCheckout && (
        <Grid sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          {description ? (
            <Typography>{description}</Typography>
          ) : !revise ? (
            <Grid>
              <Typography>
                For publishing loan requests you have to pay for monthly
                subscription - USD ${price}.
              </Typography>
              {origin !== "dashboard" && (
                <Typography>
                  You still can save loan request to the Dashboard.
                </Typography>
              )}
              <Typography fontSize={"14px"} sx={{ mt: 2 }}>
                <strong>IMPORTANT:</strong> If you do not get funded for your
                loan request in 24 hours, you will receive your money back!
              </Typography>
            </Grid>
          ) : (
            <>
              {reviseSubscriptionTerms.title}
              {reviseSubscriptionTerms.text}
            </>
          )}
          <LoanCardButton
            title={revise ? "Upgrade" : "Subscribe"}
            btnProps={{
              color: "primary",
              variant: "contained",
            }}
            cb={!revise ? handleClick : updateSubscription}
            styles={{ fontSize: "16px", margin: "16px auto 0 auto" }}
          />
          <Grid
            id="checkout"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          ></Grid>
        </Grid>
      )}
      {showStripeCheckout && clientSecret && !paymentComplete && (
        <Grid sx={{ width: "100%", height: "100%" }}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret,
              onComplete: () => {
                setPaymentComplete(true);
                handleComplete();
              },
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Grid>
      )}
    </Grid>
  );
};

const sortLoans = (loans, rule) => {
  let result = loans;

  const [parameter, direction] = rule.split("-");
  for (let key of Object.keys(loans)) {
    result[key].sort((a, b) => {
      if (direction === "asc") {
        return new Date(a[parameter]).getTime() >
          new Date(b[parameter]).getTime()
          ? 1
          : -1;
      }
      return new Date(a[parameter]).getTime() > new Date(b[parameter]).getTime()
        ? -1
        : 1;
    });
  }
  return result;
};

const reducer = (
  state = {
    loans: [],
    loan: {},
    granted: [],
    totalPages: 1,
    page: 0,
    total: 0,
  },
  action
) => {
  switch (action.type) {
    case "GET LOANS":
      const {
        payload: { loans, isPaginated },
      } = action;
      let updatedLoans = loans.borrowed;
      /*Paginated loans will be merged with existing loans */
      if (isPaginated) updatedLoans = [...state.loans, ...updatedLoans];

      return {
        ...state,
        loans: updatedLoans,
        granted: loans.lendered || [],
        totalPages: loans.totalPages,
        page: loans.page,
        total: loans.totalResults,
      };
    case "UPDATE_LOAN":
      if (action.data.updateType === "feedback") {
        const currentLoanStateSlice =
          action.data.authorRole === "borrower"
            ? state.loans.requested.paid
            : state.granted.paid;

        const loanIndex = currentLoanStateSlice.findIndex(
          (loan) => loan.id === action.data.loanId
        );
        const updatedLoansSlice = [
          ...currentLoanStateSlice.slice(0, loanIndex),
          {
            ...currentLoanStateSlice[loanIndex],
            reviews: [
              ...currentLoanStateSlice?.[loanIndex]?.reviews,
              action.data.userID,
            ],
          },
          ...currentLoanStateSlice.slice(loanIndex + 1),
        ];

        let updatedLoanState;
        if (action.data.authorRole === "borrower") {
          updatedLoanState = {
            ...state,
            loans: {
              ...state.loans,
              requested: {
                ...state.loans.requested,
                paid: updatedLoansSlice,
              },
            },
          };
        } else {
          updatedLoanState = {
            ...state,
            granted: {
              ...state.granted,
              paid: updatedLoansSlice,
            },
          };
        }

        return updatedLoanState;
      }
      return state;
    case "RESET_LOANS":
      return {
        loans: [],
        loan: {},
        totalPages: 0,
        granted: [],
        page: 0,
        total: 0,
      };

    case "SINGLE LOAN":
      return { ...state, loan: action.data };

    case "SORT_LOANS":
      if (typeof action.rule === "object") {
        return {
          ...state,
          granted: {
            granted: sortLoans(state.granted.granted, action.rule, true),
            paid: state.granted.paid,
          },
        };
      } else {
        return {
          ...state,
          loans: sortLoans(state.loans, action.rule),
          granted: sortLoans(state.granted, action.rule),
        };
      }
    default:
      return { ...state };
  }
};

export default reducer;

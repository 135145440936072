export const appSettingsReducer = (
  state = { enableTwilioVerification: false },
  action
) => {
  switch (action.type) {
    case "SET_APP_SETTINGS":
      state = {
        ...state,
        ...action.appSettings.settings,
      };
      return state;

    default:
      return state;
  }
};

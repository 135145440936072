import React, {
  lazy,
  Suspense,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Grid,
  Box,
  Typography,
  Button,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { MobileDrawer } from "../../Components/Drawer";
import { ScrollToTop } from "../../Components/ScrollToTop";
import { AppNav } from "../../Components/AppNav";
import heroBg from "../../assets/hero-bg2.webp";
import heroBgMob from "../../assets/hero-bg-mobile.webp";
import secondSectionImg from "../../assets/p2p_image.webp";
import gradientTriangle from "../../assets/gradient-triangle.svg";
import gradientCircle from "../../assets/gradient-circle.svg";

import { navItems, p2pExplanationItems, faq } from "./constants";
import { styles as stls } from "./muiStyles";

const VideoComponent = lazy(() => import("../../Components/VideoComponent"));

const ExpandIcon = () => (
  <img
    src={gradientTriangle}
    alt="triangle"
    style={{ transform: "scale(3)" }}
  />
);

function HomePage() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const smallMediaQuery = useMemo(
    () => theme.breakpoints.down("md"),
    [theme.breakpoints]
  );
  const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

  const styles = stls(heroBg, heroBgMob, isSmallMediaQuery, gradientCircle);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleMenuItemClick = useCallback((item) => {
    if (item === "privacy policy") {
      navigate("/privacy-policy");
      return;
    } else if (item === "terms & conditions") {
      navigate("/terms");
      return;
    }
    const anchor =
      document.getElementById(item).getBoundingClientRect().top +
      window.scrollY;
    window.scroll({ top: anchor, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const pageTopAnchor = document.getElementById("back-to-top-anchor");
    pageTopAnchor.scrollIntoView();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
      <div id="back-to-top-anchor" />
      <AppNav
        navItems={navItems}
        handleDrawerToggle={handleDrawerToggle}
        handleMenuItemClick={handleMenuItemClick}
        isSmallMediaQuery={isSmallMediaQuery}
      />

      <MobileDrawer
        handleDrawerToggle={handleDrawerToggle}
        handleMenuItemClick={handleMenuItemClick}
        mobileOpen={mobileOpen}
        navItems={navItems}
      />

      {/* Hero Screen */}
      <Box component="header" id="bg" sx={styles.header}>
        <div style={styles.headerOverlay} />
        <Box component="div" sx={styles.headerBtnContainer}>
          <Button
            onClick={() => handleMenuItemClick("p2p lending")}
            variant="contained"
            sx={{ color: "white", fontSize: "1rem", display: "inline-block" }}
          >
            How Loanr Works
          </Button>
        </Box>
        <Box component="div" sx={styles.headerContentContainer}>
          <Typography component="h1" sx={styles.headerTitle}>
            What is Getloanr?
          </Typography>
          <Typography
            component="p"
            variant="body1"
            sx={{ ...styles.headerDescription, mt: 6 }}
          >
            Getloanr's marketplace connects users with lenders who fund small loans ranging from $20 to $1,000, within 24 hours!
          </Typography>
          <Typography
            component="p"
            variant="body1"
            sx={{ ...styles.headerDescription, mt: 1 }}
          >
            All loans are guaranteed to be funded within 24 hours or you will be
            refunded!
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Typography sx={styles.headerExplanation}>
            <span style={{ color: "red" }}>*</span> You must have proof of
            employment, a work email to verify your employment, and a PayPal!
          </Typography>
        </Box>
      </Box>
      <Box
        component="main"
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {/* What's p2p Screen */}
        <div id="p2p lending" />
        <Grid
          container
          component="section"
          justifyContent="space-between"
          sx={{ ...styles.section, backgroundColor: "#f5f5f5f8" }}
        >
          <Grid item>
            <Typography variant="h2" sx={styles.sectionTitle}>
              What Is Peer-to-Peer (P2P) Lending?
            </Typography>
          </Grid>
          <Grid
            container
            alignItems={isSmallMediaQuery ? "baseline" : "flex-start"}
            justifyContent="center"
          >
            <Grid item lg={6} md={6} sm={12}>
              <Box component="ul" sx={styles.p2pList}>
                {p2pExplanationItems.map((item) => {
                  return (
                    <Box component="li" key={item} sx={styles.p2pListItem}>
                      <Typography variant="p" sx={styles.p2pListItemContent}>
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Button
                component={Link}
                to="/sign-up"
                variant="contained"
                sx={styles.p2pBtn}
              >
                Get Started
              </Button>
            </Grid>
            <Grid lg={6} md={6} sm={12} item sx={styles.p2pImageContainer}>
              <img src={secondSectionImg} alt="pic" style={styles.p2pImage} />
            </Grid>
          </Grid>
        </Grid>
        {/* FAQ */}
        <div id="faq" />
        <Grid
          component="section"
          container
          sx={{
            ...styles.section,
            background:
              "linear-gradient(90deg, rgba(47,120,177,0.8575805322128851) 34%, rgba(35,136,210,0.6867121848739496) 79%)",
          }}
        >
          <Grid item>
            <Typography variant="h2" sx={styles.sectionTitle} color="white">
              FAQ
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              gap: "3px",
              justifyContent: { xs: "center" },
            }}
            mt={3}
          >
            {faq.map((item) => {
              return (
                <Accordion
                  key={item.title}
                  sx={{
                    width: {
                      xs: "90%",
                      md: "70%",
                    },
                  }}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="body2" fontSize={20}>
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.paragraphs.map((p) => (
                      <Typography
                        variant="body2"
                        key={item.title + "p"}
                        fontSize={20}
                      >
                        {p}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        </Grid>
        {/* About */}
        <div id="about" />
        <Grid
          component="section"
          container
          sx={{ ...styles.section, backgroundColor: "#f2f2f2" }}
        >
          <Grid item>
            <Typography variant="h2" sx={styles.sectionTitle}>
              About
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" mt={3} mb={1} fontSize="20px">
              Getloanr.com started out of the idea that people should have the
              power to lend to others without the intervention of banking
              institutions.
            </Typography>
            <Divider />
            <Divider />
            <Typography fontSize="20px" variant="body2" mb={1} mt={1}>
              In addition, Getloanr is committed to transforming borrowers into
              lenders, as we believe that this is the ultimate form of financial
              autonomy- the power to go from borrower to lender!
            </Typography>
          </Grid>
        </Grid>
        <div id="privacy policy" />
      </Box>
      <ScrollToTop />
    </Box>
  );
}

export default HomePage;

import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AccordionCell from "./AccordionCell";

const baseSubcolums = ["Qty", "Requested,$", "Payback,$", "To Granted, %"];

const baseColumns = [
  { id: 1, name: "Period (mm-yy)", rowSpan: 2 },
  { id: 2, name: "Granted", subColumns: ["Qty"] },
  { id: 3, name: "Paid", subColumns: [...baseSubcolums, "Avg revenue,$"] },
  { id: 4, name: "Unpaid", subColumns: baseSubcolums },
  { id: 5, name: "Past Due", subColumns: baseSubcolums },
];

const getBlackBorder = (w) => `${w}px solid black`;

export const GrantedLoansTable = ({ rows }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "450px", padding: "1rem" }}
    >
      <Table sx={{ borderBottom: getBlackBorder(2) }}>
        <TableHead>
          <TableRow
            sx={{
              borderTop: getBlackBorder(2),
              borderBottom: getBlackBorder(2),
              "&>th": {
                padding: "5px",
              },
            }}
          >
            {/* Header First Row */}
            {baseColumns.map((column) => (
              <TableCell
                key={column.id}
                align="center"
                rowSpan={column.rowSpan || 1}
                colSpan={column.subColumns?.length || 1}
                sx={{
                  border: getBlackBorder(1),
                  borderBottom: getBlackBorder(2),
                  fontWeight: "bold",
                }}
              >
                {column.name}
              </TableCell>
            ))}
          </TableRow>
          {/* Header Second Row */}
          <TableRow
            sx={{
              "& > th": {
                border: getBlackBorder(1),
                borderBottom: getBlackBorder(2),
                padding: "5px",
              },
            }}
          >
            {baseColumns.map((column) => {
              if (column.subColumns) {
                return column.subColumns.map((subColumnName, i) => (
                  <TableCell key={column.id + "-" + i} align="center">
                    {subColumnName}
                  </TableCell>
                ));
              }
              return null;
            })}
          </TableRow>
        </TableHead>
        {/* Table Body */}
        <TableBody>
          {Object.keys(rows)
            .sort((a, b) => (a > b ? 1 : -1))
            .map((key) => {
              const pastDueAmounts = rows[key]?.granted?.pastDue?.amounts;
              const unpaidAmounts = rows[key]?.unpaid?.amounts;
              return (
                <TableRow
                  key={key}
                  sx={{
                    "& > td": {
                      border: getBlackBorder(1),
                      textAlign: "center",
                      padding: "5px",
                    },
                  }}
                >
                  <TableCell>{key}</TableCell>
                  <TableCell>{rows[key]?.totalGranted || 0}</TableCell>
                  <TableCell>{rows[key]?.paid?.count || 0}</TableCell>
                  <TableCell>
                    {rows[key]?.paid?.amounts.totalRequest || 0}
                  </TableCell>
                  <TableCell>
                    {rows[key]?.paid?.amounts.totalPayback || 0}
                  </TableCell>
                  <TableCell>{rows[key]?.paid?.paidToGranted || 0}</TableCell>
                  <TableCell>{rows[key]?.paid?.avgRevenue || 0}</TableCell>
                  <TableCell>{rows[key]?.unpaid?.count || 0}</TableCell>
                  <TableCell>
                    {unpaidAmounts?.requestList.length > 1 ? (
                      <AccordionCell
                        amounts={{
                          total: unpaidAmounts?.totalRequest,
                          list: unpaidAmounts?.requestList,
                        }}
                      />
                    ) : (
                      unpaidAmounts?.requestList[0] || 0
                    )}
                  </TableCell>
                  <TableCell>
                    {unpaidAmounts?.paybackList.length > 1 ? (
                      <AccordionCell
                        amounts={{
                          total: unpaidAmounts?.totalPayback,
                          list: unpaidAmounts?.paybackList,
                        }}
                      />
                    ) : (
                      unpaidAmounts?.paybackList[0] || 0
                    )}
                  </TableCell>
                  <TableCell>
                    {rows[key]?.unpaid?.unpaidToGranted || 0}
                  </TableCell>
                  <TableCell>
                    {rows[key]?.granted?.pastDue.count || 0}
                  </TableCell>
                  <TableCell>
                    {pastDueAmounts?.requestList.length > 1 ? (
                      <AccordionCell
                        amounts={{
                          total: pastDueAmounts?.totalRequest,
                          list: pastDueAmounts?.requestList,
                        }}
                      />
                    ) : (
                      pastDueAmounts?.requestList[0] || 0
                    )}
                  </TableCell>
                  <TableCell>
                    {pastDueAmounts?.paybackList.length > 1 ? (
                      <AccordionCell
                        amounts={{
                          total: pastDueAmounts?.totalPayback,
                          list: pastDueAmounts?.paybackList,
                        }}
                      />
                    ) : (
                      pastDueAmounts?.paybackList[0] || 0
                    )}
                  </TableCell>
                  <TableCell>
                    {rows[key]?.granted?.grantedToPasToDue || 0}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

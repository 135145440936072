import url from "./url";
import axios from "axios";

const API = axios.create({ baseURL: `${url}/services` });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = localStorage.getItem("token");
  }
  return req;
});

export const setAPNToken = (body) => API.post("/apn-token", body);
export const sendReport = (body) => API.post("/reports", body);

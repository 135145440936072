import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../actions/auth";

import { useTheme } from "@mui/material/styles";

import { Button, Grid, Paper, Typography } from "@mui/material";

import { PreSignupForm } from "./PreSignupForm";
import Form from "./Form";
import Success from "./Success";

import { formValidator } from "../../utils";

import { toast } from "react-toastify";

import { styles } from "./muiStyles";
import "./styles.css";

const SignUpForm = () => {
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);
  const [formState, setFormState] = useState({
    name: "",
    username: "",
    email: "",
    contact: {
      number: "",
      dialCode: "",
    },
    password: "",
    idCard: null,
  });
  const [preformState, setPreformState] = useState({
    isUserBorrower: false,
    inputsFilled: [],
    validInputs: [],
  });
  const [error, setError] = useState({
    name: false,
    username: false,
    email: false,
    contact: false,
    password: false,
    idCard: false,
  });

  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNewbieUserNavigate = () => {
    navigate(user?.role === "admin" ? "/admin" : "/dashboard");
  };

  const handlePreformChange = useCallback(
    (e) => {
      if (e.target.name === "isUserLender") {
        if (e.target.value === "no") {
          setPreformState({
            ...preformState,
            isUserBorrower: true,
          });
        } else {
          setShowForm(true);
        }
      } else {
        const isInputFilled = preformState.inputsFilled.includes(e.target.name);
        setPreformState((prevState) => ({
          ...prevState,
          inputsFilled: isInputFilled
            ? prevState.inputsFilled
            : [...prevState.inputsFilled, e.target.name],
          validInputs:
            e.target.value === "no"
              ? prevState.validInputs.filter(
                  (inputName) => inputName !== e.target.name
                )
              : [...prevState.validInputs, e.target.name],
        }));
      }
    },
    [preformState]
  );

  const handlePreformSubmit = () => {
    if (preformState.inputsFilled.length < 3) {
      toast("Please answer to the all questions");
      return;
    } else if (preformState.validInputs.length < 3) {
      toast.error("Sorry you are not able to sign up");
      return;
    } else {
      setShowForm(true);
    }
  };

  const handleFormChange = useCallback(
    (e) => {
      const { value, id } = e.target;
      if (error[id]) {
        setError((state) => ({ ...state, [id]: false }));
      }
      setFormState((state) => ({ ...state, [id]: value }));
    },
    [error]
  );

  const handleUploadIdCard = useCallback(
    (e, type) => {
      const { files } = e.target;
      setFormState({ ...formState, idCard: files[0] });
      setError({ ...error, idCard: false });
    },
    [formState, error]
  );

  const validate = useCallback(
    (e) => {
      e.preventDefault();
      const [isValid, rejectedField] = formValidator(
        {
          ...formState,
          contact: formState.contact.number.substring(
            formState.contact.dialCode.length
          ),
          isUserBorrower: preformState.isUserBorrower,
        },
        "registration"
      );
      if (rejectedField === "contact") {
        document.getElementById("phone-input").style.border =
          "1px solid #d32f2f";
        document.getElementById("phone-input").classList.add("error");
      }
      setError((prevState) => ({ ...prevState, [rejectedField]: true }));

      if (isValid) {
        const updatedData = {
          ...formState,
          contact: "+" + formState.contact.number,
          email: formState.email.toLowerCase(),
        };

        const signupFormData = new FormData();
        for (let key in updatedData) {
          signupFormData.append(key, updatedData[key]);
        }

        dispatch(signup(signupFormData, () => setIsSuccessOpen(true)));
      }
    },
    [formState, dispatch]
  );

  return (
    <>
      {isSuccessOpen ? (
        <Success navigate={handleNewbieUserNavigate} />
      ) : (
        <Grid item container justifyContent={"center"} alignItems="center">
          <Paper className="fade-in-slow" sx={styles.wrapper} elevation={6}>
            {showForm ? (
              <Form
                formState={formState}
                setFormState={setFormState}
                handleFormChange={handleFormChange}
                validate={validate}
                error={error}
                setError={setError}
                theme={theme}
                setIsSuccessOpen={setIsSuccessOpen}
                handleUploadIdCard={handleUploadIdCard}
              />
            ) : (
              <PreSignupForm
                setShowForm={setShowForm}
                handlePreformChange={handlePreformChange}
                isUserBorrower={preformState.isUserBorrower}
              />
            )}
            <Grid
              container
              spacing={2}
              item
              xs={12}
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              <Grid item xs={6}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() => navigate("/")}
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "black",
                    textTransform: "none",
                    fontSize: "1rem",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid container item justifyItems="flex-end" xs={6}>
                <Button
                  onClick={showForm ? validate : handlePreformSubmit}
                  fullWidth
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "1em",
                  }}
                >
                  {showForm ? "Register" : "Next"}
                </Button>
              </Grid>{" "}
            </Grid>{" "}
            <Grid container justifyContent="center" alignItems="center">
              <Typography sx={{ display: "inline" }}>
                Already a member?{" "}
              </Typography>
              <Button
                component={Link}
                to="/login"
                variant="text"
                sx={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  fontSize: "1rem",
                  textDecoration: "none",
                  p: "0 0 0 5px",
                  "&:hover": {
                    color: theme.palette.primary.dark,
                  },
                }}
              >
                Login Now
              </Button>
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default SignUpForm;
